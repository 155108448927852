.calendar-datePicker {
  border: 2px solid var(--primary-sky);
  border-radius: 0;
}


.calender-container .rmdp-day,.calender-container .rmdp-week-day {
  height: 34px;
  width: 34px;
}

.calender-container {
  transform: translate(-43.25px, 0px);
}

.calender-container .rmdp-arrow-container {
  width: 26px;
  height: 26px;
  align-items: center;
}

.calender-container .rmdp-arrow {
  height: 8px;
  width: 8px;
  margin-top: 0;

}