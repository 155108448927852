.btn-pwa-forgot-container {
  margin-top: 46px;
}

.forgot-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
}

.forgot-back-container {
  text-align: center;
  display: flex;
}

.success-msg,
.success-msg p {
  color: #487b03;
}

.forgot-title {
  padding-bottom: 42px !important;
}

.forgot-input {
  margin-top: 38px;
}

.forgot-pass-body {
  padding: 0px 98px 56px 98px;
}
@media screen and (max-width: 768px) {
  .forgot-input {
    margin-top: 5px;
  }
}
@media screen and (max-width: 425px) {
  .forgot-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: var(--secondary-font-family);
  }

  .forgot-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .success-msg {
    font-family: var(--secondary-font-family);
  }

  .forgot-back-container {
    text-align: center;
    display: flex;
    /* padding: 10px 5px; */
  }
}
