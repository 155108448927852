@import url("https://fonts.googleapis.com/css?family=Poppins:500,400");

.header-box {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
}
.exprience-part {
  padding: 41px 0px;
}
.exprience-work {
  display: flex;
  padding: 5px;
}
.exprience-work .exp-year {
  margin-left: auto;
  width: 34%;
}
.exprience-work .exp-work {
  margin-left: 19px;
  width: 14%;
}
.exprience-work .exp-delete {
  padding: 10px;
  margin-right: auto;
  padding-left: 24px;
}
.expadd-data i {
  font-size: 20px;
  padding: 13px;
}
.expadd-data {
  font-size: 14px;
  color: var(--primary-sky);
  width: 47%;
  margin-right: auto;
  height: 118px;
}

@media screen and (min-width: 768px) {
  .add-work-exp-mobile {
    display: none !important;
  }
}
/* step-2 */
.exp-submitstyle {
  margin-left: auto;
  width: 60%;
  margin-right: auto;
}
/* step-2 */
.expemail-btn {
  padding: 10px;
}
/* step-2 */
.workexp-inputbox .workexp-month {
  width: 45%;
  margin-right: auto;
  padding: 8px 0px;
}
