.job-view-container .editor-cotainer-inner {
  color: var(--primary-color-deepdark);
}

.job-details-container table {
  border: 2px solid #3FA4CC;
  border-collapse: collapse;
}

.job-details-container td {
  padding: 8px;
  border: 2px solid #3FA4CC;
  border-collapse: collapse;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}