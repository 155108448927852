.form-floating-input {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding-top: 0px;
  height: 55px;
  border-bottom-width: 2px;
}

.form-floating-input input {
  border-bottom: 2px solid var(--primary-sky);
  outline: none;
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding: 18px 0 8px 5px;
  margin-top: 6px;
  background-color: var(--light-silver);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* .floating-label input:focus,
.floating-label textarea:focus {
  border-color: var(--primary-sky);
} */

.floating-label input:focus + span,
.floating-label textarea:focus + span,
.floating-label.floating span {
  padding: 5px 0px 0px 5px;
  font-size: 12px;
}

.form-floating-input span {
  padding-left: 5px;
  opacity: 0.5;
  font-size: 14px;
  color: var(--primary-sky);
}

.password-view-container {
  position: absolute;
  top: 20px;
  right: 16px;
  bottom: 0;
  cursor: pointer;
}

.password-view-container img {
  height: 18px;
  width: 25px;
}

@media screen and (max-width: 425px) {
  .password-view-container img {
    height: 20px;
    width: 23px;
  }
}
