.qrCodes-section {
    background-color: #E0ECFF;
    min-height: calc(100vh - 161px);
}

.background-left-image .logo-banner-image {
    max-height: 100vh;
}

.qrCodes-main-container { 
    z-index: 1;
    max-width: 80%;
    margin: auto;
}

.qrCodes-heading {
    color: #2B2E39;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 15px;
}

.add-qrCode-button {
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.no-qrCode-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-qrCode-message {
    padding: 26px;
    border: 2px solid #3FA4CC;
}

.delete-confirmation-body {
    padding: 56px;
}

.text-apron-rigth-side {
    position: absolute !important;
}

.qr-code-image-section {
    max-width: 100% !important;
}

.delete-doc-modal .modal-dialog {
    max-width: 720px !important;
}

.delete-conf-btn {
    display: flex;
    justify-content: space-around;
}

.qrCode-table tr:hover {
    cursor: default;
}
@media (max-width: 1024px) {
    .qr-code-image-section img {
        width: 100%;
    }
    .qrCodes-main-container {
      max-width: 90%;
    }
}

@media (max-width: 992px){
    .table-tr-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .qrCodes-heading{
        font-size: 22px;
        line-height: 33px;
        font-weight: 500;
        padding-bottom: 36px !important;
        margin: 0 !important;
    }
}

@media (max-width: 776px){
    .delete-conf-btn {
        margin: auto 0;
    }

    .mobile-menu-item {
        display: none !important;
    }

    .mobile-dropdown-menu {
        display: flex !important;
    }

    .table-tr-section {
        margin-bottom: 18px;
    }

    .delete-confirmation-body {
        padding: 25px;
    }

    .delete-doc-modal .modal-dialog {
        max-width: 525px !important;
    }
}

@media (max-width: 576px){
    .qr-code-image-section {
        max-width: 150px !important;
    }
    .delete-conf-btn button {
        width: 150px;
    }
}

@media (max-width: 425px){
    .qr-code-image-section {
        padding: 0 !important;
        max-width: 100px !important;
    }
}