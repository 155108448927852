/* sidebar-menu */
.search-block {
  padding: 42px 26px 100px 26px;
  margin-bottom: 31px;
}
.search-section .dropdown-toggle::after {
  color: #000 !important;
  margin: auto 0;
  content: none;
}
.search-section [data-dropdown] > select.dropdown-display {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.down-arrow {
  position: absolute;
  top: 23px;
  right: 8px;
}
.slidebar-serch-block .form-label {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: var(--primary-deep-dark);
}

.label-colon {
  display: none;
}

.search-error-container {
  margin-top: 28%;
}

.search-input-error {
  margin-top: 20px;
}

.job-desc-block b {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--primary-deep-dark);
}

.job-desc-block p {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.003em;
  margin-bottom: 5px;
  font-style: normal;
}

.job-desc-block i {
  font-style: normal;
}

.searchLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 999999;
}

.slidebar-serch-block .form-control {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 26px 29px;
  color: var(--primary-deep-dark);
  /* border: none; */
  transition: none;
  background-color: var(--secondary-color-dark-gray);
  border-radius: 5px;
}

.job-type-title {
  padding: 0px 28px 0px 53px;
}

/* .slidebar-serch-block .form-control:focus {
  border-color: var(--primary-sky);
} */

.fullscreen-icon {
  cursor: pointer;
}

.slidebar-serch-block input::placeholder {
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-deep-dark);
}
.slidebar-serch-block .dropdown button {
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-deep-dark);
}
.slidebar-serch-block .dropdown-menu a:hover {
  border-left: 7px solid transparent;
  background: var(--secondary-color-sky);
  transition: all ease-in-out 0.5s;
}

.slidebar-serch-block .dropdown-menu {
  width: 100%;
  transform: translate3d(0px, 52px, 0px);
}

.joblist-sections {
  padding: 0px 64px;
  background: rgba(129, 178, 20, 0.16);
}

.select-search-dropdown .dropdown-menu {
  max-height: 155px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 14rem;
}
.invalid-input {
  border: 2px solid #c60000;
}
.valid-input {
  border: none;
}
button.btn.btn-primary.contact-btn:hover {
  background-color: var(--primary-sky);
}
.select-search-dropdown {
  /* padding: 4px 0px; */
  /* background-color: var(--secondary-color-dark-gray); */
  border-radius: 5px;
  cursor: pointer;
}

.qualification-section-block li::marker {
  color: var(--primary-sky);
  height: 8px;
  width: 8px;
}
.qualification-section-block li {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  color: var(--primary-deep-dark);
  line-height: 26px;
}
.search-application-type {
  margin: 43px 0px;
  padding-top: 32px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 4%);
  border-radius: 6px;
  border: none;
}

.search-job-desc {
  max-height: 205px;
  white-space: nowrap;
  overflow: hidden;
}

.search-application-type .card-header {
  background-color: var(--primary-light);
  border-bottom: 2px solid #f1f1f1;
}
.text-brown {
  color: var(--primary-deep-dark);
}
.job-type {
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
}
.job-type-subtitle {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

.bottom-pagination .page-link {
  border: 0;
}

.bottom-pagination .page-item:first-child .page-link {
  margin-right: 14px;
}

.bottom-pagination .sr-only {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: var(--primary-deep-dark);
}

.restorrent-block {
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
}
.search-application-type .card-img {
  height: 25px;
  width: 25px;
}
.pagination-data .sr-only {
  position: relative;
}
.pagination-data li a span:first-child {
  display: none;
}
.pagination-data .page-link {
  background-color: transparent;
  color: var(--primary-deep-dark);
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .slidebar-serch-block .form-label {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: var(--primary-deep-dark);
  }
}
@media (max-width: 991.98px) {
  .joblist-sections {
    padding: 0px 17px;
  }
  .card-header {
    padding: 0.75rem 0rem !important;
  }
  .slidebar-serch-block .form-label {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: var(--primary-deep-dark);
  }

  .search-application-type {
    margin: 13px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #bdbdbd;
    border-radius: 6px;
  }
  .job-description {
    padding: 65px 38px 0;
  }
  .job-type {
    font-size: 16px;
    line-height: 10px;
    font-weight: 600;
  }
  .restorrent-block {
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
  }
  .qualification-section-block li {
    font-weight: 400;
    line-height: 23px;
    font-size: 17px;
  }

  .lg-search-result {
    display: none;
  }

  .search-application-type .card-header {
    border-bottom: solid transparent;
  }
  .joblist-sections {
    padding: 0px 39px;
  }
  .edit-filter {
    font-size: 16px;
    line-height: 16px;
    margin: auto 0;
  }
  .edit-filter-block {
    padding: 25px 38px;
    height: 107vh;
    overflow-y: auto;
  }
  .edit-filter-block::-webkit-scrollbar-thumb {
    background: #b8b8b8;
    border-radius: 21px;
  }
  .edit-filter-block::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .job-type-title {
    padding: 0;
  }
  .job-type-subtitle {
    font-size: 14px;
  }
  .job-type {
    font-size: 16px;
  }
  .App-btn-data {
    width: auto;
  }
  .restaurant-manager {
    border-bottom: 1px solid #dddddd;
  }
  .job-summary-data {
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
  }
  .job-summary-inner {
    font-size: 12px;
    line-height: 20px;
    width: 600;
    font-weight: 400;
    padding: 15px 4px;
  }
  .primary-block {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
  .primary-section-block li {
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    padding: 6px 0px;
    color: var(--primary-deep-dark);
  }
  .primary-section-block li::marker {
    color: var(--primary-sky);
    height: 8px;
    width: 8px;
  }

  .back-edit-filter {
    margin: 5px 0px 25px 3px;
  }

  .search-res-title {
    background-color: var(--primary-light);
  }

  .fs-apply-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 29px !important;
  }
  .edit-filter-block::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  .edit-filter-block {
    padding: 0px;
    overflow-x: hidden;
  }

  .job-desc-block p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: var(--primary-deep-dark);
  }

  .job-desc-block b {
    font-style: normal;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.3px;
  }

  .searchLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 999999;
  }

  .select-search-dropdown .dropdown-item {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 767.98px) {
  .joblist-sections {
    padding: 64px 40px;
  }

  .search-button-css {
    text-align: center;
    border: none;
  }

  .search-block {
    margin-bottom: 31px;
    padding: 104px 33px 0px;
  }

  .slidebar-serch-block .form-control {
    font-size: 14px;
  }

  .slidebar-serch-block .form-control::placeholder {
    font-size: 14px;
  }

  .label-colon {
    display: block;
  }

  .back-edit-filter {
    margin: 25px 0px 25px 3px;
  }
}

@media (max-width: 575.98px) {
}
@media (max-width: 375px) {
  .job-type {
    font-size: 13px;
    line-height: 20px;
  }
}
