.support-section-nojob {
  background-color: #E0ECFF;
}

.support-inner-nojob-container {
  padding-bottom: 69px;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.jobListLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  position: fixed;
  left: 50%;
  top: 10%;
  bottom: 0;
  right: 50%;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: var(--primary-sky);
}

label:hover span::before,
label:hover span::before {
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
}

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 2px var(--primary-blue);
  background-color: transparent;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
}

input[type="checkbox"]:checked + span::before {
  color: var(--primary-sky);
}

input[type="checkbox"]:disabled + span {
  cursor: default;

  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 16px;
  line-height: 19px;
}

input[class="skyblue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 19.5px;
  height: 19.5px;
}

input[class="skyblue"]:checked + span::before {
  color: var(--primary-sky);
}

.job-terms {
  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  min-height: 100%;
  align-items: center;
  font-family: var(--primary-font-family);
}

.job-terms-main-section {
  font-size: 36px;
  line-height: 54px;
  font-weight: 500;
}

.main-section-part-two {
  padding: 44px 0;
}

.currently-section {
  font-size: 18px;
  line-height: 96px;
  font-weight: 400;
}

.nojob-main-section {
  background-image: url("../../Assets/Images/background_vector.png");
  background-repeat: no-repeat;
  background-position: center;
}

.nojob-Employers {
  background-image: url("../../Assets/Images/employers.png");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 43%;
}

.no-jobs-label-style {
  padding: 0px 18px;
}

/* --------table------ */
/* .job-table .dropdown button.dropdown-toggle::after {
  display: none;
} */

.job-table .dropdown-menu a:hover {
  border-left: 7px solid #f7f7f7;
  background-color: #f7f7f7;
}

.second-job-table .dropdown-menu a:hover {
  border-left: 7px solid #f7f7f7;
  background-color: #f7f7f7;
}

.job-table .dropdown-menu a {
  border-left: 7px solid var(--primary-light);
  font-size: 16px;
  line-height: 28px;
  color: #000;
  font-weight: 400;
  padding: 7px 20px !important;
}

.second-job-table .dropdown-menu a {
  border-left: 7px solid var(--primary-light);
  font-size: 16px;
  line-height: 28px;
  color: #000;
  font-weight: 400;
  padding: 4px 20px !important;
  margin: 3px auto;
}

.job-main-table-section .nav-mega {
  filter: drop-shadow(0px 4px 40px rgba(63, 164, 204, 0.14));
}

.main-table {
  width: 100%;
  table-layout: fixed; /* this is to enforce the table to display ellipsis on overflowing cells and not adjust column width based on content */
}

.main-table tbody td {
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  font-family: var(--primary-font-family);
  border-top: 2px solid #f7f7f7;
  vertical-align: middle;
}

.main-table tbody h5 {
  font-size: 16px;
  font-weight: 400;
}

.main-table tbody button {
  padding: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: var(--primary-sky);
  font-family: var(--primary-font-family);
  margin-right: 0;
  background-color: transparent !important;
  border-color: transparent;
}

.main-table tbody td button::after {
  display: none !important;
}

.main-table tbody td button:focus {
  box-shadow: none !important;
}

/* .main-table tbody td .dropdown button:hover {
  background-color: transparent;
  border-color    : transparent;
  transition      : all ease-in-out 500ms;
}

.main-table tbody td .dropdown button:active {
  background-color: transparent !important;
  border-color    : transparent !important;
  color           : var(--primary-sky) !important;
}
*/

.down-arrow-table {
  position: absolute;
  top: 3px;
  padding-left: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  /* border-color: 0.15s ease-in-out; */
}

.main-table .dropdown-menu.show {
  filter: drop-shadow(0px 4px 40px rgba(63, 164, 204, 0.14));
  border: none;
  box-shadow: 0px 4px 28px rgb(182 182 182 / 25%);
}

.job-table .dropdown-menu.show {
  transform: translate(-77px, 24px) !important;
  box-shadow: 0px 4px 28px rgba(182, 182, 182, 0.25);
  border: none;
}

.second-job-table .dropdown-menu.show {
  box-shadow: 0px 4px 28px rgba(182, 182, 182, 0.25);
  border: none;
}

/* -------------------- */
.job-main-table-section {
  padding: 24px 0px;
}

.megamenu-section .Collapse .btn-primary:hover,
.megamenu-section .Collapse .btn-primary:active,
.megamenu-section .Collapse .btn-primary:focus {
  border: none;
  color: #3fa4cc;
}

.megamenu-section .dropdown-menu a:hover {
  border-left: none;
  background: var(--primary-light);
}

.megamenu-section .dropdown-menu a {
  border-left: none;
  padding: 0;
}

.main-table tr:hover {
  background: rgba(151, 151, 151, 0.08);
  cursor: pointer;
}

.megamenu-section .dropdown-menu tr:hover {
  background: rgba(151, 151, 151, 0.08);
}

/* .Collapse-main-block tr:hover {
  background: rgba(151, 151, 151, 0.08) !important;
} */
.table-hover-style:hover {
  background: #f7f7f7 !important;
}

.megamenu-section .dropdown-menu {
  padding: 0;
  float: initial;
}

.text-skyblue {
  color: var(--primary-sky);
}

.text-red {
  color: #f81313;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.text-green {
  color: #0e9a02;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.job-inner-block {
  padding: 0px 60px;
}

.checkbox-style {
  width: 200px;
}

.checkbox-style-data {
  width: 170px;
}

/* -------Collapse-------- */
.Collapse-section .card-header {
  padding: 0;
  border: none;
  background-color: transparent;
}

.Collapse-section .card {
  border: none;
  background-color: transparent;
  background-color: transparent;
}

.Collapse-section button {
  margin-right: 0;
  text-decoration: none !important;
}

.Collapse-section .btn:focus {
  box-shadow: none;
}

.Collapse-section .btn {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  font-family: var(--primary-font-family);
  color: var(--primary-sky);
  white-space: nowrap;
}

/* .Collapse-section-block button.job-first-btn.collapsed:before {
  content: "\f104" !important;
} */

/* .Collapse-section-block button.btn.btn-link:before {
  content: "\f105" !important;
  position: absolute;
  top: 0;
  right: 7px;
} */

.Collapse-section button.btn.btn-link.collapsed:before {
  font-family: "FontAwesome";
  content: "\f107";
  float: right;
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
}

.Collapse-section button.btn.btn-link:before {
  font-family: "FontAwesome";
  content: "\f106";
  float: right;
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
}

tr.Collapse-main-block:hover {
  background: transparent;
}

.application-pagination {
  display: flex;
  justify-content: end;
}

.application-prev {
  margin-right: 16px;
}

.apl-collapse .dropdown-menu.show {
  box-shadow: 0px 4px 28px rgb(182 182 182 / 25%);
  border: none;
}

.job-first-btn {
  padding: 13px 27px 13px !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.job-btn-style {
  position: absolute;
  top: 5px;
  right: -5px;
}

/* button.job-first-btn::before {
  font-family: "FontAwesome";
  content: "\f105";
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0px;
} */

button.btn-pull-right[aria-expanded="true"]:before {
  font-family: "FontAwesome";
  content: "\f106";
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0px;
  bottom: 14px;
}

button.btn-pull-right[aria-expanded="false"]::before {
  font-family: "FontAwesome";
  content: "\f105";
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0px;
  bottom: 14px;
}

.no-job-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-job-message {
  padding: 26px;
  border: 2px solid #3FA4CC;
}

.live-rounded-button {
  display: flex;
  width: 22px;
  height: 20px;
  border-radius: 50%;
}

.live-rounded-green {
  background-color: #86FA12;
}

.live-rounded-red {
  background-color: #D80202;
}

.live-rounded-yellow {
  background-color: #F8CE3B;
}

.application-prev {
  color: #000 !important;
  margin-right: 16px !important;
  padding: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--primary-font-family);
  margin-right: 0;
  background-color: transparent !important;
  border-color: transparent !important;
}
.logo-banner-image {
  max-width: 120px;
}

.application-next {
  color: #000 !important;
  padding: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--primary-font-family);
  margin-right: 0;
  background-color: transparent !important;
  border-color: transparent !important;
}

.publish-job-button {
  border-color: #3FA4CC !important;
  border: 3px solid #3FA4CC;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-left: 10px;
  padding: 2px 5px !important;
  border-radius: 6px;
}

.jobs-table-header tr:hover {
  background-color: unset;
}
.jobs-table-header tr th {
  border: unset;
  font-size: 16px;
  color: #3fa4cc;
  font-weight: 400;
  cursor: default;
}

.resp-job-header {
  border-bottom: 2px solid #f7f7f7;
  padding-left: 20px !important;
}

.job-candidate-list {
  width: 85%;
  margin-left: auto;
}

.job-dropdown-button {
  padding-right: 20px !important;
}
.job-dropdown-button .btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.job-dropdown-button .btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}
.lst-job-detail-mobile .btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.lst-job-detail-mobile .btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}

.second-job-table .btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.second-job-table .btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}

.sort-candidates-container .dropdown-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lst-job-detail-mobile {
  margin-left: 10px;
}

.Collapse-section .appl-list-button.btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.Collapse-section .appl-list-button.btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}

.table-data-section .job-table .btn-primary:hover,
.table-data-section .job-table .btn-primary:active,
.table-data-section .job-table .btn-primary:focus
 {
    border-color: unset;
    border: none;
}

@media (min-width: 768px) and (max-width: 1167.98px) {
  .text-apron-rigth-side-img img {
    display: none;
  }
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 992px) {
  .show-fields-small-screen {
    display: none !important;
  }

  /* .truncate:hover {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  } */

  .collapse-data {
    display: none;
  }
}

@media (max-width: 1500px) {
  .job-inner-block {
    padding: 0px 0px;
  }
  .job-table .dropdown-menu.show {
    transform: translate(-12px, 24px) !important;
  }
}

@media (max-width: 1225px) {
  .main-section-part-two {
    padding: 44px 22px 0;
  }
}

@media (max-width: 992px) {
  .main-table tbody td {
    display: block;
    padding: 13px 23px 10px;
    font-size: 14px;
    line-height: 21px;
    border-top: none;
    max-width: 100%;
  }

  .truncate {
    white-space: normal;
    text-overflow: clip;
    overflow: visible;
  }

  .megamenu-section {
    padding-right: 20px !important;
    justify-content: flex-start !important;
    flex-direction: column;
  }

  .hide-fields-small-screen {
    display: none !important;
  }
  .table-hover-style:hover {
    background: var(--primary-light) !important;
  }

  .coll_block {
    padding-left: 20px !important;
  }

  .main-table tbody td button.mobile-job-appl.btn-primary {
    color: var(--primary-deep-dark) !important;
    border-color: unset !important;
    border: unset !important;
  }

  .mobile-icon {
    display: flex !important;
  }

  .Collapse-section .card-header {
    padding: 0 !important;
  }

  .collapse-data-style {
    display: none;
  }

  .Collapse-section .btn {
    font-size: 14px;
    line-height: 21px;
  }

  .table-data-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .jobs-table-header {
    display: none;
  }

  .table-data-btn button {
    transform: rotate(90deg);
  }

  .main-section-part-two {
    padding: 39px 33px 0;
  }

  .job-terms-main-section {
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
    padding-bottom: 36px !important;
    margin: 0 !important;
  }

  .job-main-table-section {
    padding: 36px 0px;
  }

  .second-menu {
    height: 5px;
  }

  .job-table .dropdown-menu a {
    font-size: 14px;
    line-height: 21px;
  }

  .second-job-table .dropdown-menu a {
    font-size: 14px;
    line-height: 21px;
    margin: 17px auto;
  }

  .table-style-block {
    margin: 25px 0px;
  }

  .text-red {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .text-green {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .job-table-icon {
    position: absolute;
    top: 9px;
    right: -2px;
  }

  input[class="skyblue"] + span::before {
    margin-top: 4px;
  }

  span.text-default-status.text-active.pl-lg-5 {
    padding-left: 0px !important;
  }
  span.text-publish.pl-lg-5 {
    padding-left: 0px !important;
  }

  .lst-job-detail-mobile .dropdown-item{
    margin: 17px auto;
    font-size: var(--small-font-size);
  }
}

@media (max-width: 768px) {
  .no-jobs-label-style {
    padding: 0px 0px;
  }
}

@media (max-width: 768px) {
  .main-section-part-two {
    padding: 30px 15px;
  }
}

@media (max-width: 575px) {
  .jobs-sort-option {
    justify-content: unset !important;
  }
  .jobs-sort-option div {
    max-width: 240px;
  }

  .jobs-sort-option .sort-select-section {
    margin-bottom: 10px;
  }
  .sort-candidates-container{
    flex-direction: column;
    align-items: flex-start !important;
  }
}