.datePicker {
  font-size: 14px;
  font-family: var(--primary-font-family);
}

.job-details-modal .modal-title {
  padding-bottom: 23px;
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
}

/* --------review and publish-model-------- */
.review-modal-block {
  font-size: var(--medium-small-font-size);
  line-height: 25px;
  font-weight: 400;
}
.review-modal-text {
  font-size: var(--medium-small-font-size);
  line-height: 18px;
  font-weight: 400;
}
.editor-cotainer {
  text-align: left;
}
.editor-cotainer textarea::placeholder {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400px;
  color: #000;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}
.editor-cotainer-inner {
  border: 2px solid var(--primary-blue);
  height: 386px;
  padding: 17px 18px 13px;
  overflow-y: scroll;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  overflow-x: hidden;
  word-wrap: break-word;
}

.editor-cotainer-inner p {
  font-size: var(--medium-small-font-size);
  margin-block-end: 0px;
  margin-bottom: 0px;
  line-height: 25px;
}

.editor-cotainer-inner ul {
  font-size: 16px;
  line-height: 24px;
}

.qlf-detail-container {
  font-size: 16px;
}

.jobreview-qlf-border {
  border-right: 2px solid var(--primary-blue);
  width: 80%;
}
.jobreview-qlf-container {
  border: 2px solid var(--primary-sky) !important;
}

.expire-dropdown-arrow {
  position: absolute;
  bottom: 14px;
  right: 26px;
  cursor: pointer;
}

@media (max-width: 991px) and (min-width:868px) {
  .expire-dropdown-arrow {
     right: 44px;
  }
}

@media (max-width: 768px) and (min-width:755px) {
  .expire-dropdown-arrow {
     right: 36px;
  }
}
@media (max-width: 768px) {
  /* --------review and publish-model-------- */
  .review-modal-block {
    font-size: 14px;
    line-height: 25px;
  }
  .review-modal-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
  .job-details-modal .modal-title {
    padding-bottom: 22px;
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
  }

  .editor-cotainer-inner,
  .editor-cotainer-inner p,
  .editor-cotainer-inner ul {
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }
}

@media (max-width: 425px) {
  .jobreview-qlf-border {
    width: 75%;
  }
}
