.doc-preview-loader {
  position: absolute;
  width: 0px;
  z-index: 2000000000;
  left: 55%;
  top: 50%;
}

@media screen and (max-width: 425px) {
  .doc-preview-loader {
    left: 50%;
    top: 48%;
  }
}
