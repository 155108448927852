.skip-checkbox {
  display: flex;
  justify-content: center;
}

.skip-checkbox-style {
  padding: 10px;
  display: flex;
  align-items: center;
  padding-left: 43px;
}

.from-radio-input {
  display: flex;
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 15px 0px 0px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 19.5px;
  height: 19.5px;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--primary-sky);
  margin: 0px 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary-sky);
}

input[type="radio"]:checked {
  border-color: var(--primary-sky);
}

.from-up-label {
  font-weight: var(--medium-font-weight);
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.tstyle th {
  padding: 0.1rem 0.1rem;
  width: 100%;
  padding-left: 8px;
}

.tstyle tr:hover {
  cursor: pointer;
  /* background-color: #ede493; */
}

.selected-tr {
  background-color: #ede493;
}

.tstyle td {
  padding: 0.1rem 0.1rem;
  width: 100%;
  padding-left: 8px;
  color: var(--primary-sky);
}

.tstyle .table-hedar {
  color: white !important;
  background-color: var(--primary-sky) !important;
}

.tstyle .t-whitespace {
  overflow: hidden;
  height: 80px;
  white-space: nowrap;
}

.resume-alldata {
  text-align: initial;
  margin: auto;
  width: 100%;
  padding: 41px 0;
  color: #3a3a3a;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  font-style: 14px;
}

.resume-alldata .upload-table {
  margin-left: auto;
  width: 86%;
  margin-top: 14px;
  border: 2px solid #3a3a3a;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}

.job-modal-step {
  justify-content: space-between;
  align-items: center;
}

.step-style {
  color: var(--primary-sky);
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-style: normal;
}

.cancel-job {
  font-family: var(var(--primary-font-family));
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-right: 9px;
  color: #3a3a3a;
}

.back-icon {
  margin-bottom: 3px;
}

.cancel-icon {
  margin-bottom: 2px;
}

/* .t-textarea {
  margin-left: auto;
  width: 86%;
} */

.upload-new-container {
  margin-left: auto;
  width: 86%;
}

.t-textarea .pink {
  font-size: 14px;
  color: var(--primary-sky);
}

.t-textarea .pink .b-style {
  font-weight: bold;
}

.t-textarea .drop-mobile {
  display: none;
}

.drop-desktop {
  margin: 7px 0px;
}

.drag-area-input {
  width: 100%;
  height: auto;
  min-height: 248px;
  max-height: 248px;
  border: 2px solid #3a3a3a;
}

.my-fromresume {
  display: flex;
  padding-top: 21px;
  justify-content: space-between;
}

.my-fromresume .my-placeholder {
  width: 37%;
  font-size: 14px;
  color: var(--primary-sky);
  font-size: 14px;
  font-weight: 400px;
  width: 36%;
}

.collapse-radio {
  cursor: pointer;
}

.form-floating-container {
  width: 320px;
}

.btn-clear {
  font-size: 16px;
  color: var(--primary-sky);
  text-align: right;
  margin-top: 12px;
  cursor: pointer;
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .from-up-label {
    font-size: 18px;
    line-height: 20px;
    font-family: var(--secondary-font-family);
  }

  .first-choose {
    padding: 27px 0;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .resume-alldata {
    padding: 27px 0px;
    width: auto;
  }

  .t-textarea .pink .b-pdf {
    display: none;
  }

  .document-modal-body {
    padding: 22px 20px 50px 20px;
  }

  .skip-checkbox {
    padding: 0px 27px;
  }

  .resume-alldata {
    font-family: var(--secondary-font-family);
    padding: 27px 10px;
  }

  .resume-alldata .upload-table {
    font-family: var(--secondary-font-family);
    padding: 27px 10px;
  }

  .t-textarea {
    width: 100%;
  }

  .t-textarea .drop-desktop {
    display: none;
  }

  .drop-mobile {
    margin: 7px 0px;
  }

  .t-textarea .drop-mobile {
    display: block;
  }

  .from-radio-input {
    width: 100%;
  }

  .skip-checkbox-style {
    padding-left: 0px;
  }

  .my-fromresume .my-placeholder {
    width: 96%;
  }

  .label-back {
    font-family: var(--secondary-font-family);
  }

  .cancel-job {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 320px) {
  .skip-checkbox {
    padding: 0px 12px;
  }

  .document-modal-body {
    padding: 22px 0px 50px 0px;
  }

  .skip-checkbox-style {
    padding-left: 0px;
  }

  .label-back {
    font-family: var(--secondary-font-family);
  }

  .cancel-job {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 1463px) and (min-width: 720px) {
  .t-textarea .pink img {
    display: none;
  }
}
