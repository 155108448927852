@import url("https://fonts.googleapis.com/css?family=Poppins:500,400");

.header-box {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
}
.exprience-part {
  padding: 41px 0px;
}
.exprience-work {
  display: flex;
  padding: 5px;
}
.exprience-work .exp-year {
  margin-left: auto;
  width: 340px;
}
.exprience-work .exp-work {
  margin-left: 19px;
  width: 118px;
}
.exprience-work .exp-delete {
  padding: 10px;
  margin-right: auto;
  padding-left: 24px;
  cursor: pointer;
}
.expadd-data i {
  font-size: 20px;
  padding: 13px;
}
.expadd-data {
  font-size: 14px;
  color: var(--primary-sky);
  width: 69%;
  margin-right: auto;
  height: 54px;
  margin-left: 7px;
  cursor: pointer;
  text-align: left;
}

.exp-table {
  display: none;
}

@media screen and (max-width: 720px) {
  .exp-table {
    display: block;
  }

  .exprience-part {
    display: none;
  }
}
