.sort-candidates-container .dropdown button { 
    color: #162937;
    background-color: #ffffff;
    border-color: #162937;
    padding: 2px 5px;
    border-radius: 0;
}

.table-data-section .second-job-table .btn-primary:hover,
.table-data-section .second-job-table .btn-primary:active,
.table-data-section .second-job-table .btn-primary:focus
 {
    border-color: unset;
    border: none;
}

@media (max-width: 660px) {
    .postjob-terms-condition .job-terms {
        font-size: 14px;
    }
}

@media (max-width: 602px) {
    .sort-candidates-container label {
        margin-bottom: 8px !important;
        color: #162937;
        font-size: 14px;
    }
}