.password-detalis p{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: var(--forms-text-color);
  margin-bottom: 11px;
}

.term-condition {
  font-size: 16px;
  color: var(--primary-blue);
  font-weight: var(--semi-bold-font-weight);
}

.terms-lbl-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accept-terms-condition {
  font-size: 16px;
  color: #3a3a3a !important;
  font-weight: 400 !important;
  user-select: none;
  font-family: var(--primary-font-family) !important;
  line-height: 20px;
}

/* .check-box label:hover .term-condition {
  color: var(--primary-sky);
} */
.create-account-modal .modal-dialog {
  max-width: 870px !important;
}

.create-account-modal .modal-body {
  padding: 0px 98px 84px 98px;
}

.create-account-modal .login-btn {
  margin-top: 60px;
}

.create-account-modal .btn-pwa-signUp {
  width: 100%;
  max-width: 208px;
  height: 50px;
}

.term-condition label:hover span,
.term-condition label:hover span {
  color: var(--primary-blue) !important;
}

.txt-accept-terms-col {
  padding-top: 2px;
  text-align: left;
  padding-left: 10px;
}

.create-account-modal .credentials-detalis {
  padding-bottom: 50px;
}

.registration-error-msg p {
 text-align: start !important;
} 

@media (max-width: 767px) {
  .create-account-modal .modal-body {
    padding: 0 30px 50px 30px;
  }

  .create-account-modal .credentials-detalis {
    padding-bottom: 39px;
  }

  .create-account-modal .modal-header {
    padding: 22px 0 12px 32px;
  }

  .txt-accept-terms-col {
    padding-left: 10px;
  }
  .modal-open .modal {
    position: fixed !important;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @supports (-webkit-touch-callout: none) {
    .scroll-modal {
      overflow: hidden !important;
    }
  }
}

@media screen and (max-width: 425px) {
  .signup-back {
    float: left;
  }

  .signup-modal-title {
    padding: 0px 0px 24px 0px !important;
  }

  .signup-btn {
    margin-top: 18px !important;
  }

  .create-account-modal .modal-body {
    padding: 24px 30px 24px 30px;
  }

  .accept-terms {
    margin-left: 1px;
  }

  .check-box {
    margin-top: 14px;
  }

  .password-detalis,
  .password-detalis p {
    font-family: var(--secondary-font-family);
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .accept-terms-condition {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a !important;
    font-family: var(--secondary-font-family) !important;
  }

  .term-condition {
    font-size: 14px;
    color: var(--primary-blue);
    font-weight: var(--semi-bold-font-weight);
    font-family: var(--secondary-font-family);
  }

  .txt-accept-terms-col {
    padding-top: 1px;
    padding-left: 0px;
  }
  .term-chkbox-row,
  .terms-checkbox-col {
    margin: 0 0;
    padding: 0;
  }
}

@media screen and (max-width: 408px) {
  .terms-checkbox-col {
    padding-left: 0px;
  }

  .txt-accept-terms-col {
    padding-left: 0px;
  }

  .term-chkbox-row {
    margin: auto 0;
  }
}
