.first-choose {
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 41px 0;
  color: var(--primary-sky);
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  font-size: 14px;
}

.public-profile-modal-class .btn-job-select {
  width: 208px;
  height: 50px;
}

.btn-job-select {
  padding: 5px 44px;
  margin: 5px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}

/* .btn-job-disable {
  background-color: #cdcdcd;
  border: 1px solid #cdcdcd !important;
} */

/* .btn-job-enable {
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
} */

.apply-terms {
  color: #3a3a3a;
  font-size: 14px;
  display: flex;
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 29px 0px 0px;
  align-items: center;
}

.application-type-lbl {
  padding-left: 14px;
}

.public-profile-modal-class input[class="blue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 24px;
  height: 24px;
}

.public-profile-modal-class input[class="blue"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.public-profile-modal-class .modal-dialog {
  max-width: 870px !important;
}
.public-profile-modal-class .credentials-detalis {
  padding-bottom: 50px;
}
.public-profile-modal-class .btn-pwa-signUp {
  width: 208px;
  height: 50px;
}

@media (max-width: 767px) {
  .public-profile-modal-class .credentials-detalis {
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .view-position {
    width: auto;
  }
  .modal-body .back-join {
    font-size: 14px;
    font-family: var(--secondary-font-family);
  }
  /* .first-choose {
    width: auto;
  } */
  .app-modal-back {
    padding-left: 19px !important;
  }

  .application-type-lbl {
    padding-left: 5px;
  }

  .public-profile-modal-class .btn-pwa-signUp {
    width: 80%;
    max-width: 208px;
  }

  .choose-option-body {
    padding: 22px 10px 50px 10px;
  }

  .choose-option-title-container {
    text-align: center;
  }

  .choose-option-title-container .back-join {
    font-size: 18px;
  }

  .apply-terms {
    color: #3a3a3a;
    font-size: 14px;
    display: flex;
    text-align: initial;
    margin: auto;
    width: 100%;
    padding: 29px 0px 0px 13px;
    font-family: var(--secondary-font-family);
    align-items: flex-start;
  }

  .apply-terms .option-checkbox {
    padding-top: 5px;
  }

  .modal-header {
    padding: 0px 10px;
  }

}
