.reset-password-back {
  text-align: left;
  cursor: pointer;
}

.reset-pass-body {
  padding: 0px 98px 56px 98px;
}

.btn-reset-pass {
  margin-top: 66px;
}

@media screen and (max-width: 425px) {
  .reset-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .reset-pass-body .credentials-detalis {
    padding-bottom: 30px;
  }

  .btn-reset-pass {
    margin-top: 34px;
  }

  .reset-password-back {
    margin-top: 10px;
    margin-right: 10px;
  }
}
