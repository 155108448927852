@media (max-width: 992px) {
  .search-button-css {
    width: auto;
    text-align: start;
  }
}
@media (max-width: 767px) {
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: none;
  }

  button.btn.btn-primary.contact-btn.space {
    margin: -20px 1px 35px 0px;
  }

  .navbar-manage {
    padding: 0px !important;
  }
  .mobile-view.nw-padding {
    padding: 25px 2px 6px;
  }
  div#navbarNavAltMarkup a {
    margin-right: 0px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.07em;
    padding-left: 18px;
  }
  .dropdown-menu.sub-drop.show {
    padding: 10px 20px;
  }
  .dropdown-menu.sub-drop.show a {
    font-size: 16px !important;
  }
  nav.navbar.navbar-expand-md.navbar-light {
    padding: 0px;
  }
  /*.navbar-nav {
    margin-top: 10px;
}*/
  section.navbar-box-shdows {
    padding-bottom: 0px;
  }
  .search-button-css {
    border-radius: 0px;
  }
  nav img {
    margin-left: 15px;
  }
  .dropdown-menu.show a {
    border-top: 0px;
  }
  h2 {
    font-size: 24px !important;
    line-height: 36px !important;
  }
  body p {
    font-size: 16px;
  }
  .desktop-view {
    display: none !important;
  }
  button.desktop-view {
    display: none;
  }
  .we-help-text {
    margin-top: 30px;
  }

  body h3 {
    font-size: 20px;
  }
  section.lorem-ipsum-section {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .image-lorem-col h3,
  .image-lorem-col h2 {
    padding-left: 15px;
  }

  .image-lorem-col {
    padding-right: 0px !important;
  }
  .image-lorem-col img {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .box-items-first {
    border-top: none;
    margin-top: 20px;
  }
  .box-items {
    padding: 25px;
  }
  .height {
    display: none;
  }

  .box-items img {
    margin-bottom: 31px;
  }
  .text-apron-right-side-img {
    display: none;
  }
  section.Our-Specialities {
    padding-bottom: 40px;
  }
  .box-items p {
    font-size: 16px;
  }
}
nav.navbar img {
  /*margin-left: -15px;*/
}

@media (min-width: 768px) {
  button.mobile-view {
    display: none;
  }
}

@media (max-width: 1250px) and (min-width: 992px) {
  div#navbarNavAltMarkup a {
    margin-right: 2px;
    color: #000;
    font-size: 16px;
    padding-right: 15px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media (max-width: 768px) {
  .text-apron-right-side-img {
    display: none;
  }

  div#navbarNavAltMarkup a {
    font-size: 16px;
  }
}
.btn.btn-primary.contact-btn.desktop-view a {
  color: var(--primary-light);
  text-decoration: none;
}
button.btn.btn-primary.contact-btn.mobile-view a {
  color: var(--primary-light);
  text-decoration: none;
}
@media (max-width: 768px) {
  .tab-view {
    display: none;
  }
  .desktop-view {
    display: none;
  }
}
h2.mobile-view.spaces.head-padd {
  padding: 12px 14px 21px;
}
