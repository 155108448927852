.modal-data-block {
  border: 2px solid var(--primary-sky);
}

.modal-data-block td {
  padding: 13px 14px 10px;
  font-size: var(--medium-small-font-size);
  line-height: 18px;
  color: #000;
  font-family: var(--primary-font-family);
  max-width: 175px !important;
  word-wrap: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal-data-block td:hover {
  text-overflow: unset;
  white-space: initial;
  overflow: unset;
  word-break: break-word;
}

.view-jobqlf-td {
  /* width: 75%; */
  border-right: 2px solid var(--primary-blue);
}

.modal-table-inner {
  margin: 5px 0px 12px;
}

.btn-resume .btn-outline-info {
  color: var(--primary-sky) !important;
  border-color: var(--primary-sky) !important;
}

.btn-resume .btn-outline-info:hover {
  background-color: transparent;
}

.main-btn-style {
  width: 168px;
}

.quali_label {
  margin-bottom: 0;
}

.iframe-section {
  margin-bottom: 29px;
}
.job_app_iframe {
  width: 100%;
  height: auto;
  border: 2px solid var(--primary-blue);
}

.job_app_iframe,
.iframe-section-block div iframe {
  min-height: 496px;
  max-height: 500px;
}

.candidate-resume-container {
  min-height: 352px;
  max-height: 500px;
  border: 2px solid var(--primary-blue);
}

.doc-resume-prev-loader {
  position: absolute;
  width: 0px;
  z-index: 2000000000;
  left: 50%;
  top: 63%;
}

.text-copy-icon {
  width: 15px;
  cursor: pointer;
  display: flex;
}
.text-link-icon {
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.copy-popover {
  top: unset;
}

.copy-popover .popover-body {
  background-color: #4b944b;
  color: #ffffff;
}

.copy-text-button {
  padding: 0;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .border-style {
    border: 1px solid var(--primary-sky);
  }

  .modal-data-block td {
    padding: 6px 10px 4px;
    font-size: 14px;
    line-height: 18px;
    max-width: 138px !important;
  }

  .btn-cover {
    margin-left: 0px;
  }

  .main-btn-style {
    width: 148px;
  }

  .job_app_iframe,
  .iframe-section-block iframe {
    min-height: 456px;
    max-height: 481px;
  }

  .candidate-resume-container,
  .candidate-resume-container .iframe-section-block iframe {
    min-height: 270px;
    max-height: 270px;
  }
  .job-details-container td {
    font-size: 14px !important;
  }
}

.candidate-email {
  word-break: break-all;
}

@media (max-width: 660px) {
  .review-modal-block div {
    color: #000;
  }
  .resp-text-spacing {
    white-space: unset !important;
  }
  .modal-data-block td {
    white-space: unset;
  }
}

@media (max-width: 425px) {
  .modal-data-block td {
    font-family: var(--secondary-font-family);
  }
}

@media (max-width: 415px) {
  .main-btn-style {
    margin-right: 0;
  }

  .contact-btn {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (max-width: 400px) {
  .main-btn-style {
    max-width: 105px;
    margin-right: 0;
  }
}
