.modal-postjob-data .modal-content {
  padding: 50px 0px 54px;
  background: var(--primary-light) !important;
  border-radius: 0;
}

.modal-postjob-data .modal-body h5 {
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  padding-bottom: 31px;
}

.modal-postjob-data .modal-body {
  padding: 0px 177px 0px;
  font-size: 18px;
  line-height: 27px;
  text-align: start;
}

.postjob-terms-condition {
  font-size: 14px !important;
  color: #162937 !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  -webkit-user-select: none;
  user-select: none;
}

.modal-postjob-data .modal-dialog {
  max-width: 870px !important;
  top: 0;
}

.text-skyblue {
  color: var(--primary-sky);
}

.location-dropdown,
.select-type-dropdown .dropdown-toggle {
  -webkit-text-fill-color: var(--primary-color-deepdark);
  opacity: 1;
  /* required on iOS */
}

.location-dropdown .dropdown-menu {
  transform: translate(-3px, 53px) !important;
}

.btn-location-continue:hover {
  background: #808080;
}

button.close {
  display: inline;
}

.modal-content-data .modal-header {
  padding: 0 50px;
}

.postjob-data {
  width: 0px;
}

.city-search-dropdown .dropdown-menu {
  overflow-y: hidden !important;
}

.search-list-dropdown {
  overflow-x: hidden;
  max-height: 155px;
}

.search-list-dropdown .search-list-item:hover {
  background: var(--secondary-color-sky);
  transition: all ease-in-out 0.5s;
}

.search-list-dropdown .search-list-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}

.form-floating {
  position: relative;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: var(--primary-sky);
}

label:hover span::before,
label:hover span::before {
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
}

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 1px #a8a8a8;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  background: #f6f6f6;
}

input[type="checkbox"]:checked + span::before {
  color: var(--primary-sky);
}

input[type="checkbox"]:disabled + span {
  cursor: default;
  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 16px;
  line-height: 19px;
}

input[class="skyblue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 19.5px;
  height: 19.5px;
}

input[class="skyblue"]:checked + span::before {
  color: var(--primary-sky);
}

.postjob-block-inner {
  padding-top: 56px;
  padding-bottom: 16px;
}

.con-btn-style {
  width: 23% !important;
}

.slidebar-serch-block .dropdown button:focus {
  box-shadow: none;
}

.state-input-sel-container input {
  padding-right: 33px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.city-search-dropdown .search-list-dropdown {
  height: auto !important;
}

@media (max-width: 768px) {
  .modal-postjob-data .modal-content {
    padding: 0px 0px 48px 0px;
  }

  .modal-postjob-data .modal-body {
    padding: 0px 17px 0px;
  }

  .postjob-block-inner {
    padding-top: 31px;
    padding-bottom: 8px;
  }

  .postjob-style-block {
    display: flex !important;
    flex-wrap: nowrap;
  }

  input[class="skyblue"] + span::before {
    border: solid 1px var(--primary-sky);
    background-color: transparent;
    width: 19.5px;
    height: 19.5px;
    margin-top: 4px;
  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal-postjob-data .modal-body h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 31px;
  }

  .post-none-part {
    display: none;
  }

  .con-btn-style {
    width: 86% !important;
  }

  .close-style span {
    display: none;
  }
}
