.doc-view-modal {
  text-align: left !important;
  padding: 20px 98px 60px 98px !important;
}

.desc-view-area {
  width: 100%;
  height: 253px;
  min-height: 253px;
  border: 2px solid #3a3a3a;
  margin-bottom: 64px;
  margin-top: 28px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 15px;
  background-color: var(--primary-light);
}

.lbl-doc-viewer {
  margin-top: 6px;
}

.desc-up-label {
  font-weight: 600;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

@media screen and (max-width: 425px) {
  .desc-view-area {
    min-height: 269px;
    margin-bottom: 28px;
    margin-top: 18px;
    height: 269px;
  }

  .doc-view-modal {
    padding: 30px 98px 30px 98px !important;
  }

  .desc-up-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  /* ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  } */

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #3a3a3a;

    border-radius: 4px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
