/* .modal.fade .modal-dialog {
  right: -2020px !important;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
} */

.auth-modal.right.fade.show .modal-dialog {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.left.fade.show .modal-dialog {
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.close-right.fade:not(.show, .close-left) .modal-dialog {
  /* right: -100vw;
  top: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  transition: opacity 0.3s linear, right 0.3s ease-out !important; */
  transform: translate3d(100vw, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.close-left.fade:not(.show) .modal-dialog {
  transform: translate3d(-100vw, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.right .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  transform: translate3d(100vw, 0, 0);
  transition: transform 0.5s ease;
}

.auth-modal.left .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  transform: translate3d(-100vw, 0, 0);
  transition: transform 0.5s ease;
}

.modal-header {
  display: inline !important;
  color: var(--primary-sky);
  font-family: var(--primary-font-family) !important;
  font-size: 12px;
  font-weight: 700;
  border-bottom: none !important;
}
.modal-dialog {
  max-width: 310px !important;
}

.fed-placeholder-modal .modal-content {
  width: 73%;
  margin: auto;
}

.fed-auth-loader {
  width: 100%;
  height: 100%;
  left: -1%;
  bottom: 0%;
}

.fed-type-title {
  margin-bottom: 70px !important;
}

.auth-modal-back {
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
}

.modal-content {
  background: var(--primary-light);
  box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.56);
}

.modal-body {
  text-align: center;
  padding: 50px 98px 56px 98px;
}

.lbl-auth-or {
  z-index: 999999;
  margin: 20px 0px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-sky);
}

.btn-auth-back {
  display: flex;
}

.bth-auth {
  box-shadow: 0px 0px 6px 2px rgb(169 156 156 / 28%);
}

.btn-signup {
  padding: 9px 18px 9px 18px;
  margin: 6px 0;
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  width: 80%;
  font-family: var(--secondary-font-family);
  text-align: left;
}

.btn-auth-social {
  padding: 9px 18px 9px 18px;
  margin: 6px 0;
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  width: 80%;
  text-align: left;
  font-family: var(--secondary-font-family);
}
.btn-auth-social.btn-dark {
  background-color: #000;
  border-color: #000;
}
.btn-auth-social.btn-primary {
  background-color: #1877f2;
  border-color: #1877f2;
}
.btn-signup.btn-warning {
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}
.btn-signup.btn-warning:hover,
.btn-signup.btn-warning:active,
.btn-signup.btn-warning:focus {
  color: var(--primary-light) !important;
}

.btn-signup.btn-warning:not(:disabled):not(.disabled):active {
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}

.btn-pwa-singin {
  background-color: var(--primary-light);
  border: 2px solid var(--primary-sky) !important;
  color: var(--primary-sky);
  font-weight: 500;
}

.btn-signup img {
  width: 22px;
  height: 22px;
  margin: 4px;
  /* background-color: white; */
  border-radius: 2px;
  margin-right: 10px;
}

.btn-auth-social img {
  width: 22px;
  height: 22px;
  margin: 4px;
  margin-right: 10px;
}
.btn-light {
  color: #757575;
}

.label-back {
  font-family: var(--primary-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-left: 7px;
}

.auth-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1199999;
  left: -1%;
  bottom: 0%;
}

.job-sub-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
  color: var(--primary-dark);
}

.btn-auth-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .modal-body {
    padding: 22px 30px 50px 30px;
  }
  .modal-header {
    padding: 22px 0 0 32px;
  }

  .modal-content {
    background: var(--primary-light) !important;
    box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.9);
  }
}

@media screen and (max-width: 425px) {
  .auth-modal-cancel {
    justify-content: space-between;
  }

  .auth-action-body {
    padding: 10px 30px 39px 30px !important;
  }

  .lbl-auth-or {
    margin: 15px 0px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
}
