.error-msg-apply-job p {
  font-size: 14px;
  text-align: center;
  color: var(--primary-color-danger);
  font-weight: 600;
  font-family: var(--primary-font-family);
  font-style: normal;
  line-height: 20px;
}

.submit-modal {
  padding: 41px 0px;
}

.submit-modal .exp-submitstyle {
  width: 75%;
}

@media screen and (max-width: 425px) {
  .error-msg-apply-job p {
    font-family: var(--secondary-font-family);
  }
}
@media screen and (max-width: 425px) and (min-width: 320px) {
  .exp-submitstyle {
    width: auto;
  }

  .info-detail-body {
    padding: 22px 20px 50px 20px !important;
  }

  .submit-modal .exp-submitstyle {
    width: 100%;
  }
}
