/* --------job Publish modal-------- */
.registration-modal .modal-title {
  padding-bottom: 50px;
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
}
.review-modal-block {
  font-size: var(--medium-font-size);
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-align: center;
}
.btn-style-block {
  width: 50%;
}

@media (max-width: 768px) {
  .review-modal-block {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.3px;
  }
  .btn-style-block {
    width: 90%;
  }
  .modal-footer {
    padding: 0;
  }
}
