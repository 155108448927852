.navbar.navbar-expand-md.navbar-light {
  box-shadow: 0px 0px 60px rgba(172, 172, 172, 0.25);
  background: var(--primary-light);
  display: flex;
  width: 100%;
  margin: 0px;
  padding-top: 10px;
}

.jobpost-outer-main-container {
  background-color: var(--primary-light);
}

.post-job-section {
  font-weight: bold;
  font-size: 40px;
  line-height: 72px;
}
.post-job-section-inner {
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 25px;
}

.employers-button-css {
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  border: 2px solid #0351C7;
  background-color: transparent;
  color: #000;
}

.emp-post-job-btn,
.emp-post-job-btn:focus {
  padding: 10px 58px !important;
  outline: none;
  border-color: var(--primary-sky);
}

.btn-accordion-title {
  padding-left: 8px;
}


.top-and-bottom,
.left-and-right,
.corner-border,
.bottom-corner {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.btn-accordion-title:focus {
  box-shadow: none;
}
.accordion-divider {
  margin-bottom: 200px;
}
.accordion-divider-2 {
  margin-bottom: 50px;
}
.post-img {
  background-image: linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214),
    linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214), linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-size: 20px 0%, 0% 20px, 20px 0%, 65% 20px, calc(100% - 8px) calc(100% - 8px);
  background-position: right bottom, right bottom, right top, right top, right top, 10px 10px;
}

.module1 {
  background-image: linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214),
    linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214), linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-size: 20px 100%, 54% 20px, 20px 0%, 54% 20px, calc(100% - 8px) calc(100% - 8px);

  background-position: right bottom, right bottom, right top, right top, right top, 10px 10px;
}
.module1.img-thumbnail {
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0;
  max-width: 100%;
  height: auto;
  padding-left: 0;

  border: 0 !important;
}
.post-img.img-thumbnail {
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0;
  max-width: 100%;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  border: 0 !important;
}

.postjob-block {
  padding: 100px 0px 70px 0px;
}
.mobile-view-data {
  display: none;
}
.employers-mobile-view {
  display: none;
}
.sky-blue {
  color: var(--primary-sky);
}
.lorem-ipsum-data {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}
.lorem-ipsum-block {
  padding: 70px 0px;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: unset;
  outline: none;
}

.btn.focus,
.btn:focus {
  box-shadow: unset;
  outline: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.home-main-container {
  background-image: url("../../Assets/Images/header_large_back.png");
  background-repeat: no-repeat;
  background-size: 100% 60%;
}
.work-second-section {
  padding: 135px 0px 57px 0px;
}
.meeting-section {
  display: flex;
}
.secound-section .consectetus {
  margin: auto;
}

.secondsection-left-title {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  color: var(--primary-light);
}
.scondsection-left-desc {
  margin-top: 82px;
  margin-bottom: 26px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary-light);
}
.align-items-center .lorem-img {
  text-align: right;
}

/* .work-third-main-container {
  background-image: url("../../Assets/Images/footer_background_section.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 23%;
  padding-bottom: 44px;
  margin-bottom: -32px;
} */

.work-desc-col {
  margin-bottom: 10px;
}

.work-desc-main-container {
  max-width: 561px;
  width: auto;
  height: 516px;
  background: var(--primary-light);
  box-shadow: 0px 0px 42px -5px rgb(196 196 196 / 40%);
  border-radius: 14px;
  padding: 60px;
  margin: auto 20px;
}
.work-desc-main-container.first, .work-desc-main-container.third {
  margin-left: 0 !important;
}

.work-desc-main-container.second, .work-desc-main-container.forth {
  margin-right: 0 !important;
}

.work-card-title {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: var(--primary-dark);
  margin: 21px auto 17px auto;
}

.work-card-detail {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary-grey);
}
.work-img {
  width: 72px;
  height: 72px;
}
.right-work-desc-container {
  margin-top: 60px;
}

@media (max-width: 1440px) {
  .thard-section {
    padding-bottom: 85px;
  }

  .home-main-container {
    background-size: 100% 62%;
  }
}

@media (max-width: 1200px) {

  .work-desc-main-container {
    padding: 32px;
  }

  .work-card-title {
    font-size: 22px;
    line-height: 28px;
  }
  
  .work-card-detail {
    font-size: 15px;
    line-height: 25px;
  }
}

@media (max-width: 1024px) {
  .secound-section {
    padding: 120px 0px 38px 0px;
  }
}
@media (max-width: 991px) {
  .thard-section {
    padding-bottom: 0;
  }
  .support-description {
    margin-top: 0 !important;
  }
  .right-work-desc-container {
    margin-bottom: 60px;
  }
  .work-header-img {
    display: flex !important; 
    justify-content: center;
    margin: 20px 0;
    height: 50vh; /* half the height of the viewport */
    width: auto; /* maintain aspect ratio */
    object-fit: cover; /* ensure the image covers the whole element */
}

  .work-desc-main-container {
    max-width: 100%;
    margin: 0 0 25px 0 !important;
  }

  .secound-section {
    padding: 66px 0px 38px 0px;
  }
}

@media (max-width: 768px) {

  .job-desc-left-img {
    display: none;
  }
  .job-desc-center-img {
    display: block;
    text-align: center;
  }

  .job-desc-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }

  .job-desc-details {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
  }
  .desc-details {
    padding: 51px 28px;
  }

  .fourth-desc-details {
    align-items: center;
    padding: 54px 28px 80px 28px;
  }

  .fourth-job-center-img {
    margin: 30px 0px;
  }
  .thard-section {
    background-color: none;
  }

  .secound-section {
    padding: 66px 0px 38px 0px;
  }

  .jobseek-desc-title {
    text-align: center;
  }

  .scondsection-left-desc {
    margin-top: 22px;
  }
}

@media screen and (max-width: 425px) {
  .rounded-btn {
    padding: 9px 21px !important;
    height: 41px;
  }

  .rounded-btn1 {
    padding: 9px 21px !important;
    height: 41px;
  }
  .secound-section .consectetus {
    margin-top: 27px;
  }

  .third-section-container {
    margin-top: 77px;
  }
}

@media (max-width: 576px) {
  .work-header-img {
    height: auto; /* make height proportional to width */
    width: 100%; /* image width equals to container width */
  }
  .secound-section {
    padding: 25px 0px 38px 0px;
  }
  .scondsection-left-desc {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .employers-main-button {
    font-size: 14px;
    margin-left: 10px;
  }
}
@media (max-width: 425px) {

  .secound-section {
    padding: 0;
  }
  .secondsection-left-title {
    line-height: 32px;
  }
}

@media (max-width: 1199px) {
  .emp-post-job-btn {
    margin-left: 12px;
  }
}

@media (max-width: 1399.98px) {
  .post-job-section {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
  }
}

@media (max-width: 1199.98px) {
  .post-job-section {
    font-weight: bold;
    font-size: 34px;
    line-height: 50px;
  }
  .postjob-block {
    padding: 100px 0px 70px 0px;
  }
  div#navbarNavAltMarkup span {
    font-size: 14px;
    padding: 10px 12px !important;
  }

  @media (max-width: 991.98px) {
    .desktop-view {
      display: none;
    }
    .post-job-desktop-view {
      display: none;
    }
    div#navbarNavAltMarkup span {
      font-size: 14px;
      padding: 10px 12px !important;
    }
    .employers-button-css {
      font-size: 14px;
      line-height: 20px;
      padding: 6px 14px !important;
      font-weight: 500;
    }
    .lorem-ipsum-block {
      padding: 30px 0px;
    }
    .post-job-section-inner {
      padding: 0px 33px;
      font-size: 16px;
      line-height: 24px;
    }
    .postjob-block {
      padding: 20px 0px 34px 0px;
    }
    .emp-btn {
      padding-left: 18px;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
    .post-job-col {
      margin: unset !important;
      display: flex;
      align-items: center;
    }
    .post-job-section-inner {
      margin-top: 40px;
    }
    .accordion-divider {
      margin-bottom: 53px;
    }
  }
}
@media (max-width: 992px) {
  .mobile-view-data {
    display: inline;
  }
}
@media (min-width: 768px) {
  .employers-main-button {
    display: none;
  }
}
@media (max-width: 767px) {
  .secondsection-left-title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
  }
  .employers-main-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 145px;
    max-height: 45px;
    border-radius: 7px;
    color: #fff;
    border: 1px solid var(--top-bar-bg-color, #FFF);
    background-color: #0351C7;
  }
  .employers-mobile-view {
    display: inline;
  }
  .header-light-bulk-image {
    display: none;
  }
}