.lbl-description {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  margin-top: 10px;
  margin-bottom: 7px;
}

.lbl-publish-title > div:first-child{
    font-weight: 600;
}

.btn-add-new-job {
  width: 100%;
  max-width: 184px;
  height: 50px;
  padding: 5px 10px;
  margin: 5px;
  color: white;
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
  font-weight: 500;
  font-size: 16px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}

.btn-job-disable {
  background-color: #808080 !important;
  border: 1px solid #808080 !important;
}

.btn-job-enable {
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
}

.lbl-add-qualification {
  margin-top: 24px;
}

.job-expr-outer-container {
  align-items: center;
}

.rmdp-container {
  width: 100%;
}

.expire-date-header{
  padding-bottom: 10px;
}

/* .tox-statusbar__text-container span {
  display: none;
} */
