@import url(https://fonts.googleapis.com/css?family=Poppins:500,400);
@import url(https://fonts.googleapis.com/css?family=Poppins:500,400);
@import url(https://fonts.googleapis.com/css?family=Poppins:500,400);
.loaderparent{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999;
    bottom: 0%;
}
/* .modal.fade .modal-dialog {
  right: -2020px !important;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
} */

.auth-modal.right.fade.show .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.left.fade.show .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.close-right.fade:not(.show, .close-left) .modal-dialog {
  /* right: -100vw;
  top: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  transition: opacity 0.3s linear, right 0.3s ease-out !important; */
  -webkit-transform: translate3d(100vw, 0, 0);
          transform: translate3d(100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.close-left.fade:not(.show) .modal-dialog {
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.right .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  -webkit-transform: translate3d(100vw, 0, 0);
          transform: translate3d(100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.left .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.modal-header {
  display: inline !important;
  color: var(--primary-sky);
  font-family: var(--primary-font-family) !important;
  font-size: 12px;
  font-weight: 700;
  border-bottom: none !important;
}
.modal-dialog {
  max-width: 310px !important;
}

.fed-placeholder-modal .modal-content {
  width: 73%;
  margin: auto;
}

.fed-auth-loader {
  width: 100%;
  height: 100%;
  left: -1%;
  bottom: 0%;
}

.fed-type-title {
  margin-bottom: 70px !important;
}

.auth-modal-back {
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
}

.modal-content {
  background: var(--primary-light);
  box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.56);
}

.modal-body {
  text-align: center;
  padding: 50px 98px 56px 98px;
}

.lbl-auth-or {
  z-index: 999999;
  margin: 20px 0px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-sky);
}

.btn-auth-back {
  display: flex;
}

.bth-auth {
  box-shadow: 0px 0px 6px 2px rgb(169 156 156 / 28%);
}

.btn-signup {
  padding: 9px 18px 9px 18px;
  margin: 6px 0;
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  width: 80%;
  font-family: var(--secondary-font-family);
  text-align: left;
}

.btn-auth-social {
  padding: 9px 18px 9px 18px;
  margin: 6px 0;
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  width: 80%;
  text-align: left;
  font-family: var(--secondary-font-family);
}
.btn-auth-social.btn-dark {
  background-color: #000;
  border-color: #000;
}
.btn-auth-social.btn-primary {
  background-color: #1877f2;
  border-color: #1877f2;
}
.btn-signup.btn-warning {
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}
.btn-signup.btn-warning:hover,
.btn-signup.btn-warning:active,
.btn-signup.btn-warning:focus {
  color: var(--primary-light) !important;
}

.btn-signup.btn-warning:not(:disabled):not(.disabled):active {
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}

.btn-pwa-singin {
  background-color: var(--primary-light);
  border: 2px solid var(--primary-sky) !important;
  color: var(--primary-sky);
  font-weight: 500;
}

.btn-signup img {
  width: 22px;
  height: 22px;
  margin: 4px;
  /* background-color: white; */
  border-radius: 2px;
  margin-right: 10px;
}

.btn-auth-social img {
  width: 22px;
  height: 22px;
  margin: 4px;
  margin-right: 10px;
}
.btn-light {
  color: #757575;
}

.label-back {
  font-family: var(--primary-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-left: 7px;
}

.auth-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1199999;
  left: -1%;
  bottom: 0%;
}

.job-sub-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
  color: var(--primary-dark);
}

.btn-auth-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .modal-body {
    padding: 22px 30px 50px 30px;
  }
  .modal-header {
    padding: 22px 0 0 32px;
  }

  .modal-content {
    background: var(--primary-light) !important;
    box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.9);
  }
}

@media screen and (max-width: 425px) {
  .auth-modal-cancel {
    justify-content: space-between;
  }

  .auth-action-body {
    padding: 10px 30px 39px 30px !important;
  }

  .lbl-auth-or {
    margin: 15px 0px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
}

.animatable {
  will-change: transform;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.animatable.prepare {
  /* we do this so we can tell when the "preparing" was done
     since there seems to be a browser bug where the preparation still takes
     time, even with `transition: none;` */
  transition-duration: 0.0001s;
  opacity: 1;
}

.animatable.prepare.from.left {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
}
.animatable.prepare.from.right {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
}
.animatable.prepare.to.left {
  -webkit-transform: translateX(-1px);
          transform: translateX(-1px);
}
.animatable.prepare.to.right {
  -webkit-transform: translateX(1px);
          transform: translateX(1px);
}

.animatable:not(.prepare).to {
  pointer-events: none;
}
.animatable:not(.prepare).to.left {
  -webkit-transform: translateX(-110%);
          transform: translateX(-110%);
}
.animatable:not(.prepare).to.right {
  -webkit-transform: translateX(110%);
          transform: translateX(110%);
}
.animatable:not(.prepare).from {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.page-wrapper {
  margin-top: 70px;
}

.form-floating-input {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding-top: 0px;
  height: 55px;
  border-bottom-width: 2px;
}

.form-floating-input input {
  border-bottom: 2px solid var(--primary-sky);
  outline: none;
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding: 18px 0 8px 5px;
  margin-top: 6px;
  background-color: var(--light-silver);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* .floating-label input:focus,
.floating-label textarea:focus {
  border-color: var(--primary-sky);
} */

.floating-label input:focus + span,
.floating-label textarea:focus + span,
.floating-label.floating span {
  padding: 5px 0px 0px 5px;
  font-size: 12px;
}

.form-floating-input span {
  padding-left: 5px;
  opacity: 0.5;
  font-size: 14px;
  color: var(--primary-sky);
}

.password-view-container {
  position: absolute;
  top: 20px;
  right: 16px;
  bottom: 0;
  cursor: pointer;
}

.password-view-container img {
  height: 18px;
  width: 25px;
}

@media screen and (max-width: 425px) {
  .password-view-container img {
    height: 20px;
    width: 23px;
  }
}

.homePageLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  bottom: 10%;
  right: 0%;
}

.recaptcha-container {
  display: none;
}

.modal-header {
  display: inline !important;
  color: #3a3a3a;
  font-family: var(--primary-font-family) !important;
  font-size: 12px;
  font-weight: 700;
  padding: 26px 44px;
  border-bottom: none !important;
}
.modal-dialog {
  max-width: 525px !important;
}
.modal-content {
  background: var(--primary-light);
  box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.56);
  border-radius: 0;
  border: none;
}

.btn-pwa-signUp {
  padding: 5px 44px;
  margin: 5px;
  color: white;
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}
.btn-signup {
  width: 100%;
}
.btn-auth-social {
  width: 100%;
}
.credentials-detalis {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  padding: 10px;
}
.email {
  color: var(--primary-sky);
  padding: 7px;
  font-size: 11px;
}

label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}

.form-floating {
  position: relative;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: #333;
}

label:hover span::before,
label:hover span::before {
  box-shadow: 0 0 2px #ccc;
}

/* label:hover span,
label:hover span {
  color: #000;
} */

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 1px #a8a8a8;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  background: #f6f6f6;
}

input[type="checkbox"]:checked + span::before {
  color: #666;
}

input[type="checkbox"]:disabled + span {
  cursor: default;

  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 18px;
  line-height: 23px;
}

/* input[class="blue"] + span::before {
  border: solid 1px var(--primary-deep-dark);
  background-color: transparent;
  width: 26px;
  height: 26px;
} */

input[class="blue"] + span::before {
  border: solid 2px var(--primary-blue);
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin: 0 9px 0 0;
}

input[class="blue"]:checked + span::before {
  color: var(--primary-deep-dark);
}

.password-change {
  font-size: 14px;
  color: var(--primary-sky);
  font-weight: var(--medium-font-weight);
  cursor: pointer;
}

.sigin-third-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.lbl-keep-login {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-dark);
  font-family: var(--primary-font-family);
}

.login-btn {
  text-align: center;
}
.error-msg p,
.error-msg {
  font-size: 14px;
  text-align: center;
  color: var(--primary-color-danger);
  font-weight: 500;
  font-family: var(--secondary-font-family);
  font-style: normal;
}
.login-modal .modal-dialog {
  max-width: 784px !important;
}
.login-modal .credentials-detalis {
  padding-bottom: 50px;
}
.login-modal .btn-pwa-signUp {
  width: 100%;
  max-width: 214px;
  height: 50px;
}

.keep-login {
  text-align: left;
}
.top-forgot-container {
  display: none;
}
@media (max-width: 767px) {
  .credentials-detalis {
    font-size: 18px;
  }
  .login-modal .credentials-detalis {
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
  }

  .top-forgot-container {
    display: block;
    text-align: right;
    margin-bottom: 11px;
  }
  .bottom-forgot {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .signin-modal-body {
    padding: 0px 30px 38px 30px !important;
  }

  .signin-modal-body .credentials-detalis {
    padding-bottom: 29px;
  }

  .auth-floating-input {
    height: 50px;
  }

  .credentials-detalis {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 375px) {
  .password-change {
    margin: 23px 0px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
}

.privacy-description {
  background: var(--primary-light);
  box-shadow: 0px 0px 42px -5px rgb(196 196 196 / 40%);
  border-radius: 14px;
  padding: 72px 65px;
}

.privacy-title-row {
  margin-bottom: 39px;
}

.privacy-second-section {
  padding: 78px 0px 120px 0px;
}

.privacy-second-main-container {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 5%;
}

.privacy-desc a {
  color: var(--primary-blue);
  word-break: break-word;
}

.m-bold-text {
  color: var(--primary-grey);
}

.privacy-capitalize {
  text-transform: uppercase;
}

.privacy-title {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  color: var(--primary-grey);
  font-family: var(--primary-font-family);
}

.policy-title {
  margin: 10px 0px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.policy-sub-title {
  margin: 10px 0px;
  font-weight: bolder;
  font-size: 16px !important;
  line-height: 25px !important;
}

.privacy-desc {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: var(--primary-grey);
}

.privacy-desc p {
  color: var(--primary-grey);
}

.privacy-sub-desc li::marker {
  color: var(--primary-grey);
  font-size: 24px;
  border-radius: 50%;
}

.privacy-main-container {
  background-repeat: no-repeat;
  background-size: 100% 25%;
}

.privacy-modal-title {
  justify-content: center;
  text-align: center;
}

@media (max-width: 768px) {
  .privacy-description {
    padding: 45px 29px;
  }

  .privacy-title {
    font-size: 22px;
    line-height: 24px;
  }

  .privacy-desc {
    font-size: 14px;
    line-height: 25px;
  }

  .policy-title {
    font-size: 14px !important;
    line-height: 24px;
  }

  .policy-sub-title {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  .privacy-desc p {
    color: var(--primary-grey);
    font-size: 14px;
  }

  .privacy-title-row {
    margin-bottom: 29px;
  }

  .privacy-main-container {
    background-repeat: no-repeat, no-repeat;
    background-position: center top, top bottom;
    background-size: 100% 5%, 100% 97%;
  }

  .privacy-second-section {
    padding: 0px 0px 120px 0px;
  }

  .privacy-second-main-container {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% 4%;
  }
}

@media screen and (max-width: 425px) {
  .privacy-main-container {
    background-repeat: no-repeat, no-repeat;
    background-position: center top, top bottom;
    background-size: 100% 5%, 100% 97%;
  }

  .privacy-second-main-container {
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100% 2%;
  }

  .privacy-second-section {
    padding: 0px 0px 120px 0px;
  }
}

.privacy-desc-view-area {
  width: 100%;
  height: 401px;
  min-height: 401px;
  border: 2px solid #3a3a3a;
  margin-bottom: 46px;
  margin-top: 43px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 15px;
  background-color: var(--primary-light);
  text-align: left;
}

@media screen and (max-width: 425px) {
  .privacy-desc-view-area {
    min-height: 269px;
    margin-bottom: 26px;
    margin-top: 26px;
    height: 269px;
  }

  .privacy-desc-view-area::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  .privacy-modal-title {
    font-weight: 600 !important;
    text-align: center;
  }

  /* Handle */
  .privacy-desc-view-area::-webkit-scrollbar-thumb {
    background-color: #3a3a3a;

    border-radius: 4px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.password-detalis p{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: var(--forms-text-color);
  margin-bottom: 11px;
}

.term-condition {
  font-size: 16px;
  color: var(--primary-blue);
  font-weight: var(--semi-bold-font-weight);
}

.terms-lbl-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accept-terms-condition {
  font-size: 16px;
  color: #3a3a3a !important;
  font-weight: 400 !important;
  -webkit-user-select: none;
          user-select: none;
  font-family: var(--primary-font-family) !important;
  line-height: 20px;
}

/* .check-box label:hover .term-condition {
  color: var(--primary-sky);
} */
.create-account-modal .modal-dialog {
  max-width: 870px !important;
}

.create-account-modal .modal-body {
  padding: 0px 98px 84px 98px;
}

.create-account-modal .login-btn {
  margin-top: 60px;
}

.create-account-modal .btn-pwa-signUp {
  width: 100%;
  max-width: 208px;
  height: 50px;
}

.term-condition label:hover span,
.term-condition label:hover span {
  color: var(--primary-blue) !important;
}

.txt-accept-terms-col {
  padding-top: 2px;
  text-align: left;
  padding-left: 10px;
}

.create-account-modal .credentials-detalis {
  padding-bottom: 50px;
}

.registration-error-msg p {
 text-align: start !important;
} 

@media (max-width: 767px) {
  .create-account-modal .modal-body {
    padding: 0 30px 50px 30px;
  }

  .create-account-modal .credentials-detalis {
    padding-bottom: 39px;
  }

  .create-account-modal .modal-header {
    padding: 22px 0 12px 32px;
  }

  .txt-accept-terms-col {
    padding-left: 10px;
  }
  .modal-open .modal {
    position: fixed !important;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @supports (-webkit-touch-callout: none) {
    .scroll-modal {
      overflow: hidden !important;
    }
  }
}

@media screen and (max-width: 425px) {
  .signup-back {
    float: left;
  }

  .signup-modal-title {
    padding: 0px 0px 24px 0px !important;
  }

  .signup-btn {
    margin-top: 18px !important;
  }

  .create-account-modal .modal-body {
    padding: 24px 30px 24px 30px;
  }

  .accept-terms {
    margin-left: 1px;
  }

  .check-box {
    margin-top: 14px;
  }

  .password-detalis,
  .password-detalis p {
    font-family: var(--secondary-font-family);
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .accept-terms-condition {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a !important;
    font-family: var(--secondary-font-family) !important;
  }

  .term-condition {
    font-size: 14px;
    color: var(--primary-blue);
    font-weight: var(--semi-bold-font-weight);
    font-family: var(--secondary-font-family);
  }

  .txt-accept-terms-col {
    padding-top: 1px;
    padding-left: 0px;
  }
  .term-chkbox-row,
  .terms-checkbox-col {
    margin: 0 0;
    padding: 0;
  }
}

@media screen and (max-width: 408px) {
  .terms-checkbox-col {
    padding-left: 0px;
  }

  .txt-accept-terms-col {
    padding-left: 0px;
  }

  .term-chkbox-row {
    margin: auto 0;
  }
}

.btn-pwa-forgot-container {
  margin-top: 46px;
}

.forgot-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
}

.forgot-back-container {
  text-align: center;
  display: flex;
}

.success-msg,
.success-msg p {
  color: #487b03;
}

.forgot-title {
  padding-bottom: 42px !important;
}

.forgot-input {
  margin-top: 38px;
}

.forgot-pass-body {
  padding: 0px 98px 56px 98px;
}

@media screen and (max-width: 425px) {
  .forgot-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: var(--secondary-font-family);
  }

  .forgot-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .success-msg {
    font-family: var(--secondary-font-family);
  }

  .forgot-back-container {
    text-align: center;
    display: flex;
    /* padding: 10px 5px; */
  }
}

.user-setting-table {
  text-align: left;
}

.user-setting-table td {
  padding: 5px;
  border-top: none;
}

.lbl-edit-email {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding-left: 6px;
  color: var(--primary-LightCoral);
}

.lbl-setting-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conf-pass-title {
  padding-bottom: 28px !important;
}

.conf-subtitle {
  margin-bottom: 28px;
}

.edit-setting {
  cursor: pointer;
}

.lbl-setting-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  color: var(--primary-LightCoral);
}

.change-pass-body {
  padding: 0px 98px 56px 98px;
}

.conf-pass-body {
  padding: 0px 98px 56px 98px;
}

.lbl-setting-desc {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 35px;
  color: var(--primary-dark);
}

.btn-setting-container {
  margin-top: 64px;
}

.setting-inner-body {
  display: none;
}

.btn-chngpass-container {
  margin-top: 60px;
}

.btn-confpass {
  margin-top: 44px;
}

.edit-detail-body {
  padding: 0px 98px 56px 98px;
}

@media screen and (max-width: 425px) {
  .user-setting-table {
    display: none;
  }
  .setting-inner-body {
    display: block;
    padding: 0px 9px;
  }
  .lbl-setting-title {
    text-align: left;
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: var(--primary-LightCoral);
    opacity: 0.54;
    margin-top: 12px;
  }

  .setting-desc-container {
    display: flex;
    justify-content: space-between;
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    color: var(--primary-dark);
  }

  .change-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .btn-chngpass-container {
    margin-top: 27px;
  }

  .edit-detail-body {
    padding: 0px 20px 50px 20px !important;
  }

  .edit-detail-input-container {
    padding: 19px 0 14px 0;
  }

  .change-pass-title {
    padding-bottom: 23px !important;
  }

  .conf-pass-body {
    padding: 0px 30px 38px 30px;
  }

  .btn-confpass {
    margin-top: 23px;
  }
}

@media screen and (max-width: 425px) {
  .btn-extend-ses {
    max-width: 197px !important;
    height: 44px !important;
  }
}

.btn-sessionexp-close {
  margin-top: 22px;
}

@media (max-width: 992px) {
  .search-button-css {
    width: auto;
    text-align: start;
  }
}
@media (max-width: 767px) {
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: none;
  }

  button.btn.btn-primary.contact-btn.space {
    margin: -20px 1px 35px 0px;
  }

  .navbar-manage {
    padding: 0px !important;
  }
  .mobile-view.nw-padding {
    padding: 25px 2px 6px;
  }
  div#navbarNavAltMarkup a {
    margin-right: 0px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.07em;
    padding-left: 18px;
  }
  .dropdown-menu.sub-drop.show {
    padding: 10px 20px;
  }
  .dropdown-menu.sub-drop.show a {
    font-size: 16px !important;
  }
  nav.navbar.navbar-expand-md.navbar-light {
    padding: 0px;
  }
  /*.navbar-nav {
    margin-top: 10px;
}*/
  section.navbar-box-shdows {
    padding-bottom: 0px;
  }
  .search-button-css {
    border-radius: 0px;
  }
  nav img {
    margin-left: 15px;
  }
  .dropdown-menu.show a {
    border-top: 0px;
  }
  h2 {
    font-size: 24px !important;
    line-height: 36px !important;
  }
  body p {
    font-size: 16px;
  }
  .desktop-view {
    display: none !important;
  }
  button.desktop-view {
    display: none;
  }
  .we-help-text {
    margin-top: 30px;
  }

  body h3 {
    font-size: 20px;
  }
  section.lorem-ipsum-section {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .image-lorem-col h3,
  .image-lorem-col h2 {
    padding-left: 15px;
  }

  .image-lorem-col {
    padding-right: 0px !important;
  }
  .image-lorem-col img {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .box-items-first {
    border-top: none;
    margin-top: 20px;
  }
  .box-items {
    padding: 25px;
  }
  .height {
    display: none;
  }

  .box-items img {
    margin-bottom: 31px;
  }
  .text-apron-right-side-img {
    display: none;
  }
  section.Our-Specialities {
    padding-bottom: 40px;
  }
  .box-items p {
    font-size: 16px;
  }
}
nav.navbar img {
  /*margin-left: -15px;*/
}

@media (min-width: 768px) {
  button.mobile-view {
    display: none;
  }
}

@media (max-width: 1250px) and (min-width: 992px) {
  div#navbarNavAltMarkup a {
    margin-right: 2px;
    color: #000;
    font-size: 16px;
    padding-right: 15px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .col-sm-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media (max-width: 768px) {
  .text-apron-right-side-img {
    display: none;
  }

  div#navbarNavAltMarkup a {
    font-size: 16px;
  }
}
.btn.btn-primary.contact-btn.desktop-view a {
  color: var(--primary-light);
  text-decoration: none;
}
button.btn.btn-primary.contact-btn.mobile-view a {
  color: var(--primary-light);
  text-decoration: none;
}
@media (max-width: 768px) {
  .tab-view {
    display: none;
  }
  .desktop-view {
    display: none;
  }
}
h2.mobile-view.spaces.head-padd {
  padding: 12px 14px 21px;
}

/*json data css*/
.list-items ul li {
  font-size: 18px;
  font-family: var(--primary-font-family);
  line-height: 27px;
  font-weight: 400;
  padding-bottom: 12px;
}

.nw-sec {
  border: unset !important;
}

.nw-brder {
  border-bottom: 15px solid #81b214;
  padding-top: 35px;
}

.lbl-support {
  width: 178px;
}
.nav-item-container {
  align-items: center;
}

.dropdown-item-divider {
  border-bottom: 1px solid var(--primary-blue);
  width: 313px;
  height: 0px;
  margin: auto;
  padding: 0;
}

.mobile-dropdown-container {
  position: unset;
}

.mobile-dropdown-container .desktop-menu-container {
  text-align: center;
  padding: 20px 0px !important;
  width: 250px;
  height: auto;
  right: 80px;
  border-radius: 5px;
  box-shadow: 0px 4px 25px rgb(0 0 0 / 21%) !important;
}

.mobile-dropdown-container .desktop-menu-container a {
  font-size: 16px !important;
  line-height: 24px;
  border-top: unset !important;
}

ul.available-job-position-list li:before {
  content: "\2022";
  color: #f5c80e;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.dec {
  position: relative;
  height: 14.2em;
  margin: 0 0 1em 0;
  overflow: hidden;
}

.item12 {
  background: #fdfdfd;
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 4px rgb(0 0 0 / 5%);
  border-radius: 10px;
  padding-bottom: 60px;
  margin-bottom: 40px;
}

section.joblist-sections-for-page {
  margin-top: 50px;
  /*margin-bottom: 50px;*/
}

p.heading-title {
  margin-bottom: 0px;
  background: var(--primary-sky);
  padding: 10px 100px;
  color: #ffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: bold;
}

p.location-manage {
  background: var(--primary-sky);
  padding: 0px 100px 10px;
  color: #ffff;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: var(--primary-light);
}

.dec {
  padding: 0px 100px 10px;
}

.learn-more a {
  float: right;
  background: #fdfdfd;
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 31px rgb(0 0 0 / 5%);
  border-radius: 4px;
  padding: 10px 20px;
  margin-right: 100px;
  font-weight: 700;
  font-family: var(--primary-font-family);
  color: var(--primary-deep-dark);
  text-decoration: none !important;
}

form.form-submit label {
  font-family: var(--primary-font-family);
  font-size: 18px;
}

.form-submit input {
  height: 70px !important;
  border: 2px solid rgba(43, 46, 57, 0.4);
  border-radius: 7px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  margin-top: -22px;
}

.form-submit input::-webkit-input-placeholder {
  font-family: var(--primary-font-family);
}

.form-submit input::placeholder {
  font-family: var(--primary-font-family);
}

.spaces {
  padding-bottom: 15px;
}

.spacess {
  padding-bottom: 20px;
}

.accordion {
  max-width: 800px;
  margin: 0px auto;
}

.head-padd {
  padding: 15px 02px 21px;
}

/*search*/
section.body-part.search {
  background-image: linear-gradient(#ffffff00, #d6dec3);
}

.row.search-row {
  padding: 80px 0px 40px;
}

.search-row .row {
  padding-top: 10px;
}

.send-resume.text-center {
  margin: 0px 60px;
  padding: 25px;
  background: var(--primary-light);
  border-radius: 6px;
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 4px rgb(0 0 0 / 5%);
  opacity: 0.9;
}

.search-row label {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
}

.search-row .form-control {
  border: none;
  border-bottom: 1px solid var(--primary-sky);
  background: #fdfdfd;
  border-radius: 4px;
}

.search-row .dropdown-toggle {
  background-color: #fdfdfd !important;
  border-radius: 4px;
  border: none;
  border-bottom: 1px solid var(--primary-sky);
  color: #162937;
}

.search-row .dropdown-toggle:hover {
  background-color: #fdfdfd !important;
  color: #162937 !important;
}

.search-row button.btn-primary.first-btn {
  background: var(--primary-sky) !important;
  border: none;
  border: none;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  padding-right: 40px;
  padding-left: 40px;
}

.search-row button.btn.btn-primary.second-btn {
  background: #fdfdfd !important;
  border: none;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #b8b8b8;
}

.search-row button.btn.btn-primary.submit-btns {
  background: var(--primary-sky);
  border: none !important;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}

.search-row .form-control::-webkit-input-placeholder {
  color: #acacac;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.search-row .form-control::placeholder {
  color: #acacac;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

.search-row button.btn.btn-primary.dropdown-toggle {
  color: #4f4f4f;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-transform: lowercase;
}

.search-row .send-resume p {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.search-row .send-resume h3 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  color: #000;
}

.setting-icon {
  height: 30px;
  width: 30px;
}

.dropdown-toggle::after {
  color: #5ba3c8 !important;
}

/*json data css end*/

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 3.2s !important;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.map {
  box-shadow: 0px 0px 12px #00000085;
  border-radius: 0px !important;
  padding: 0px !important;
}

.close {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .close {
  display: block;
}

.navbar-toggler[aria-expanded="false"] .close {
  display: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  display: none;
}

body span.navbar-toggler-icon {
  background-image: url(/static/media/menu.d601269e.svg) !important;
  height: 24px;
  margin-right: 10px;
}

.custom-navbar-toggler {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.navbar-brand-container label {
  margin-bottom: 0;
}

.navbar-toggler .close {
  margin-right: 13px;
  margin-top: -7px;
}

*:focus {
  outline: 0 !important;
}

div#navbarNavAltMarkup a {
  font-family: var(--primary-font-family);
  font-size: 18px;
  color: #000;
  font-weight: 400;
  padding: 10px;
  padding-right: 50px;
}

div#navbarNavAltMarkup span {
  font-family: var(--primary-font-family);
  font-size: 16px;
  color: var(--very-dark-grayish);
  font-weight: 400;
  padding: 10px;
  padding-right: 50px;
}

.navbar-light .navbar-toggler {
  border: none;
}

body h2 {
  font-family: var(--primary-font-family);
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: var(--primary-deep-dark);
  line-height: 58px;
}

body h3 {
  color: var(--primary-sky);
  font-family: var(--primary-font-family);
  font-weight: bold;
}

body p {
  font-family: var(--primary-font-family);
  font-size: 18px;
  color: var(--primary-deep-dark);
}

.search-button-css,
.search-button-css:focus {
  background: var(--primary-sky);
  border-radius: 6px;
  color: var(--primary-light) !important;
  padding: 10px 14px !important;
  transition: all ease-in-out 0.5s;
  font-size: 18px;
  line-height: 27px;
}

a.nav-item.nav-link.disabled.search-button-css.search-button-navbar:hover {
  color: #000 !important;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

a.nav-item.nav-link.disabled.search-button-css.search-button-navbar:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  color: var(--primary-light) !important;
  /*background: #0069d9;*/
}

.navbar-nav .active,
.navbar-nav .active a {
  color: var(--primary-sky) !important;
}

section.navbar-box-shdows {
  box-shadow: 0px 0px 60px rgb(0 0 0 / 25%);
}

/* section.navbar-box-shdows a {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  padding: 10px;
  padding-right: 50px;
} */

.navbar-inner .navbar-nav a {
  font-size: 20px;
  color: var(--primary-deep-dark);
  font-weight: 500;
  padding: 10px 20px !important;
}

.navbar-inner .navbar-nav span {
  font-size: 20px;
  color: var(--primary-deep-dark);
  font-weight: 500;
  padding: 10px 20px !important;
  transition: all ease-in-out 0.5s;
}

.navbar-nav .nav-link:hover {
  color: var(--primary-sky) !important;
  transition: all ease-in-out 0.5s;
}

div#navbarNavAltMarkup span:hover {
  font-weight: 700;
  /* transition: all ease-in-out 0.5s; */
  cursor: pointer;
}

.top-navbar {
  background-color: var(--primary-light);
}

a.navbar-brand {
  font-weight: 600;
  margin-left: 10px;
}

.footer-section p a {
  color: var(--primary-light);
  text-decoration: none;
  opacity: 0.64;
}

.footer-section p a:hover {
  color: var(--primary-sky);
}

.footer-section .address p {
  color: var(--primary-light);
  opacity: 0.64;
}

.footer-section .copyright p {
  color: var(--primary-light);
  opacity: 0.64;
}

.dropdown-menu a:hover {
  font-weight: 700 !important;
  background: var(--primary-light);
}

.dropdown-menu a {
  border-left: 7px solid var(--primary-light);
}

section.Hospitality-section {
  margin-top: 60px;
  margin-bottom: 60px;
}

.hospitality-row .img-thumbnail {
  background: none;
  border: 0px;
  padding-left: 0px !important;
  padding: 0px;
  border-radius: 0px;
}

.contact-btn {
  font-family: var(--primary-font-family);
  font-size: 18px;
  padding-left: 20px !important;
  padding-right: 20px !important;
  height: 50px;
  line-height: 20px;
}

/**********lorem iopsum section ************/
.lorem-ipsun-row .img-thumbnail {
  border: none;
  padding: 0;
  border-radius: unset;
}

.image-lorem-col {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

section.lorem-ipsum-section {
  padding-top: 40px;
  padding-bottom: 60px;
}

section.Our-Specialities {
  background: rgba(129, 178, 20, 0.16);
  padding-top: 60px;
  padding-bottom: 60px;
  transition: all ease-in-out 0.5s;
}

.box-items {
  /* padding: 20px; */
  background: #ffff;
  margin-bottom: 30px;
  box-shadow: 0px 0px 106px -5px rgba(196, 196, 196, 0.4);
  border-top: 10px solid var(--primary-light);
  transition: all ease-in-out 0.5s;
  padding: 65px 40px 87px;
  /* max-width : 353px; */
}

.box-items-first {
  border-top: 10px solid var(--primary-light);
  transition: all ease-in-out 0.5s;
}

.box-items:hover {
  border-top: 10px solid #81b214;
  /* transition:  all ease-in-out 300ms; */
  transition: all ease-in-out 0.5s;
}

.navbar-nav .show .dropdown-toggle::after {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

/*.show a#navbarDropdownMenuLink {
    color: #3FA4CC;
}*/
.height {
  margin-top: 80px;
}

.box-items img {
  margin-bottom: 31px;
}

.text-apron-right-side-img {
  padding: 0px !important;
}

/**********new page css start here ************/
.restaurant-first-section .img-thumbnail {
  border: none;
  padding: 0;
  margin-left: 15px;
  border-radius: 0px;
}

.row.restaurant-row {
  padding: 97px 0px;
  padding-top: 66px;
  padding-right: 0px;
  padding-bottom: 60px;
  padding-left: 0px;
}

.row.restaurant-row .btn-primary {
  color: var(--primary-light);
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}

.row.restaurant-row .btn-primary:hover {
  color: var(--primary-light);
  background-color: #0069d9;
  border-color: #0062cc;
}

.postjob-accordion .faq-head h3 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
}

.postjob-accordion .faq-head h2 {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 58px;
  text-align: center;
  color: var(--primary-deep-dark);
  margin-bottom: 30px;
}

.postjob-accordion .card-header {
  border-bottom: none !important;
  background-color: var(--primary-light) !important;
}

.postjob-accordion .card {
  margin-bottom: 20px !important;
  padding: 10px;
  border-radius: 12px !important;
  border: 2px solid #c4c4c4 !important;
}

button.btn.btn-link.collapsed:before {
  font-family: "FontAwesome";
  content: "\f067";
  float: right;
  padding-top: 0px;
  margin-left: 10px;
  color: var(--primary-sky);
}

button.btn.btn-link:before {
  font-family: "FontAwesome";
  content: "\f068";
  float: right;
  padding-top: 0px;
  margin-left: 10px;
  color: var(--primary-sky);
}

.postjob-accordion button.btn.btn-link {
  width: 100%;
  text-align: left;
}

.postjob-accordion button.btn.btn-link.collapsed {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-sky);
  text-decoration: none;
}

.postjob-accordion .btn-link {
  font-weight: 400;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-sky);
  white-space: normal;
}

.postjob-accordion .btn-link:hover {
  text-decoration: none;
  color: var(--primary-sky);
}

.postjob-accordion .btn-link:focus {
  text-decoration: none;
  color: var(--primary-sky);
}

.postjob-accordion .card-body {
  flex: 1 1 auto;
  padding: 0px 30px 50px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

button.btn.btn-primary.contact-formm {
  color: var(--primary-light);
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}

button.btn.btn-primary.contact-formm:hover {
  color: var(--primary-light);
  background-color: #0069d9;
  border-color: #0062cc;
}

.col-sm-12.faq {
  padding: 0px !important;
  margin-top: 70px;
}

button.btn.btn-primary.contact-btn {
  background: var(--primary-sky);
  border: 1px solid var(--primary-sky);
}

button.btn.btn-primary.contact-btn:hover {
  background: #0069d9;
}

.text-apron-right-side-img {
  padding: 0px !important;
}

.mission-row {
  background: var(--primary-deep-dark);
  border-top: 15px solid #81b214;
  display: block;
}

.about-img {
  padding-right: 0 !important;
}

/*about us page*/

/*.mission-row .desktop-view{
    display:block;
}*/
.our-mission h2,
.our-mission p {
  color: var(--primary-light);
}

.our-mission-section {
  padding-top: 200px;
}

.getintouch-row {
  padding: 50px;
  box-shadow: 0px 4px 100px -5px rgb(196 196 196 / 40%);
  background: var(--primary-light);
  /* background-image: url("images/contact box.png"); */
  position: relative;
}

.getintouch-row:before {
  position: absolute;
  content: "";
  display: block;
  border-top: 15px solid #81b214;
  width: 40%;
  left: 0;
  top: -16px;
}

.getintouch-row:after {
  position: absolute;
  content: "";
  display: block;
  border-left: 15px solid #81b214;
  left: -10px;
  height: 78%;
  top: -16px;
  z-index: 9;
}

.getintouch-section {
  margin-top: 100px;
  margin-bottom: 0px;
}

button.btn.btn-primary.contact-btn.space {
  margin-bottom: 15px;
}

.submit-btn {
  margin-top: 33px;
  color: var(--primary-light);
  background: var(--primary-sky);
  border-radius: 6px;
  border: 1px solid var(--primary-sky);
  width: 164px;
  height: 50px;
}

.desktop-view .btn-space button.btn.btn-primary.dropdown-toggle {
  left: 10px;
  position: relative;
}

.active-menu {
  font-weight: 700 !important;
}

.dec p,
.dec1 p {
  color: #acacac;
}

.dec ul li,
.dec1 ul li {
  color: #acacac;
}

p.short-description {
  color: #000;
}

/********** pagination css ************/
div#pagination-container {
  margin: 0 auto;
  text-align: center;
  display: flex;
}

div#pagination-container ul {
  display: flex;
  margin: 0 auto;
}

div#pagination-container ul li {
  list-style: none;
}

div#pagination-container {
  padding-bottom: 50px;
}

div#pagination-container a.page-link.prev {
  background: rgba(172, 172, 172, 0.5);
  padding: 10px 20px;
  border-radius: 20px;
}

span.current {
  background: var(--primary-sky);
  padding: 10px 20px;
  border-radius: 50px;
  /* margin-top: 47px; */
  position: relative;
  top: 10px;
}

div#pagination-container a.page-link.next {
  background: rgba(172, 172, 172, 0.5);
  padding: 10px 20px;
  border-radius: 20px;
}

div#pagination-container li {
  margin-right: 20px;
  color: #000 !important;
}

div#pagination-container ul li a {
  background: rgba(172, 172, 172, 0.5);
  padding: 10px 20px;
  border-radius: 20px;
}

/*.item12-popup {
    position: relative;
    bottom: 400px;
}
*/
a.bkserch {
  text-transform: uppercase;
  font-weight: 700;
}

span.apply12 {
  font-weight: 700;
  box-shadow: 0px 4px 4px 0px #00000042;
}

a.bkserch {
  color: #5ba3c8;
  text-decoration: none !important;
}

/**********************/
input#search,
.search-row button.btn-primary.first-btn,
.search-row button.btn.btn-primary.second-btn {
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 31px rgb(0 0 0 / 5%);
}

.drop-for-serch-page {
  box-shadow: 4px 0px 4px rgb(0 0 0 / 5%), 0px 4px 31px rgb(0 0 0 / 5%);
}

/**************** popup css ******************/
.modal-dialog-full-width {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;
}

.modal-content-full-width {
  height: auto !important;
  min-height: 100% !important;
  border-radius: 0 !important;
  background-color: #ececec !important;
}

.modal-header-full-width {
  border-bottom: 1px solid #9ea2a2 !important;
}

.modal-footer-full-width {
  border-top: 1px solid #9ea2a2 !important;
}

html {
  scroll-behavior: smooth;
}

div#exampleModalPreview {
  padding-left: 20%;
}

.search-row button.btn.btn-primary.dropdown-toggle:focus {
  background: var(--primary-light) !important;
}

.navbar {
  box-shadow: none;
}

.search-button-css:hover {
  background: #1b7a9f;
  transition: all ease-in-out 0.5s;
}

p.text-after-position {
  margin-bottom: 50px;
}

.list-items ul li {
  list-style: none;
  position: relative;
}

.list-items ul li:before {
  content: "\2022";
  color: var(--primary-sky);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 33px;
  position: absolute;
  margin-left: -1em;
}

/* navbar */
.navbar-block {
  padding: 0px 100px;
}

.navbar-jobs-block {
  padding: 0px 20px;

}

.navbar-expand-md .navbar-jobs-block.navbar-collapse {
  padding: 0px 50px;
  flex-grow: unset;
  width: 100%;
  flex-basis: unset;
  z-index: 1000;
}

.navbar-inner.navbar-collapse {
  flex-grow: unset;
  flex-basis: unset;
}

.disable-candidate-menu {
  color: var(--secondary-color-skin) !important;
  pointer-events: none;
}

@media (max-width: 1400px) {
  .navbar-inner .navbar-nav a {
    padding: 10px 10px !important;
  }

  .navbar-brand-container {
    margin-right: 0px;
  }

  .lbl-support {
    width: 77px;
  }
}

@media (max-width: 1201px) and (min-width: 992px) {
  div#navbarNavAltMarkup a {
    margin-right: 2px;
    color: var(--very-dark-grayish);
    font-size: 16px;
  }

}

@media (max-width: 1090px) {
  .navbar-expand-md .navbar-jobs-block.navbar-collapse {
    padding: 0px 10px;
  }
}
@media (max-width: 1212px) {
  .navbar-jobs-block {
    padding: 0px 10px !important;
  }
  .mobile-dropdown-container .desktop-menu-container {
    right: 42px;
  }
}

@media (max-width: 1171px) and (min-width: 992px) {
  .navbar-jobs-block {
    padding: 0px 10px !important;
  }
  .res-navbar-container .navbar-nav .employers-button-css {
    padding: 2px 10px !important;
  }
}

@media (max-width: 991px) {
  .nonauth-dropdown-menu-container {
    width: 258px;
    height: auto;
    border-radius: 5px;
    padding: 20px 0px;
    text-align: center;
    -webkit-transform: translate(0px, 0px) !important;
            transform: translate(0px, 0px) !important;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 21%);
  }
  .dropdown-items {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: #3a3a3a;
  }

  .dropdown-menu-right .dropdown-items:hover {
    font-weight: 500;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  .dropdown-item-divider {
    width: 210px;
    margin-bottom: 5px;
  }
  .dropdown-menu-container {
    margin: 0px;
    text-align: center;
    padding: 20px 0px;
    width: 258px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 21%);
  }

  .nav-search-btn {
    display: none;
  }

  .nav-search-button {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
    padding-bottom: 0px;
    padding-top: 0px;
    border: navajowhite;
    box-shadow: 0px 0px 25px -5px rgba(196, 196, 196, 0.4);
  }
}
@media (max-width: 996px) {
  .setting-icon {
    height: 20px;
    width: 20px;
  }
}
@media (max-width: 991px) {
  .navbar-inner .navbar-nav a {
    border-top: 1px solid var(--primary-deep-dark);
  }

  .navbar-inner .navbar-nav a img {
    padding-bottom: 19px;
  }

  /* section.navbar-box-shdows {
    box-shadow: 0px 0px 60px rgb(0 0 0 / 11%);
  } */

  div#navbarNavAltMarkup a {
    font-size: 16px;
  }

  div#navbarNavAltMarkup span {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .navbar-block {
    padding: 0px 5px;
  }

  .navbar-jobs-block {
    padding: 0 5px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .col-sm-6 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

@media (min-width: 768px) and (max-width: 769px) {
  .our-mission-section {
    padding-top: 0;
  }

  .our-mission {
    padding-top: 20px;
  }
}

@media (min-width: 769px) {
  .drop-down-list-for-emplyee .show a {
    font-size: 18px !important;
    border-bottom: 1px solid #c4c4c4;
  }

  .leftpadding {
    padding-left: 15px;
  }

  .getintouch-row {
    margin-left: 0;
    margin-right: 0;
    left: 0;
    box-shadow: none;
    padding: 0px !important;
    margin-top: 39px;
  }

  .getintouch-row:before {
    display: none;
  }

  .getintouch-row:after {
    display: none;
  }

  .getintouch-section {
    margin-top: 0;
    /*margin-bottom: 150px;*/
    border-top: 20px solid #81b214;
  }

  .getintouch-form .form-group input {
    height: 50px;
  }
}

@media (max-width: 768px) {
  p.heading-title,
  p.location-manage,
  .dec {
    padding: 10px 20px;
  }

  .dec {
    -webkit-line-clamp: 10;
  }

  .manage-left-right-margin21 {
    margin-left: 20px;
    margin-right: 20px;
  }

  nav.navbar.navbar.navbar-inverse.navbar-fixed-top.navbar-expand-md {
    position: absolute;
    display: flex;
    background: var(--primary-light);
    width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 60px rgb(0 0 0 / 11%);
    margin-top: -11px;
    padding-top: 20px;
    padding-bottom: 11px;
  }

  .hospitality-row .col-md-10 {
    max-width: 100% !important;
    flex: 100% 1;
    padding: 0;
  }

  .dropdown-menu.sub-drop a {
    white-space: break-spaces;
    font-size: 16px !important;
    /*padding  : 9px 0px 0px 5px !important;*/
  }

  .nav-item {
    border-top: 1px solid #00000059;
  }

  button.btn.btn-primary.contact-btn:hover {
    background: #0069d9;
  }

  button.btn.btn-primary.contact-btn:active {
    color: var(--primary-light);
    background-color: #0062cc;
    border-color: #005cbf;
  }

  /*body p{margin-top: 15px;}*/
  img.img-thumbnail.job {
    margin-top: 0px;
  }

  .col-sm-12.faq {
    padding: 0px !important;
    margin-top: 0px;
  }

  .card-header {
    padding: 0px !important;
  }

  .postjob-accordion .card-body {
    padding: 10px !important;
  }

  .col-md-6.col-sm-12.image-restaurant-col.justify-content-center.align-self-center.head-img {
    padding: 0px !important;
    margin-left: -15px !important;
  }

  /* .mobile-view {
    display: none;
  } */
  button.mobile-view {
    display: none;
  }

  .text-apron-right-side-img {
    display: none;
  }

  .spaces.head-padd {
    padding: 78px 14px 30px 14px;
  }

  .send-resume.text-center {
    margin: 0px;
  }

  .search-row label {
    font-size: 16px;
  }

  .dropdown.btn-space.show {
    margin-left: 30px;
  }

  div#pagination-container ul {
    padding-left: 0px;
  }

  div#pagination-container li {
    margin-right: 5px;
    color: #000 !important;
  }

  .dec {
    margin-bottom: 20px;
  }

  .list-items ul {
    margin-bottom: 0px;
  }

  .manage-last-image-mobile {
    width: 80px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 20px;
  }

  .nw-brder {
    width: 90% !important;
    text-align: center;
    margin: 0 auto;
  }

  .pos-mb {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .row.restaurant-row {
    padding-bottom: 10px;
  }

  .leftpadding {
    padding-left: 0x;
  }
}

@media (max-width: 767px) {
  .footer-section .row {
    display: grid !important;
  }

  .cnt {
    order: 1;
  }

  .address {
    order: 2;
  }

  .policys {
    order: 3;
  }

  .copyright {
    order: 4;
  }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border: none;
  }

  .navbar-manage {
    padding: 0px !important;
  }

  div#navbarNavAltMarkup a {
    margin-right: 0px;
  }

  div#navbarNavAltMarkup span {
    margin-right: 0px;
  }

  nav.navbar.navbar-expand-md.navbar-light {
    display: flex;
    background: var(--primary-light);
    width: 100%;
    margin: 0px;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.11);
    padding: 14px 0;
  }

  .navbar-nav {
    margin-top: 10px;
  }

  section.navbar-box-shdows {
    padding-bottom: 0px;
  }

  .search-button-css {
    padding-left: 17px !important;
  }

  .dropdown-menu.show a {
    border-top: 0px;
  }

  h2 {
    font-size: 24px !important;
    line-height: 36px !important;
  }

  body p {
    font-size: 16px;
  }

  .desktop-view {
    display: none;
  }

  button.desktop-view {
    display: none;
  }

  .we-help-text {
    margin-top: 30px;
  }

  body h3 {
    font-size: 20px;
  }

  section.lorem-ipsum-section {
    padding-top: 0px;
    padding-bottom: 60px;
  }

  .image-lorem-col h3,
  .image-lorem-col h2 {
    padding-left: 15px;
  }

  .image-lorem-col {
    padding-right: 0px !important;
  }

  .image-lorem-col img {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .box-items-first {
    border-top: none;
    margin-top: 20px;
  }

  .box-items {
    padding: 58px 41px 67px;
  }

  .height {
    display: none;
  }

  .box-items img {
    margin-bottom: 15px;
  }

  .text-apron-right-side-img {
    display: none;
  }

  section.Our-Specialities {
    padding-bottom: 40px;
  }

  .box-items p {
    font-size: 16px;
  }

  .dropdown-menu-container {
    -webkit-transform: translate(-10px, -10px) !important;
            transform: translate(-10px, -10px) !important;
  }
}

@media (max-width: 600px) {
  .mission-row {
    border-top: none;
  }

  .our-mission {
    border-top: 15px solid #81b214;
    padding: 50px;
  }

  .mission-row .mobile-view {
    display: block;
  }

  .mission-row .desktop-view {
    display: none;
  }

  .our-mission-section {
    padding-top: 0;
  }

  .about-img {
    padding-left: 0 !important;
  }

  .about-img img {
    padding: 0 !important;
  }

  .about-img .we-help-text {
    padding: 0px 15px;
  }

  .about-img button.about-contact-btn {
    margin-left: 15px;
  }

  /*button.btn.btn-primary.contact-btn {
    background: #3FA4CC;
    border: 1px solid #3FA4CC
 }
button.btn.btn-primary.contact-btn:hover {
    background: #0069d9;
}*/
  .about-img .contact-btn.mobile-view {
    margin-left: 15px;
  }

  .mission-image {
    padding: 0px !important;
  }

  .contact-btn {
    font-size: 16px;
    line-height: 25px;
  }
}

@media (max-width: 425px) {
  .dec {
    -webkit-line-clamp: 6 !important;
  }
  .dropdown-menu-container {
    -webkit-transform: translate(-10px, -10px) !important;
            transform: translate(-10px, -10px) !important;
  }

  .font-style,
  .font-style input,
  .font-style td {
    font-family: var(--secondary-font-family);
  }

  .postjob-accordion button.btn.btn-link.collapsed,
  .postjob-accordion .btn-link {
    font-size: 18px;
  }

  .postjob-accordion .card-body {
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .right-state {
    max-width: 50%;
  }

  .left-city {
    max-width: 50%;
  }

  .state {
    display: flex;
  }
}

div.job_h1 {
  font-weight: 700;
}

div.job_h2 {
  font-weight: 600;
}

div.job_h3 {
  font-weight: 500;
}

div.job_h4 {
  font-weight: 500;
}

div.job_h5 {
  font-weight: 500;
}

div.job_h6 {
  font-weight: 500;
}
@media only screen and (max-width: 880px) and (min-width: 767px) {
  .employers-button-css {
    padding: 8px 14px !important;
  }
}
.pagebodyitem{
    width: 90%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.rowitem{
    width: 40%;
}
.modal-postjob-data .modal-content {
  padding: 50px 0px 54px;
  background: var(--primary-light) !important;
  border-radius: 0;
}

.modal-postjob-data .modal-body h5 {
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  padding-bottom: 31px;
}

.modal-postjob-data .modal-body {
  padding: 0px 177px 0px;
  font-size: 18px;
  line-height: 27px;
  text-align: start;
}

.postjob-terms-condition {
  font-size: 14px !important;
  color: #162937 !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  -webkit-user-select: none;
  user-select: none;
}

.modal-postjob-data .modal-dialog {
  max-width: 870px !important;
  top: 0;
}

.text-skyblue {
  color: var(--primary-sky);
}

.location-dropdown,
.select-type-dropdown .dropdown-toggle {
  -webkit-text-fill-color: var(--primary-color-deepdark);
  opacity: 1;
  /* required on iOS */
}

.location-dropdown .dropdown-menu {
  -webkit-transform: translate(-3px, 53px) !important;
          transform: translate(-3px, 53px) !important;
}

.btn-location-continue:hover {
  background: #808080;
}

button.close {
  display: inline;
}

.modal-content-data .modal-header {
  padding: 0 50px;
}

.postjob-data {
  width: 0px;
}

.city-search-dropdown .dropdown-menu {
  overflow-y: hidden !important;
}

.search-list-dropdown {
  overflow-x: hidden;
  max-height: 155px;
}

.search-list-dropdown .search-list-item:hover {
  background: var(--secondary-color-sky);
  transition: all ease-in-out 0.5s;
}

.search-list-dropdown .search-list-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}

.form-floating {
  position: relative;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: var(--primary-sky);
}

label:hover span::before,
label:hover span::before {
  box-shadow: 0 0 2px #ccc;
}

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 1px #a8a8a8;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  background: #f6f6f6;
}

input[type="checkbox"]:checked + span::before {
  color: var(--primary-sky);
}

input[type="checkbox"]:disabled + span {
  cursor: default;
  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 16px;
  line-height: 19px;
}

input[class="skyblue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 19.5px;
  height: 19.5px;
}

input[class="skyblue"]:checked + span::before {
  color: var(--primary-sky);
}

.postjob-block-inner {
  padding-top: 56px;
  padding-bottom: 16px;
}

.con-btn-style {
  width: 23% !important;
}

.slidebar-serch-block .dropdown button:focus {
  box-shadow: none;
}

.state-input-sel-container input {
  padding-right: 33px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.city-search-dropdown .search-list-dropdown {
  height: auto !important;
}

@media (max-width: 768px) {
  .modal-postjob-data .modal-content {
    padding: 0px 0px 48px 0px;
  }

  .modal-postjob-data .modal-body {
    padding: 0px 17px 0px;
  }

  .postjob-block-inner {
    padding-top: 31px;
    padding-bottom: 8px;
  }

  .postjob-style-block {
    display: flex !important;
    flex-wrap: nowrap;
  }

  input[class="skyblue"] + span::before {
    border: solid 1px var(--primary-sky);
    background-color: transparent;
    width: 19.5px;
    height: 19.5px;
    margin-top: 4px;
  }

  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none;
  }

  .modal-postjob-data .modal-body h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 31px;
  }

  .post-none-part {
    display: none;
  }

  .con-btn-style {
    width: 86% !important;
  }

  .close-style span {
    display: none;
  }
}


.modal-postjob-data .modal-content {
  padding: 22px 0px 52px;
  background: var(--primary-light) !important;
  border-radius: 0;
  box-shadow: 0px 6px 60px -5px rgba(117, 117, 117, 0.26);
}

.modal-postjob-data .modal-body h5 {
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  padding-bottom: 41px;
}

.modal-postjob-data .modal-body {
  padding: 0px 177px 0px;
  font-size: 18px;
  line-height: 27px;
  text-align: start;
}

.postjob-terms-condition {
  font-size: 14px !important;
  color: #162937 !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  -webkit-user-select: none;
  user-select: none;
}

.modal-postjob-data .modal-dialog {
  max-width: 870px !important;
}

.text-skyblue {
  color: var(--primary-sky);
}

.editor-main-block .tox-statusbar__text-container {
  display: none !important;
}

.editor-container-border .tox-tinymce {
  border: 2px solid var(--primary-blue);
}

.editor-container-error-border .tox-tinymce {
  border: 2px solid var(--primary-color-danger);
}

.post-jobtype-error::before {
  border-color: var(--primary-color-danger) !important;
}

.tox-notifications-container {
  display: none !important;
}

button.close {
  display: inline;
}

.modal-content-data .modal-header {
  padding: 0 50px;
}

.postjob-data {
  width: 0px;
}

label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}

.form-floating {
  position: relative;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: var(--primary-sky);
}

label:hover span::before,
label:hover span::before {
  box-shadow: 0 0 2px #ccc;
}

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 1px #a8a8a8;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  background: #f6f6f6;
}

input[type="checkbox"]:checked + span::before {
  color: var(--primary-sky);
}

input[type="checkbox"]:disabled + span {
  cursor: default;

  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 16px;
  line-height: 19px;
}

input[class="skyblue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 19.5px;
  height: 19.5px;
}

input[class="skyblue-check-box"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 17.5px;
  height: 17.5px;
}

input[class="skyblue-check-box"]:checked + span::before {
  color: var(--primary-sky);
}

input[class="skyblue"]:checked + span::before {
  color: var(--primary-sky);
}

.postjob-block-inner {
  padding-top: 21px;
  padding-bottom: 12px;
}

/* ------job-details-modal---------- */
.close-style {
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
}

.job-details-modal .modal-dialog {
  /* max-width: 1169px !important; */
  max-width: 1000px !important;
}

.job-details-modal .modal-content {
  padding: 26px 0px 26px;
  background: var(--primary-light) !important;
  border-radius: 0;
  box-shadow: 0px 0px 42px -5px rgba(196, 196, 196, 0.4);
}

.job-details-modal .modal-header {
  /* padding: 26px 115px 26px 98px; */
  padding: 16px 115px 16px 98px;
  border-bottom: none !important;
}

.job-details-modal .modal-body {
  padding: 0px 97px 0px 98px;
}

.job-details-modal .modal-title {
  padding-bottom: 23px;
}

.editor-main-block {
  padding-top: 20px;
}

.editor-main-block .tox-tinymce {
  height: 343px !important;
}

.con-btn {
  width: 30%;
}

.sign-btn {
  width: 24%;
}

@media (max-width: 1200px) {
  /* -----------job-details-modal------------- */
  .job-details-modal.modal.show .modal-dialog {
    margin: 26px;
  }
}

@media (max-width: 768px) {
  .modal-postjob-data .modal-content {
    padding: 0px 0px 48px 0px;
  }

  .modal-postjob-data .modal-body {
    padding: 0px 17px 0px;
  }

  .postjob-block-inner {
    padding-top: 31px;
    padding-bottom: 8px;
  }

  .postjob-style-block {
    display: flex !important;
    flex-wrap: nowrap;
  }

  input[class="skyblue"] + span::before {
    border: solid 1px var(--primary-sky);
    background-color: transparent;
    width: 19.5px;
    height: 19.5px;
  }

  input[class="skyblue-check-box"] + span::before {
    border: solid 1px var(--primary-sky);
    background-color: transparent;
    width: 19.5px;
    height: 19.5px;
  }

  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none;
  }

  .modal-postjob-data .modal-body h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 31px;
  }

  .post-none-part {
    display: none;
  }

  /* -----------job-details-modal------------- */
  .job-details-modal .modal-content {
    padding: 23px 0px 25px;
  }

  .job-details-modal .modal-header {
    padding: 0px 20px 0px 20px;
  }

  .job-details-modal .modal-title {
    padding-bottom: 6px;
  }

  .job-details-modal .modal-body {
    padding: 0px 20px 0px 20px;
  }

  .sign-btn {
    width: 75%;
  }

  .editor-main-block .tox-tinymce {
    height: 210px !important;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) /* -----------job-details-modal------------- */ {
  .job-details-modal .modal-header div {
    padding: 0;
  }

  input[class="skyblue"] + span::before {
    width: 26.5px;
    height: 19.5px;
  }

  .job-details-modal .close-style span {
    display: none;
  }

  .con-btn {
    width: 90%;
  }

  .job-details-modal .form-group {
    margin-bottom: 0;
  }

  .editor-main-block {
    padding-top: 5px;
  }

  input[type="checkbox"]:checked + span::before {
    /* margin-top: 5px; */
  }
}

.post-job-inner {
  margin-bottom: 12px;
}
.skip-data-style {
  margin: 4px 16px;
}
.skip-label-block {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: var(--primary-font-family);
}

.qlf-subtitle {
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 16px;
  text-align: center;
}

.qlf-input-box input {
  border-bottom-color: var(--primary-color-danger) !important;
}

.add-qlf-container {
  display: none;
}

.job-details-modal .modal-dialog {
  top: 0;
}
.review-btn {
  width: 22%;
}

.select-type-dropdown .dropdown-toggle::after {
  display: none;
}

.select-type-dropdown .dropdown-toggle {
  box-shadow: none;
}

.qlftype-lbl-col {
  display: none;
}

.qlf-down-arrow {
  position: absolute;
  top: 23px;
  right: 18px;
}

@media (max-width: 1024px) {
  .review-btn {
    width: 23%;
  }
}
@media (max-width: 768px) {
  .review-btn {
    width: 85%;
  }

  .qlftype-lbl-col {
    display: none;
  }
}

@media (max-width: 425px) {
  .qlf-subtitle {
    text-align: left;
  }

  .qlftype-lbl-col {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
  }

  .add-qlf-container {
    display: block;
    text-align: left;
  }

  .lbl-add-qlf {
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-dark);
  }
}

.calendar-datePicker {
  border: 2px solid var(--primary-sky);
  border-radius: 0;
}


.calender-container .rmdp-day,.calender-container .rmdp-week-day {
  height: 34px;
  width: 34px;
}

.calender-container {
  -webkit-transform: translate(-43.25px, 0px);
          transform: translate(-43.25px, 0px);
}

.calender-container .rmdp-arrow-container {
  width: 26px;
  height: 26px;
  align-items: center;
}

.calender-container .rmdp-arrow {
  height: 8px;
  width: 8px;
  margin-top: 0;

}
.datePicker {
  font-size: 14px;
  font-family: var(--primary-font-family);
}

.job-details-modal .modal-title {
  padding-bottom: 23px;
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
}

/* --------review and publish-model-------- */
.review-modal-block {
  font-size: var(--medium-small-font-size);
  line-height: 25px;
  font-weight: 400;
}
.review-modal-text {
  font-size: var(--medium-small-font-size);
  line-height: 18px;
  font-weight: 400;
}
.editor-cotainer {
  text-align: left;
}
.editor-cotainer textarea::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400px;
  color: #000;
}
.editor-cotainer textarea::placeholder {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400px;
  color: #000;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}
.editor-cotainer-inner {
  border: 2px solid var(--primary-blue);
  height: 386px;
  padding: 17px 18px 13px;
  overflow-y: scroll;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  overflow-x: hidden;
  word-wrap: break-word;
}

.editor-cotainer-inner p {
  font-size: var(--medium-small-font-size);
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  margin-bottom: 0px;
  line-height: 25px;
}

.editor-cotainer-inner ul {
  font-size: 16px;
  line-height: 24px;
}

.qlf-detail-container {
  font-size: 16px;
}

.jobreview-qlf-border {
  border-right: 2px solid var(--primary-blue);
  width: 80%;
}
.jobreview-qlf-container {
  border: 2px solid var(--primary-sky) !important;
}

.expire-dropdown-arrow {
  position: absolute;
  bottom: 14px;
  right: 26px;
  cursor: pointer;
}

@media (max-width: 991px) and (min-width:868px) {
  .expire-dropdown-arrow {
     right: 44px;
  }
}

@media (max-width: 768px) and (min-width:755px) {
  .expire-dropdown-arrow {
     right: 36px;
  }
}
@media (max-width: 768px) {
  /* --------review and publish-model-------- */
  .review-modal-block {
    font-size: 14px;
    line-height: 25px;
  }
  .review-modal-text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
  .job-details-modal .modal-title {
    padding-bottom: 22px;
    font-size: 22px;
    line-height: 24px;
    font-weight: 500;
  }

  .editor-cotainer-inner,
  .editor-cotainer-inner p,
  .editor-cotainer-inner ul {
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }
}

@media (max-width: 425px) {
  .jobreview-qlf-border {
    width: 75%;
  }
}

/* --------job Publish modal-------- */
.registration-modal .modal-title {
  padding-bottom: 50px;
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
}
.review-modal-block {
  font-size: var(--medium-font-size);
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0.3px;
  text-align: center;
}
.btn-style-block {
  width: 50%;
}

@media (max-width: 768px) {
  .review-modal-block {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.3px;
  }
  .btn-style-block {
    width: 90%;
  }
  .modal-footer {
    padding: 0;
  }
}

.shimmer {
  color: var(--secondary-color-skin);
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  -webkit-animation: shimmer 2.5s infinite;
          animation: shimmer 2.5s infinite;
  font-size: 50px;
}

@-webkit-keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.navbar.navbar-expand-md.navbar-light {
  box-shadow: 0px 0px 60px rgba(172, 172, 172, 0.25);
  background: var(--primary-light);
  display: flex;
  width: 100%;
  margin: 0px;
  padding-top: 10px;
}

.jobpost-outer-main-container {
  background-color: var(--primary-light);
}

.post-job-section {
  font-weight: bold;
  font-size: 40px;
  line-height: 72px;
}
.post-job-section-inner {
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 25px;
}

.employers-button-css {
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 500;
  border: 2px solid #0351C7;
  background-color: transparent;
  color: #000;
}

.emp-post-job-btn,
.emp-post-job-btn:focus {
  padding: 10px 58px !important;
  outline: none;
  border-color: var(--primary-sky);
}

.btn-accordion-title {
  padding-left: 8px;
}


.top-and-bottom,
.left-and-right,
.corner-border,
.bottom-corner {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
}

.btn-accordion-title:focus {
  box-shadow: none;
}
.accordion-divider {
  margin-bottom: 200px;
}
.accordion-divider-2 {
  margin-bottom: 50px;
}
.post-img {
  background-image: linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214),
    linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214), linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-size: 20px 0%, 0% 20px, 20px 0%, 65% 20px, calc(100% - 8px) calc(100% - 8px);
  background-position: right bottom, right bottom, right top, right top, right top, 10px 10px;
}

.module1 {
  background-image: linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214),
    linear-gradient(#81b214, #81b214), linear-gradient(#81b214, #81b214), linear-gradient(#fff, #fff);
  background-repeat: no-repeat;
  background-size: 20px 100%, 54% 20px, 20px 0%, 54% 20px, calc(100% - 8px) calc(100% - 8px);

  background-position: right bottom, right bottom, right top, right top, right top, 10px 10px;
}
.module1.img-thumbnail {
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0;
  max-width: 100%;
  height: auto;
  padding-left: 0;

  border: 0 !important;
}
.post-img.img-thumbnail {
  padding: 1.25rem;
  background-color: #fff;
  border-radius: 0;
  max-width: 100%;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  border: 0 !important;
}

.postjob-block {
  padding: 100px 0px 70px 0px;
}
.mobile-view-data {
  display: none;
}
.employers-mobile-view {
  display: none;
}
.sky-blue {
  color: var(--primary-sky);
}
.lorem-ipsum-data {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}
.lorem-ipsum-block {
  padding: 70px 0px;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: unset;
  outline: none;
}

.btn.focus,
.btn:focus {
  box-shadow: unset;
  outline: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.home-main-container {
  background-image: url(/static/media/header_large_back.93eadca9.png);
  background-repeat: no-repeat;
  background-size: 100% 60%;
}
.work-second-section {
  padding: 135px 0px 57px 0px;
}
.meeting-section {
  display: flex;
}
.secound-section .consectetus {
  margin: auto;
}

.secondsection-left-title {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 45px;
  color: var(--primary-light);
}
.scondsection-left-desc {
  margin-top: 82px;
  margin-bottom: 26px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary-light);
}
.align-items-center .lorem-img {
  text-align: right;
}

/* .work-third-main-container {
  background-image: url("../../Assets/Images/footer_background_section.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100% 23%;
  padding-bottom: 44px;
  margin-bottom: -32px;
} */

.work-desc-col {
  margin-bottom: 10px;
}

.work-desc-main-container {
  max-width: 561px;
  width: auto;
  height: 516px;
  background: var(--primary-light);
  box-shadow: 0px 0px 42px -5px rgb(196 196 196 / 40%);
  border-radius: 14px;
  padding: 60px;
  margin: auto 20px;
}
.work-desc-main-container.first, .work-desc-main-container.third {
  margin-left: 0 !important;
}

.work-desc-main-container.second, .work-desc-main-container.forth {
  margin-right: 0 !important;
}

.work-card-title {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: var(--primary-dark);
  margin: 21px auto 17px auto;
}

.work-card-detail {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: var(--primary-grey);
}
.work-img {
  width: 72px;
  height: 72px;
}
.right-work-desc-container {
  margin-top: 60px;
}

@media (max-width: 1440px) {
  .thard-section {
    padding-bottom: 85px;
  }

  .home-main-container {
    background-size: 100% 62%;
  }
}

@media (max-width: 1200px) {

  .work-desc-main-container {
    padding: 32px;
  }

  .work-card-title {
    font-size: 22px;
    line-height: 28px;
  }
  
  .work-card-detail {
    font-size: 15px;
    line-height: 25px;
  }
}

@media (max-width: 1024px) {
  .secound-section {
    padding: 120px 0px 38px 0px;
  }
}
@media (max-width: 991px) {
  .thard-section {
    padding-bottom: 0;
  }
  .support-description {
    margin-top: 0 !important;
  }
  .right-work-desc-container {
    margin-bottom: 60px;
  }
  .work-header-img {
    display: flex !important; 
    justify-content: center;
    margin: 20px 0;
    height: 50vh; /* half the height of the viewport */
    width: auto; /* maintain aspect ratio */
    object-fit: cover; /* ensure the image covers the whole element */
}

  .work-desc-main-container {
    max-width: 100%;
    margin: 0 0 25px 0 !important;
  }

  .secound-section {
    padding: 66px 0px 38px 0px;
  }
}

@media (max-width: 768px) {

  .job-desc-left-img {
    display: none;
  }
  .job-desc-center-img {
    display: block;
    text-align: center;
  }

  .job-desc-title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
  }

  .job-desc-details {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
  }
  .desc-details {
    padding: 51px 28px;
  }

  .fourth-desc-details {
    align-items: center;
    padding: 54px 28px 80px 28px;
  }

  .fourth-job-center-img {
    margin: 30px 0px;
  }
  .thard-section {
    background-color: none;
  }

  .secound-section {
    padding: 66px 0px 38px 0px;
  }

  .jobseek-desc-title {
    text-align: center;
  }

  .scondsection-left-desc {
    margin-top: 22px;
  }
}

@media screen and (max-width: 425px) {
  .rounded-btn {
    padding: 9px 21px !important;
    height: 41px;
  }

  .rounded-btn1 {
    padding: 9px 21px !important;
    height: 41px;
  }
  .secound-section .consectetus {
    margin-top: 27px;
  }

  .third-section-container {
    margin-top: 77px;
  }
}

@media (max-width: 576px) {
  .work-header-img {
    height: auto; /* make height proportional to width */
    width: 100%; /* image width equals to container width */
  }
  .secound-section {
    padding: 25px 0px 38px 0px;
  }
  .scondsection-left-desc {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .employers-main-button {
    font-size: 14px;
    margin-left: 10px;
  }
}
@media (max-width: 425px) {

  .secound-section {
    padding: 0;
  }
  .secondsection-left-title {
    line-height: 32px;
  }
}

@media (max-width: 1199px) {
  .emp-post-job-btn {
    margin-left: 12px;
  }
}

@media (max-width: 1399.98px) {
  .post-job-section {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
  }
}

@media (max-width: 1199.98px) {
  .post-job-section {
    font-weight: bold;
    font-size: 34px;
    line-height: 50px;
  }
  .postjob-block {
    padding: 100px 0px 70px 0px;
  }
  div#navbarNavAltMarkup span {
    font-size: 14px;
    padding: 10px 12px !important;
  }

  @media (max-width: 991.98px) {
    .desktop-view {
      display: none;
    }
    .post-job-desktop-view {
      display: none;
    }
    div#navbarNavAltMarkup span {
      font-size: 14px;
      padding: 10px 12px !important;
    }
    .employers-button-css {
      font-size: 14px;
      line-height: 20px;
      padding: 6px 14px !important;
      font-weight: 500;
    }
    .lorem-ipsum-block {
      padding: 30px 0px;
    }
    .post-job-section-inner {
      padding: 0px 33px;
      font-size: 16px;
      line-height: 24px;
    }
    .postjob-block {
      padding: 20px 0px 34px 0px;
    }
    .emp-btn {
      padding-left: 18px;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
    .post-job-col {
      margin: unset !important;
      display: flex;
      align-items: center;
    }
    .post-job-section-inner {
      margin-top: 40px;
    }
    .accordion-divider {
      margin-bottom: 53px;
    }
  }
}
@media (max-width: 992px) {
  .mobile-view-data {
    display: inline;
  }
}
@media (min-width: 768px) {
  .employers-main-button {
    display: none;
  }
}
@media (max-width: 767px) {
  .secondsection-left-title {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
  }
  .employers-main-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 145px;
    max-height: 45px;
    border-radius: 7px;
    color: #fff;
    border: 1px solid #FFF;
    border: 1px solid var(--top-bar-bg-color, #FFF);
    background-color: #0351C7;
  }
  .employers-mobile-view {
    display: inline;
  }
  .header-light-bulk-image {
    display: none;
  }
}
.reset-password-back {
  text-align: left;
  cursor: pointer;
}

.reset-pass-body {
  padding: 0px 98px 56px 98px;
}

.btn-reset-pass {
  margin-top: 66px;
}

@media screen and (max-width: 425px) {
  .reset-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .reset-pass-body .credentials-detalis {
    padding-bottom: 30px;
  }

  .btn-reset-pass {
    margin-top: 34px;
  }

  .reset-password-back {
    margin-top: 10px;
    margin-right: 10px;
  }
}

.email-verify-title {
  font-weight: normal !important;
  margin-bottom: 61px !important;
  font-size: var(--medium-font-size);
}

@media screen and (max-width: 425px) {
  .btn-verified-close {
    max-width: 148px !important;
    height: 44px !important;
  }
}

:root {
  --primary-color: #3ba6fb;
  --primary-light: #ffffff;
  --primary-color-dark-contrast: #60638a;
  --primary-dark: #3a3a3a;
  --primary-blue: #3fa4cc;
  --primary-deep-dark: #2b2e39;
  --primary-Kimberly: #60638a;
  --primary-sky: #3fa4cc;
  --primary-grey: #7e7e7e;
  --primary-light-green: #81b214;
  --primary-color-medium-light: #f1f0fd;
  --primary-color-danger: #c60000;
  --primary-color-deepdark: #000000;
  --secondary-color-light: #ceced9;
  --secondary-color-light-contrast: #6396a6;
  --secondary-color-dark: #17176d;
  --secondary-color-light-tint: #d7d7e6;
  --secondary-color-skin: #c4c4c4;
  --secondary-color-light-gray: #fafafa;
  --secondary-color-dark-gray: #f0f0f0;
  --secondary-color-sky: #66c1e5;
  --dark-grayish: #2b2e39;
  --very-dark-grayish: #282e39;
  --light-silver: #f9f9f9;
  --user-managemnt-margin-top: 120px;

  --forms-text-color: #808080;
  --forms-padding: 30px;
  --forms-button-width: 45%;

  --primary-font-family: "Poppins";
  --secondary-font-family: "Roboto";
  --primary-generic-font-family: "Roboto";
  --large-font-size: 25px;
  --medium-large-font-size: 20px;
  --medium-font-size: 18px;
  --medium-small-font-size: 16px;
  --small-font-size: 14px;
  --extra-small-font-size: 12px;

  --normal-font-weight: 400;
  --medium-font-weight: 500;
  --semi-bold-font-weight: 600;
  --bold-font-weight: 700;
}

.popupmodal {
  width: 100%;
  padding: 20px;
}

.usermanagementcontainer{
    margin: auto;
}
.registrationscreen{
    /* background-color: #817f7f; */
    margin-top: var(--user-managemnt-margin-top);
}
.loginscreen{
    /* background-color: #3d3c3c; */
    margin-top: var(--user-managemnt-margin-top);
    margin-bottom: var(--user-managemnt-margin-top);
}
.formcontainer{
    width: 40%;
    margin: auto;
    background-color: aliceblue;
    border: 1px solid var(--primary-color);
    border-radius: .3rem;
    outline: 0;
    color: var(--forms-text-color);
    padding: var(--forms-padding);
}
.inputfield{
    width: 70% !important;
    margin: auto;
    margin-top: 20px;
}
.forgotpassword{
    color: var(--primary-color);
    cursor: pointer;
}
.rememberme{
    color: gray;
    margin-top: 20px;
}
.formbuttonsmall{
    width: var(--forms-button-width);
    border: 1px solid gray !important;

}
.buttongroupsmall{
    width: 70%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttongroupbig{
    width: 90%;
    margin: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.email-confirmation{
    display: flex;
    flex-direction: column;
    margin: auto;
}
.button-in-link{
    width: 100%;
    border: none;
    border-radius: 0px;
}

.doc-view-modal {
  text-align: left !important;
  padding: 20px 98px 60px 98px !important;
}

.desc-view-area {
  width: 100%;
  height: 253px;
  min-height: 253px;
  border: 2px solid #3a3a3a;
  margin-bottom: 64px;
  margin-top: 28px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 15px;
  background-color: var(--primary-light);
}

.lbl-doc-viewer {
  margin-top: 6px;
}

.desc-up-label {
  font-weight: 600;
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

@media screen and (max-width: 425px) {
  .desc-view-area {
    min-height: 269px;
    margin-bottom: 28px;
    margin-top: 18px;
    height: 269px;
  }

  .doc-view-modal {
    padding: 30px 98px 30px 98px !important;
  }

  .desc-up-label {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  /* ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  } */

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #3a3a3a;

    border-radius: 4px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.modal-body .back-join {
  font-weight: 600;
  font-style: normal;
  font-size: 29px;
  line-height: 25px;
  color: #3a3a3a;
}
.modal-body .switch-count {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-sky);
  cursor: pointer;
}
.modal-body .switch-count span {
  font-weight: 700;
}
.modal-open {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.view {
  padding-bottom: 10px;
}
.view-position {
  text-align: initial;
  margin: auto;
  width: 77%;
  padding: 59px 0 42px 0px;
}
.btn-joe-con {
  width: auto;
}
@media screen and (max-width: 425px) and (min-width: 320px) {
  .view-position {
    width: auto;
  }

  .job-modal-body {
    padding: 22px 21px 50px 21px;
    font-family: var(--secondary-font-family);
  }

  .job-modal-body .desc-title {
    font-size: 18px !important;
  }
}

.first-choose {
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 41px 0;
  color: var(--primary-sky);
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  font-size: 14px;
}

.public-profile-modal-class .btn-job-select {
  width: 208px;
  height: 50px;
}

.btn-job-select {
  padding: 5px 44px;
  margin: 5px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}

/* .btn-job-disable {
  background-color: #cdcdcd;
  border: 1px solid #cdcdcd !important;
} */

/* .btn-job-enable {
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
} */

.apply-terms {
  color: #3a3a3a;
  font-size: 14px;
  display: flex;
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 29px 0px 0px;
  align-items: center;
}

.application-type-lbl {
  padding-left: 14px;
}

.public-profile-modal-class input[class="blue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 24px;
  height: 24px;
}

.public-profile-modal-class input[class="blue"] {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.public-profile-modal-class .modal-dialog {
  max-width: 870px !important;
}
.public-profile-modal-class .credentials-detalis {
  padding-bottom: 50px;
}
.public-profile-modal-class .btn-pwa-signUp {
  width: 208px;
  height: 50px;
}

@media (max-width: 767px) {
  .public-profile-modal-class .credentials-detalis {
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .view-position {
    width: auto;
  }
  .modal-body .back-join {
    font-size: 14px;
    font-family: var(--secondary-font-family);
  }
  /* .first-choose {
    width: auto;
  } */
  .app-modal-back {
    padding-left: 19px !important;
  }

  .application-type-lbl {
    padding-left: 5px;
  }

  .public-profile-modal-class .btn-pwa-signUp {
    width: 80%;
    max-width: 208px;
  }

  .choose-option-body {
    padding: 22px 10px 50px 10px;
  }

  .choose-option-title-container {
    text-align: center;
  }

  .choose-option-title-container .back-join {
    font-size: 18px;
  }

  .apply-terms {
    color: #3a3a3a;
    font-size: 14px;
    display: flex;
    text-align: initial;
    margin: auto;
    width: 100%;
    padding: 29px 0px 0px 13px;
    font-family: var(--secondary-font-family);
    align-items: flex-start;
  }

  .apply-terms .option-checkbox {
    padding-top: 5px;
  }

  .modal-header {
    padding: 0px 10px;
  }

}

.doc-preview-loader {
  position: absolute;
  width: 0px;
  z-index: 2000000000;
  left: 55%;
  top: 50%;
}

@media screen and (max-width: 425px) {
  .doc-preview-loader {
    left: 50%;
    top: 48%;
  }
}

.doc-view-modal {
  text-align: left !important;
  padding: 20px 98px 60px 98px !important;
}

.doc-view-area {
  width: 100%;
  height: auto;
  min-height: 310px;
  border: 2px solid #3a3a3a;
  margin-bottom: 64px;
  margin-top: 28px;
}

.lbl-doc-viewer {
  margin-top: 6px;
}

.upd-docprev-loader {
  position: absolute;
  width: 0px;
  z-index: 2000000000;
  left: 50%;
  top: 43%;
}

@media screen and (max-width: 425px) {
  .doc-view-area {
    min-height: 270px;
    margin-bottom: 28px;
    margin-top: 18px;
  }

  .doc-view-modal {
    padding: 30px 98px 30px 98px !important;
  }

  .upd-docprev-loader {
    left: 50%;
    top: 46%;
  }
}

.header-box {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
}
.exprience-part {
  padding: 41px 0px;
}
.exprience-work {
  display: flex;
  padding: 5px;
}
.exprience-work .exp-year {
  margin-left: auto;
  width: 34%;
}
.exprience-work .exp-work {
  margin-left: 19px;
  width: 14%;
}
.exprience-work .exp-delete {
  padding: 10px;
  margin-right: auto;
  padding-left: 24px;
}
.expadd-data i {
  font-size: 20px;
  padding: 13px;
}
.expadd-data {
  font-size: 14px;
  color: var(--primary-sky);
  width: 47%;
  margin-right: auto;
  height: 118px;
}

@media screen and (min-width: 768px) {
  .add-work-exp-mobile {
    display: none !important;
  }
}
/* step-2 */
.exp-submitstyle {
  margin-left: auto;
  width: 60%;
  margin-right: auto;
}
/* step-2 */
.expemail-btn {
  padding: 10px;
}
/* step-2 */
.workexp-inputbox .workexp-month {
  width: 45%;
  margin-right: auto;
  padding: 8px 0px;
}

.header-box {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.25);
}
.exprience-part {
  padding: 41px 0px;
}
.exprience-work {
  display: flex;
  padding: 5px;
}
.exprience-work .exp-year {
  margin-left: auto;
  width: 340px;
}
.exprience-work .exp-work {
  margin-left: 19px;
  width: 118px;
}
.exprience-work .exp-delete {
  padding: 10px;
  margin-right: auto;
  padding-left: 24px;
  cursor: pointer;
}
.expadd-data i {
  font-size: 20px;
  padding: 13px;
}
.expadd-data {
  font-size: 14px;
  color: var(--primary-sky);
  width: 69%;
  margin-right: auto;
  height: 54px;
  margin-left: 7px;
  cursor: pointer;
  text-align: left;
}

.exp-table {
  display: none;
}

@media screen and (max-width: 720px) {
  .exp-table {
    display: block;
  }

  .exprience-part {
    display: none;
  }
}

.skip-checkbox {
  display: flex;
  justify-content: center;
}

.skip-checkbox-style {
  padding: 10px;
  display: flex;
  align-items: center;
  padding-left: 43px;
}

.from-radio-input {
  display: flex;
  text-align: initial;
  margin: auto;
  width: 88%;
  padding: 15px 0px 0px;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 19.5px;
  height: 19.5px;
  border-radius: 50%;
  outline: none;
  border: 2px solid var(--primary-sky);
  margin: 0px 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary-sky);
}

input[type="radio"]:checked {
  border-color: var(--primary-sky);
}

.from-up-label {
  font-weight: var(--medium-font-weight);
  font-style: normal;
  font-size: 25px;
  line-height: 35px;
}

.tstyle th {
  padding: 0.1rem 0.1rem;
  width: 100%;
  padding-left: 8px;
}

.tstyle tr:hover {
  cursor: pointer;
  /* background-color: #ede493; */
}

.selected-tr {
  background-color: #ede493;
}

.tstyle td {
  padding: 0.1rem 0.1rem;
  width: 100%;
  padding-left: 8px;
  color: var(--primary-sky);
}

.tstyle .table-hedar {
  color: white !important;
  background-color: var(--primary-sky) !important;
}

.tstyle .t-whitespace {
  overflow: hidden;
  height: 80px;
  white-space: nowrap;
}

.resume-alldata {
  text-align: initial;
  margin: auto;
  width: 100%;
  padding: 41px 0;
  color: #3a3a3a;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  font-style: 14px;
}

.resume-alldata .upload-table {
  margin-left: auto;
  width: 86%;
  margin-top: 14px;
  border: 2px solid #3a3a3a;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}

.job-modal-step {
  justify-content: space-between;
  align-items: center;
}

.step-style {
  color: var(--primary-sky);
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-style: normal;
}

.cancel-job {
  font-family: var(var(--primary-font-family));
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-right: 9px;
  color: #3a3a3a;
}

.back-icon {
  margin-bottom: 3px;
}

.cancel-icon {
  margin-bottom: 2px;
}

/* .t-textarea {
  margin-left: auto;
  width: 86%;
} */

.upload-new-container {
  margin-left: auto;
  width: 86%;
}

.t-textarea .pink {
  font-size: 14px;
  color: var(--primary-sky);
}

.t-textarea .pink .b-style {
  font-weight: bold;
}

.t-textarea .drop-mobile {
  display: none;
}

.drop-desktop {
  margin: 7px 0px;
}

.drag-area-input {
  width: 100%;
  height: auto;
  min-height: 248px;
  max-height: 248px;
  border: 2px solid #3a3a3a;
}

.my-fromresume {
  display: flex;
  padding-top: 21px;
  justify-content: space-between;
}

.my-fromresume .my-placeholder {
  width: 37%;
  font-size: 14px;
  color: var(--primary-sky);
  font-size: 14px;
  font-weight: 400px;
  width: 36%;
}

.collapse-radio {
  cursor: pointer;
}

.form-floating-container {
  width: 320px;
}

.btn-clear {
  font-size: 16px;
  color: var(--primary-sky);
  text-align: right;
  margin-top: 12px;
  cursor: pointer;
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .from-up-label {
    font-size: 18px;
    line-height: 20px;
    font-family: var(--secondary-font-family);
  }

  .first-choose {
    padding: 27px 0;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
  .resume-alldata {
    padding: 27px 0px;
    width: auto;
  }

  .t-textarea .pink .b-pdf {
    display: none;
  }

  .document-modal-body {
    padding: 22px 20px 50px 20px;
  }

  .skip-checkbox {
    padding: 0px 27px;
  }

  .resume-alldata {
    font-family: var(--secondary-font-family);
    padding: 27px 10px;
  }

  .resume-alldata .upload-table {
    font-family: var(--secondary-font-family);
    padding: 27px 10px;
  }

  .t-textarea {
    width: 100%;
  }

  .t-textarea .drop-desktop {
    display: none;
  }

  .drop-mobile {
    margin: 7px 0px;
  }

  .t-textarea .drop-mobile {
    display: block;
  }

  .from-radio-input {
    width: 100%;
  }

  .skip-checkbox-style {
    padding-left: 0px;
  }

  .my-fromresume .my-placeholder {
    width: 96%;
  }

  .label-back {
    font-family: var(--secondary-font-family);
  }

  .cancel-job {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 320px) {
  .skip-checkbox {
    padding: 0px 12px;
  }

  .document-modal-body {
    padding: 22px 0px 50px 0px;
  }

  .skip-checkbox-style {
    padding-left: 0px;
  }

  .label-back {
    font-family: var(--secondary-font-family);
  }

  .cancel-job {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 1463px) and (min-width: 720px) {
  .t-textarea .pink img {
    display: none;
  }
}

.error-msg-apply-job p {
  font-size: 14px;
  text-align: center;
  color: var(--primary-color-danger);
  font-weight: 600;
  font-family: var(--primary-font-family);
  font-style: normal;
  line-height: 20px;
}

.submit-modal {
  padding: 41px 0px;
}

.submit-modal .exp-submitstyle {
  width: 75%;
}

@media screen and (max-width: 425px) {
  .error-msg-apply-job p {
    font-family: var(--secondary-font-family);
  }
}
@media screen and (max-width: 425px) and (min-width: 320px) {
  .exp-submitstyle {
    width: auto;
  }

  .info-detail-body {
    padding: 22px 20px 50px 20px !important;
  }

  .submit-modal .exp-submitstyle {
    width: 100%;
  }
}

.role-select-heading {
    color: #162937;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.loaderparent{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999;
    bottom: 0%;
}
.modal-data-block {
  border: 2px solid var(--primary-sky);
}

.modal-data-block td {
  padding: 13px 14px 10px;
  font-size: var(--medium-small-font-size);
  line-height: 18px;
  color: #000;
  font-family: var(--primary-font-family);
  max-width: 175px !important;
  word-wrap: unset;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.modal-data-block td:hover {
  text-overflow: unset;
  white-space: initial;
  overflow: unset;
  word-break: break-word;
}

.view-jobqlf-td {
  /* width: 75%; */
  border-right: 2px solid var(--primary-blue);
}

.modal-table-inner {
  margin: 5px 0px 12px;
}

.btn-resume .btn-outline-info {
  color: var(--primary-sky) !important;
  border-color: var(--primary-sky) !important;
}

.btn-resume .btn-outline-info:hover {
  background-color: transparent;
}

.main-btn-style {
  width: 168px;
}

.quali_label {
  margin-bottom: 0;
}

.iframe-section {
  margin-bottom: 29px;
}
.job_app_iframe {
  width: 100%;
  height: auto;
  border: 2px solid var(--primary-blue);
}

.job_app_iframe,
.iframe-section-block div iframe {
  min-height: 496px;
  max-height: 500px;
}

.candidate-resume-container {
  min-height: 352px;
  max-height: 500px;
  border: 2px solid var(--primary-blue);
}

.doc-resume-prev-loader {
  position: absolute;
  width: 0px;
  z-index: 2000000000;
  left: 50%;
  top: 63%;
}

.text-copy-icon {
  width: 15px;
  cursor: pointer;
  display: flex;
}
.text-link-icon {
  width: 15px;
  margin-left: 10px;
  cursor: pointer;
}

.copy-popover {
  top: unset;
}

.copy-popover .popover-body {
  background-color: #4b944b;
  color: #ffffff;
}

.copy-text-button {
  padding: 0;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

@media (max-width: 991.98px) {
  .border-style {
    border: 1px solid var(--primary-sky);
  }

  .modal-data-block td {
    padding: 6px 10px 4px;
    font-size: 14px;
    line-height: 18px;
    max-width: 138px !important;
  }

  .btn-cover {
    margin-left: 0px;
  }

  .main-btn-style {
    width: 148px;
  }

  .job_app_iframe,
  .iframe-section-block iframe {
    min-height: 456px;
    max-height: 481px;
  }

  .candidate-resume-container,
  .candidate-resume-container .iframe-section-block iframe {
    min-height: 270px;
    max-height: 270px;
  }
  .job-details-container td {
    font-size: 14px !important;
  }
}

.candidate-email {
  word-break: break-all;
}

@media (max-width: 660px) {
  .review-modal-block div {
    color: #000;
  }
  .resp-text-spacing {
    white-space: unset !important;
  }
  .modal-data-block td {
    white-space: unset;
  }
}

@media (max-width: 425px) {
  .modal-data-block td {
    font-family: var(--secondary-font-family);
  }
}

@media (max-width: 415px) {
  .main-btn-style {
    margin-right: 0;
  }

  .contact-btn {
    font-size: 13px;
    line-height: 17px;
  }
}

@media (max-width: 400px) {
  .main-btn-style {
    max-width: 105px;
    margin-right: 0;
  }
}

.job-view-container .editor-cotainer-inner {
  color: var(--primary-color-deepdark);
}

.job-details-container table {
  border: 2px solid #3FA4CC;
  border-collapse: collapse;
}

.job-details-container td {
  padding: 8px;
  border: 2px solid #3FA4CC;
  border-collapse: collapse;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}
.remove-confirmation-body {
  padding: 0px 61px 56px 61px;
}

.remove-doc-modal .modal-dialog {
  max-width: 702px !important;
}

.remove-conf-btn-container {
  display: flex;
  justify-content: space-between;
  margin: auto 51px;
}

.remove-conf-btn-outline {
  border-color: var(--primary-blue);
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
  outline: none;
}

.remove-btn-width {
  width: 208px;
}

.remove-conf-title {
  font-family: var(--primary-font-family);
  color: var(--primary-dark);
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
}

.remove-conf-msg {
  font-family: var(--primary-font-family);
  color: var(--primary-color-deepdark);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  margin: 30px auto;
}

@media (max-width: 426px) {
  .remove-confirmation-body {
    padding: 42px 24px 53px 24px;
  }

  .remove-btn-width {
    width: 148px;
    height: 41px;
  }

  .remove-conf-btn-container {
    margin: auto 5px;
  }

  .remove-conf-title {
    font-family: var(--secondary-font-family);
    font-size: 22px;
    line-height: 24px;
  }

  .remove-conf-msg {
    font-family: var(--secondary-font-family);
    font-size: 14px;
    line-height: 25px;
    margin: 24px auto;
    text-align: left;
  }
}

.support-section-nojob {
  background-color: #E0ECFF;
}

.support-inner-nojob-container {
  padding-bottom: 69px;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.jobListLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  position: fixed;
  left: 50%;
  top: 10%;
  bottom: 0;
  right: 50%;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: var(--primary-sky);
}

label:hover span::before,
label:hover span::before {
  box-shadow: 0 0 2px #ccc;
}

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 2px var(--primary-blue);
  background-color: transparent;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
}

input[type="checkbox"]:checked + span::before {
  color: var(--primary-sky);
}

input[type="checkbox"]:disabled + span {
  cursor: default;

  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 16px;
  line-height: 19px;
}

input[class="skyblue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 19.5px;
  height: 19.5px;
}

input[class="skyblue"]:checked + span::before {
  color: var(--primary-sky);
}

.job-terms {
  font-size: 16px;
  line-height: 20px;
  display: inline-flex;
  min-height: 100%;
  align-items: center;
  font-family: var(--primary-font-family);
}

.job-terms-main-section {
  font-size: 36px;
  line-height: 54px;
  font-weight: 500;
}

.main-section-part-two {
  padding: 44px 0;
}

.currently-section {
  font-size: 18px;
  line-height: 96px;
  font-weight: 400;
}

.nojob-main-section {
  background-image: url(/static/media/background_vector.ab7e37dc.png);
  background-repeat: no-repeat;
  background-position: center;
}

.nojob-Employers {
  background-image: url(/static/media/employers.556182a4.png);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 43%;
}

.no-jobs-label-style {
  padding: 0px 18px;
}

/* --------table------ */
/* .job-table .dropdown button.dropdown-toggle::after {
  display: none;
} */

.job-table .dropdown-menu a:hover {
  border-left: 7px solid #f7f7f7;
  background-color: #f7f7f7;
}

.second-job-table .dropdown-menu a:hover {
  border-left: 7px solid #f7f7f7;
  background-color: #f7f7f7;
}

.job-table .dropdown-menu a {
  border-left: 7px solid var(--primary-light);
  font-size: 16px;
  line-height: 28px;
  color: #000;
  font-weight: 400;
  padding: 7px 20px !important;
}

.second-job-table .dropdown-menu a {
  border-left: 7px solid var(--primary-light);
  font-size: 16px;
  line-height: 28px;
  color: #000;
  font-weight: 400;
  padding: 4px 20px !important;
  margin: 3px auto;
}

.job-main-table-section .nav-mega {
  -webkit-filter: drop-shadow(0px 4px 40px rgba(63, 164, 204, 0.14));
          filter: drop-shadow(0px 4px 40px rgba(63, 164, 204, 0.14));
}

.main-table {
  width: 100%;
  table-layout: fixed; /* this is to enforce the table to display ellipsis on overflowing cells and not adjust column width based on content */
}

.main-table tbody td {
  padding: 20px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  font-family: var(--primary-font-family);
  border-top: 2px solid #f7f7f7;
  vertical-align: middle;
}

.main-table tbody h5 {
  font-size: 16px;
  font-weight: 400;
}

.main-table tbody button {
  padding: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: var(--primary-sky);
  font-family: var(--primary-font-family);
  margin-right: 0;
  background-color: transparent !important;
  border-color: transparent;
}

.main-table tbody td button::after {
  display: none !important;
}

.main-table tbody td button:focus {
  box-shadow: none !important;
}

/* .main-table tbody td .dropdown button:hover {
  background-color: transparent;
  border-color    : transparent;
  transition      : all ease-in-out 500ms;
}

.main-table tbody td .dropdown button:active {
  background-color: transparent !important;
  border-color    : transparent !important;
  color           : var(--primary-sky) !important;
}
*/

.down-arrow-table {
  position: absolute;
  top: 3px;
  padding-left: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  /* border-color: 0.15s ease-in-out; */
}

.main-table .dropdown-menu.show {
  -webkit-filter: drop-shadow(0px 4px 40px rgba(63, 164, 204, 0.14));
          filter: drop-shadow(0px 4px 40px rgba(63, 164, 204, 0.14));
  border: none;
  box-shadow: 0px 4px 28px rgb(182 182 182 / 25%);
}

.job-table .dropdown-menu.show {
  -webkit-transform: translate(-77px, 24px) !important;
          transform: translate(-77px, 24px) !important;
  box-shadow: 0px 4px 28px rgba(182, 182, 182, 0.25);
  border: none;
}

.second-job-table .dropdown-menu.show {
  box-shadow: 0px 4px 28px rgba(182, 182, 182, 0.25);
  border: none;
}

/* -------------------- */
.job-main-table-section {
  padding: 24px 0px;
}

.megamenu-section .Collapse .btn-primary:hover,
.megamenu-section .Collapse .btn-primary:active,
.megamenu-section .Collapse .btn-primary:focus {
  border: none;
  color: #3fa4cc;
}

.megamenu-section .dropdown-menu a:hover {
  border-left: none;
  background: var(--primary-light);
}

.megamenu-section .dropdown-menu a {
  border-left: none;
  padding: 0;
}

.main-table tr:hover {
  background: rgba(151, 151, 151, 0.08);
  cursor: pointer;
}

.megamenu-section .dropdown-menu tr:hover {
  background: rgba(151, 151, 151, 0.08);
}

/* .Collapse-main-block tr:hover {
  background: rgba(151, 151, 151, 0.08) !important;
} */
.table-hover-style:hover {
  background: #f7f7f7 !important;
}

.megamenu-section .dropdown-menu {
  padding: 0;
  float: initial;
}

.text-skyblue {
  color: var(--primary-sky);
}

.text-red {
  color: #f81313;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.text-green {
  color: #0e9a02;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
}

.job-inner-block {
  padding: 0px 60px;
}

.checkbox-style {
  width: 200px;
}

.checkbox-style-data {
  width: 170px;
}

/* -------Collapse-------- */
.Collapse-section .card-header {
  padding: 0;
  border: none;
  background-color: transparent;
}

.Collapse-section .card {
  border: none;
  background-color: transparent;
  background-color: transparent;
}

.Collapse-section button {
  margin-right: 0;
  text-decoration: none !important;
}

.Collapse-section .btn:focus {
  box-shadow: none;
}

.Collapse-section .btn {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  font-family: var(--primary-font-family);
  color: var(--primary-sky);
  white-space: nowrap;
}

/* .Collapse-section-block button.job-first-btn.collapsed:before {
  content: "\f104" !important;
} */

/* .Collapse-section-block button.btn.btn-link:before {
  content: "\f105" !important;
  position: absolute;
  top: 0;
  right: 7px;
} */

.Collapse-section button.btn.btn-link.collapsed:before {
  font-family: "FontAwesome";
  content: "\f107";
  float: right;
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
}

.Collapse-section button.btn.btn-link:before {
  font-family: "FontAwesome";
  content: "\f106";
  float: right;
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
}

tr.Collapse-main-block:hover {
  background: transparent;
}

.application-pagination {
  display: flex;
  justify-content: end;
}

.application-prev {
  margin-right: 16px;
}

.apl-collapse .dropdown-menu.show {
  box-shadow: 0px 4px 28px rgb(182 182 182 / 25%);
  border: none;
}

.job-first-btn {
  padding: 13px 27px 13px !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.job-btn-style {
  position: absolute;
  top: 5px;
  right: -5px;
}

/* button.job-first-btn::before {
  font-family: "FontAwesome";
  content: "\f105";
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0px;
} */

button.btn-pull-right[aria-expanded="true"]:before {
  font-family: "FontAwesome";
  content: "\f106";
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0px;
  bottom: 14px;
}

button.btn-pull-right[aria-expanded="false"]::before {
  font-family: "FontAwesome";
  content: "\f105";
  padding-top: 0px;
  color: var(--primary-sky);
  font-size: 19px;
  font-weight: 700;
  position: absolute;
  left: 0px;
  bottom: 14px;
}

.no-job-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-job-message {
  padding: 26px;
  border: 2px solid #3FA4CC;
}

.live-rounded-button {
  display: flex;
  width: 22px;
  height: 20px;
  border-radius: 50%;
}

.live-rounded-green {
  background-color: #86FA12;
}

.live-rounded-red {
  background-color: #D80202;
}

.live-rounded-yellow {
  background-color: #F8CE3B;
}

.application-prev {
  color: #000 !important;
  margin-right: 16px !important;
  padding: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--primary-font-family);
  margin-right: 0;
  background-color: transparent !important;
  border-color: transparent !important;
}
.logo-banner-image {
  max-width: 120px;
}

.application-next {
  color: #000 !important;
  padding: 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--primary-font-family);
  margin-right: 0;
  background-color: transparent !important;
  border-color: transparent !important;
}

.publish-job-button {
  border-color: #3FA4CC !important;
  border: 3px solid #3FA4CC;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-left: 10px;
  padding: 2px 5px !important;
  border-radius: 6px;
}

.jobs-table-header tr:hover {
  background-color: unset;
}
.jobs-table-header tr th {
  border: unset;
  font-size: 16px;
  color: #3fa4cc;
  font-weight: 400;
  cursor: default;
}

.resp-job-header {
  border-bottom: 2px solid #f7f7f7;
  padding-left: 20px !important;
}

.job-candidate-list {
  width: 85%;
  margin-left: auto;
}

.job-dropdown-button {
  padding-right: 20px !important;
}
.job-dropdown-button .btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.job-dropdown-button .btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}
.lst-job-detail-mobile .btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.lst-job-detail-mobile .btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}

.second-job-table .btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.second-job-table .btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}

.sort-candidates-container .dropdown-toggle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lst-job-detail-mobile {
  margin-left: 10px;
}

.Collapse-section .appl-list-button.btn-primary {
  border: unset !important;
  border-color: unset !important;
}
.Collapse-section .appl-list-button.btn-primary:hover {
  border: unset !important;
  border-color: unset !important;
}

.table-data-section .job-table .btn-primary:hover,
.table-data-section .job-table .btn-primary:active,
.table-data-section .job-table .btn-primary:focus
 {
    border-color: unset;
    border: none;
}

@media (min-width: 768px) and (max-width: 1167.98px) {
  .text-apron-rigth-side-img img {
    display: none;
  }
}

.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 992px) {
  .show-fields-small-screen {
    display: none !important;
  }

  /* .truncate:hover {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  } */

  .collapse-data {
    display: none;
  }
}

@media (max-width: 1500px) {
  .job-inner-block {
    padding: 0px 0px;
  }
  .job-table .dropdown-menu.show {
    -webkit-transform: translate(-12px, 24px) !important;
            transform: translate(-12px, 24px) !important;
  }
}

@media (max-width: 1225px) {
  .main-section-part-two {
    padding: 44px 22px 0;
  }
}

@media (max-width: 992px) {
  .main-table tbody td {
    display: block;
    padding: 13px 23px 10px;
    font-size: 14px;
    line-height: 21px;
    border-top: none;
    max-width: 100%;
  }

  .truncate {
    white-space: normal;
    text-overflow: clip;
    overflow: visible;
  }

  .megamenu-section {
    padding-right: 20px !important;
    justify-content: flex-start !important;
    flex-direction: column;
  }

  .hide-fields-small-screen {
    display: none !important;
  }
  .table-hover-style:hover {
    background: var(--primary-light) !important;
  }

  .coll_block {
    padding-left: 20px !important;
  }

  .main-table tbody td button.mobile-job-appl.btn-primary {
    color: var(--primary-deep-dark) !important;
    border-color: unset !important;
    border: unset !important;
  }

  .mobile-icon {
    display: flex !important;
  }

  .Collapse-section .card-header {
    padding: 0 !important;
  }

  .collapse-data-style {
    display: none;
  }

  .Collapse-section .btn {
    font-size: 14px;
    line-height: 21px;
  }

  .table-data-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .jobs-table-header {
    display: none;
  }

  .table-data-btn button {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }

  .main-section-part-two {
    padding: 39px 33px 0;
  }

  .job-terms-main-section {
    font-size: 22px;
    line-height: 33px;
    font-weight: 500;
    padding-bottom: 36px !important;
    margin: 0 !important;
  }

  .job-main-table-section {
    padding: 36px 0px;
  }

  .second-menu {
    height: 5px;
  }

  .job-table .dropdown-menu a {
    font-size: 14px;
    line-height: 21px;
  }

  .second-job-table .dropdown-menu a {
    font-size: 14px;
    line-height: 21px;
    margin: 17px auto;
  }

  .table-style-block {
    margin: 25px 0px;
  }

  .text-red {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .text-green {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .job-table-icon {
    position: absolute;
    top: 9px;
    right: -2px;
  }

  input[class="skyblue"] + span::before {
    margin-top: 4px;
  }

  span.text-default-status.text-active.pl-lg-5 {
    padding-left: 0px !important;
  }
  span.text-publish.pl-lg-5 {
    padding-left: 0px !important;
  }

  .lst-job-detail-mobile .dropdown-item{
    margin: 17px auto;
    font-size: var(--small-font-size);
  }
}

@media (max-width: 768px) {
  .no-jobs-label-style {
    padding: 0px 0px;
  }
}

@media (max-width: 768px) {
  .main-section-part-two {
    padding: 30px 15px;
  }
}

@media (max-width: 575px) {
  .jobs-sort-option {
    justify-content: unset !important;
  }
  .jobs-sort-option div {
    max-width: 240px;
  }

  .jobs-sort-option .sort-select-section {
    margin-bottom: 10px;
  }
  .sort-candidates-container{
    flex-direction: column;
    align-items: flex-start !important;
  }
}
.sort-candidates-container .dropdown button { 
    color: #162937;
    background-color: #ffffff;
    border-color: #162937;
    padding: 2px 5px;
    border-radius: 0;
}

.table-data-section .second-job-table .btn-primary:hover,
.table-data-section .second-job-table .btn-primary:active,
.table-data-section .second-job-table .btn-primary:focus
 {
    border-color: unset;
    border: none;
}

@media (max-width: 660px) {
    .postjob-terms-condition .job-terms {
        font-size: 14px;
    }
}

@media (max-width: 602px) {
    .sort-candidates-container label {
        margin-bottom: 8px !important;
        color: #162937;
        font-size: 14px;
    }
}
.qrCodes-section {
    background-color: #E0ECFF;
    min-height: calc(100vh - 161px);
}

.background-left-image .logo-banner-image {
    max-height: 100vh;
}

.qrCodes-main-container { 
    z-index: 1;
    max-width: 80%;
    margin: auto;
}

.qrCodes-heading {
    color: #2B2E39;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 15px;
}

.add-qrCode-button {
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.no-qrCode-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-qrCode-message {
    padding: 26px;
    border: 2px solid #3FA4CC;
}

.delete-confirmation-body {
    padding: 56px;
}

.text-apron-rigth-side {
    position: absolute !important;
}

.qr-code-image-section {
    max-width: 100% !important;
}

.delete-doc-modal .modal-dialog {
    max-width: 720px !important;
}

.delete-conf-btn {
    display: flex;
    justify-content: space-around;
}

.qrCode-table tr:hover {
    cursor: default;
}
@media (max-width: 1024px) {
    .qr-code-image-section img {
        width: 100%;
    }
    .qrCodes-main-container {
      max-width: 90%;
    }
}

@media (max-width: 992px){
    .table-tr-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .qrCodes-heading{
        font-size: 22px;
        line-height: 33px;
        font-weight: 500;
        padding-bottom: 36px !important;
        margin: 0 !important;
    }
}

@media (max-width: 776px){
    .delete-conf-btn {
        margin: auto 0;
    }

    .mobile-menu-item {
        display: none !important;
    }

    .mobile-dropdown-menu {
        display: flex !important;
    }

    .table-tr-section {
        margin-bottom: 18px;
    }

    .delete-confirmation-body {
        padding: 25px;
    }

    .delete-doc-modal .modal-dialog {
        max-width: 525px !important;
    }
}

@media (max-width: 576px){
    .qr-code-image-section {
        max-width: 150px !important;
    }
    .delete-conf-btn button {
        width: 150px;
    }
}

@media (max-width: 425px){
    .qr-code-image-section {
        padding: 0 !important;
        max-width: 100px !important;
    }
}
.btn-resend-email {
  padding: 5px 44px;
  margin: 5px;
  color: var(--primary-blue);
  background-color: var(--primary-light);
  border: 2px solid var(--primary-blue) !important;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.btn-close-confirm {
  padding: 5px 44px;
  margin: 5px;
  color: white;
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-blue) !important;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}

.login-modal .btn-resend-email,
.btn-close-confirm {
  width: 100%;
  max-width: 209px;
  height: 50px;
}

.email-confirm-title {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: var(--large-font-size);
  line-height: 35px;
  margin-bottom: 40px;
}

.btn-confirm-container {
  display: flex;
  justify-content: space-around;
}

.email-cnfrm-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
  margin-bottom: 40px;
}

.lbl-resend-email {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #487b03;
  margin-bottom: 28px;
}

@media screen and (max-width: 425px) {
  .email-confirm-title {
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }

  .email-cnfrm-subtitle,
  .lbl-resend-email {
    font-family: var(--secondary-font-family);
  }

  .email-cnfrm-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
  }

  .lbl-resend-email {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .btn-resend-email,
  .btn-close-confirm {
    font-family: var(--secondary-font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    padding: 5px 0px;
  }

  .login-modal .btn-resend-email,
  .btn-close-confirm {
    width: 148px;
    height: 44px;
    font-size: 18px;
  }
}

.input-from-container {
  width: 87%;
  margin: 0 auto;
}

.account-term-container {
  width: 82%;
  margin: 0px auto 63px 38px;
  text-align: left;
}

@media (min-width: 426px) and (max-width: 490px) {
  .account-conf-modal-body {
    padding: 22px 10px 50px 10px;
  }
  .txt-accept-terms-col {
    padding-left: 10px;
  }

  .terms-checkbox-col {
    padding-right: 0px;
    margin-right: 0;
  }

  .txt-accept-terms-col {
    padding-left: 12px;
  }
}

@media screen and (max-width: 425px) {
  .input-from-container {
    width: 100%;
    margin: 0 auto;
  }

  .account-term-container {
    width: 100%;
    margin: 0px auto 38px auto;
  }

  .accountconf-title {
    font-weight: 600;
  }
}

.lbl-description {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  margin-top: 10px;
  margin-bottom: 7px;
}

.lbl-publish-title > div:first-child{
    font-weight: 600;
}

.btn-add-new-job {
  width: 100%;
  max-width: 184px;
  height: 50px;
  padding: 5px 10px;
  margin: 5px;
  color: white;
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
  font-weight: 500;
  font-size: 16px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}

.btn-job-disable {
  background-color: #808080 !important;
  border: 1px solid #808080 !important;
}

.btn-job-enable {
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
}

.lbl-add-qualification {
  margin-top: 24px;
}

.job-expr-outer-container {
  align-items: center;
}

.rmdp-container {
  width: 100%;
}

.expire-date-header{
  padding-bottom: 10px;
}

/* .tox-statusbar__text-container span {
  display: none;
} */

.published-modal .modal-dialog {
  max-width: 1400px !important;
}

.job-modal-head-container {
  text-align: right;
  cursor: pointer;
}

.editor-cotainer .tox-statusbar__branding {
  display: none;
}

.edit-job-left-container {
  margin-right: 78px;
}

.input-disable-container .form-floating-input input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--primary-color-deepdark);
  background-color: var(--primary-light);
}

.pub-qualification {
  margin-top: 42px;
}

.publisedjob-expr-date {
  margin-top: 110px;
}

@media screen and (max-width: 425px) {
  .job-modal-head-container {
    margin-top: 10px;
    margin-right: 10px;
  }
}

.stage-job-container {
  min-height: 750px;
}

@media (max-width: 992px) {
  .stage-job-container {
    min-height: 550px;
    margin-top: 74px;
  }
}

@media (max-width: 425px) {
  .stage-job-container {
    margin-top: 74px;
  }
}

.bg-dark-blue {
  background: #102938;
}

.footer-section li {
  list-style-type: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.footer-section ul {
  -webkit-padding-start: 32px !important;
          padding-inline-start: 32px !important;
}

.text-grey {
  color: var(--primary-light);
  opacity: 0.64;
}

.footer-section {
  padding: 30px 0px 8px 0px;
  font-weight: 400;
  font-family: poppins;
}

.page-wrapper {
  margin-top: 0px;
}

.contact-block {
  width: 100px;
}

.text-cursor {
  cursor: pointer;
}

.text-footer-link a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.txt-bottom-last-right {
  margin-right: 12px;
}

@media screen and (max-width: 425px) {
  .footer-container {
    padding: 0;
  }

  .footer-col {
    padding-left: 0px;
  }
}
.footer-main-container {
  z-index: 1;
  background-color: #0351C7;
}
.footer-title-row {
  padding-top: 60px;
  padding-bottom: 30px;
}
.logo-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-left: 10px;
}
.lbl-footer-copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 20px;
}
.lbl-footer-content {
  margin-bottom: 10px;
}
.lbl-footer-content a {
  color: #ffffff;
  text-decoration: none;
}
.lbl-footer-description a {
  color: #ffffff;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .footer-title-row {
    max-width: 100%;
    margin: auto;
  }

  .contact-block-container {
    display: none !important;
  }
  .footer-title-row div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-title-row div.footer-logo {
    flex-direction: row !important;
  }
  .footer-left-container {
    margin-bottom: 40px;
  }
}
/* sidebar-menu */
.search-block {
  padding: 42px 26px 100px 26px;
  margin-bottom: 31px;
}
.search-section .dropdown-toggle::after {
  color: #000 !important;
  margin: auto 0;
  content: none;
}
.search-section [data-dropdown] > select.dropdown-display {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.down-arrow {
  position: absolute;
  top: 23px;
  right: 8px;
}
.slidebar-serch-block .form-label {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  color: var(--primary-deep-dark);
}

.label-colon {
  display: none;
}

.search-error-container {
  margin-top: 28%;
}

.search-input-error {
  margin-top: 20px;
}

.job-desc-block b {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--primary-deep-dark);
}

.job-desc-block p {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.003em;
  margin-bottom: 5px;
  font-style: normal;
}

.job-desc-block i {
  font-style: normal;
}

.searchLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 999999;
}

.slidebar-serch-block .form-control {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding: 26px 29px;
  color: var(--primary-deep-dark);
  /* border: none; */
  transition: none;
  background-color: var(--secondary-color-dark-gray);
  border-radius: 5px;
}

.job-type-title {
  padding: 0px 28px 0px 53px;
}

/* .slidebar-serch-block .form-control:focus {
  border-color: var(--primary-sky);
} */

.fullscreen-icon {
  cursor: pointer;
}

.slidebar-serch-block input::-webkit-input-placeholder {
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-deep-dark);
}

.slidebar-serch-block input::placeholder {
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-deep-dark);
}
.slidebar-serch-block .dropdown button {
  font-weight: 400px;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-deep-dark);
}
.slidebar-serch-block .dropdown-menu a:hover {
  border-left: 7px solid transparent;
  background: var(--secondary-color-sky);
  transition: all ease-in-out 0.5s;
}

.slidebar-serch-block .dropdown-menu {
  width: 100%;
  -webkit-transform: translate3d(0px, 52px, 0px);
          transform: translate3d(0px, 52px, 0px);
}

.joblist-sections {
  padding: 0px 64px;
  background: rgba(129, 178, 20, 0.16);
}

.select-search-dropdown .dropdown-menu {
  max-height: 155px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 14rem;
}
.invalid-input {
  border: 2px solid #c60000;
}
.valid-input {
  border: none;
}
button.btn.btn-primary.contact-btn:hover {
  background-color: var(--primary-sky);
}
.select-search-dropdown {
  /* padding: 4px 0px; */
  /* background-color: var(--secondary-color-dark-gray); */
  border-radius: 5px;
  cursor: pointer;
}

.qualification-section-block li::marker {
  color: var(--primary-sky);
  height: 8px;
  width: 8px;
}
.qualification-section-block li {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 0.3px;
  color: var(--primary-deep-dark);
  line-height: 26px;
}
.search-application-type {
  margin: 43px 0px;
  padding-top: 32px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 4%);
  border-radius: 6px;
  border: none;
}

.search-job-desc {
  max-height: 205px;
  white-space: nowrap;
  overflow: hidden;
}

.search-application-type .card-header {
  background-color: var(--primary-light);
  border-bottom: 2px solid #f1f1f1;
}
.text-brown {
  color: var(--primary-deep-dark);
}
.job-type {
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
}
.job-type-subtitle {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

.bottom-pagination .page-link {
  border: 0;
}

.bottom-pagination .page-item:first-child .page-link {
  margin-right: 14px;
}

.bottom-pagination .sr-only {
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: var(--primary-deep-dark);
}

.restorrent-block {
  font-size: 20px;
  line-height: 18px;
  font-weight: 600;
}
.search-application-type .card-img {
  height: 25px;
  width: 25px;
}
.pagination-data .sr-only {
  position: relative;
}
.pagination-data li a span:first-child {
  display: none;
}
.pagination-data .page-link {
  background-color: transparent;
  color: var(--primary-deep-dark);
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
}
@media (max-width: 1024px) {
  .slidebar-serch-block .form-label {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: var(--primary-deep-dark);
  }
}
@media (max-width: 991.98px) {
  .joblist-sections {
    padding: 0px 17px;
  }
  .card-header {
    padding: 0.75rem 0rem !important;
  }
  .slidebar-serch-block .form-label {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    font-weight: 700;
    color: var(--primary-deep-dark);
  }

  .search-application-type {
    margin: 13px 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #bdbdbd;
    border-radius: 6px;
  }
  .job-description {
    padding: 65px 38px 0;
  }
  .job-type {
    font-size: 16px;
    line-height: 10px;
    font-weight: 600;
  }
  .restorrent-block {
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
  }
  .qualification-section-block li {
    font-weight: 400;
    line-height: 23px;
    font-size: 17px;
  }

  .lg-search-result {
    display: none;
  }

  .search-application-type .card-header {
    border-bottom: solid transparent;
  }
  .joblist-sections {
    padding: 0px 39px;
  }
  .edit-filter {
    font-size: 16px;
    line-height: 16px;
    margin: auto 0;
  }
  .edit-filter-block {
    padding: 25px 38px;
    height: 107vh;
    overflow-y: auto;
  }
  .edit-filter-block::-webkit-scrollbar-thumb {
    background: #b8b8b8;
    border-radius: 21px;
  }
  .edit-filter-block::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .job-type-title {
    padding: 0;
  }
  .job-type-subtitle {
    font-size: 14px;
  }
  .job-type {
    font-size: 16px;
  }
  .App-btn-data {
    width: auto;
  }
  .restaurant-manager {
    border-bottom: 1px solid #dddddd;
  }
  .job-summary-data {
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
  }
  .job-summary-inner {
    font-size: 12px;
    line-height: 20px;
    width: 600;
    font-weight: 400;
    padding: 15px 4px;
  }
  .primary-block {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
  .primary-section-block li {
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    padding: 6px 0px;
    color: var(--primary-deep-dark);
  }
  .primary-section-block li::marker {
    color: var(--primary-sky);
    height: 8px;
    width: 8px;
  }

  .back-edit-filter {
    margin: 5px 0px 25px 3px;
  }

  .search-res-title {
    background-color: var(--primary-light);
  }

  .fs-apply-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 29px !important;
  }
  .edit-filter-block::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  .edit-filter-block {
    padding: 0px;
    overflow-x: hidden;
  }

  .job-desc-block p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: var(--primary-deep-dark);
  }

  .job-desc-block b {
    font-style: normal;
    font-family: "Open Sans";
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.3px;
  }

  .searchLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 999999;
  }

  .select-search-dropdown .dropdown-item {
    font-size: 14px;
    line-height: 24px;
  }
}
@media (max-width: 767.98px) {
  .joblist-sections {
    padding: 64px 40px;
  }

  .search-button-css {
    text-align: center;
    border: none;
  }

  .search-block {
    margin-bottom: 31px;
    padding: 104px 33px 0px;
  }

  .slidebar-serch-block .form-control {
    font-size: 14px;
  }

  .slidebar-serch-block .form-control::-webkit-input-placeholder {
    font-size: 14px;
  }

  .slidebar-serch-block .form-control::placeholder {
    font-size: 14px;
  }

  .label-colon {
    display: block;
  }

  .back-edit-filter {
    margin: 25px 0px 25px 3px;
  }
}

@media (max-width: 575.98px) {
}
@media (max-width: 375px) {
  .job-type {
    font-size: 13px;
    line-height: 20px;
  }
}

.support-main-container {
  background-image: url(/static/media/header_large_back.93eadca9.png);
  background-repeat: no-repeat;
  background-size: 100% 110%;
}

.support-description {
  padding-bottom: 44px;
  margin-bottom: -32px;
  background: var(--primary-light);
  border-radius: 14px;
  padding: 72px 65px;
  margin: 60px auto;
  box-shadow: 0px 0px 42px -5px rgba(196, 196, 196, 0.40);
}

.main-content_section {
  background-image: url(/static/media/footer_background_section.719ba9f8.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  padding-bottom: 10px;
}

.support-input-container {
  padding: 0px 32px;
}

.support-title {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 45px;
  color: var(--primary-dark);
}


.support-inner-container {
  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url(/static/media/footer_background_section.719ba9f8.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  margin-bottom: -50px;
}

.support-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.secondary-input-container {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding-top: 0px;
  height: 55px;
  border-bottom-width: 2px;
  background: #f9f9f9;
  border-radius: 4px;
  -webkit-filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
          filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
}

.secondary-input-container input,
.secondary-input-container textarea {
  border-bottom: 2px solid #0351C7;
  outline: none;
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding: 22px 0 8px 5px;
  margin-top: 6px;
  background-color: var(--light-silver);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #000;
}

.secondary-input-container span {
  padding-left: 16px;
  padding-top: 16px;
  opacity: 0.54;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: var(--primary-dark);
}

.secondary-input-container,
.floating-label input:focus,
.messasge-input textarea:focus {
  color: #000;
  border-color: #0351C7 !important;
}

.messasge-input input,
.messasge-input textarea {
  height: 231px;
}

.message-input-container {
  height: 231px;
}

.btn-support-submit {
  padding: 10px 44px;
  margin-top: 46px;
  color: var(--primary-light);
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-light) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  width: 100%;
  border-radius: 7px;
}

.support-img-col {
  display: block;
}

.msg-top-space {
  margin-top: 22px;
}

@media screen and (max-width: 991px) {
  .support-inner-container {
    margin-bottom: 0;
  }
  .support-description {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .support-description {
    padding: 45px 29px;
  }

  .support-img-col {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .support-inner-container {
    padding-top: 60px;
    background-image: unset;
  }
  .btn-support-submit {
    margin-top: 12px;
    padding: 8px 44px;
  }
  .support-title {
    font-weight: bold;
  }
}

@media screen and (max-width: 576px) {
  .support-description {
    max-width: 100%;
  }
}


@media screen and (max-width: 425px) {
  .support-img-col {
    display: none;
  }

  .support-title {
    font-size: 22px;
    line-height: 24px;
  }

  .support-sub-title {
    font-size: 14px;
    line-height: 25px;
  }

  .support-description {
    padding: 45px 13px;
  }
  .secondary-input-container input,
  .secondary-input-container textarea {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 375px) {
  .support-description {
    padding: 45px 7px;
  }
}

.notfound-container {
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 768px) {
  .img-notfound {
    width: 100%;
  }
}

.loadingparent {
    position: fixed; /* Fixed position */
    top: 0;
    left: 0;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    z-index: 10000; /* High enough to be on top */
}


.deftle-modal.right.fade .modal-dialog,
.deftle-modal.close-right.fade:not(.show) .modal-dialog {
    -webkit-transform: translate3d(100vw, 0, 0);
            transform: translate3d(100vw, 0, 0);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.deftle-modal.left.fade .modal-dialog,
.deftle-modal.close-left.fade:not(.show) .modal-dialog {
    -webkit-transform: translate3d(-100vw, 0, 0);
            transform: translate3d(-100vw, 0, 0);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.deftle-modal.fade.show .modal-dialog {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

/* .modal.fade .modal-dialog {
  right: -2020px !important;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
} */

.auth-modal.right.fade.show .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.left.fade.show .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.close-right.fade:not(.show, .close-left) .modal-dialog {
  /* right: -100vw;
  top: 0;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out !important;
  transition: opacity 0.3s linear, right 0.3s ease-out !important; */
  -webkit-transform: translate3d(100vw, 0, 0);
          transform: translate3d(100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.close-left.fade:not(.show) .modal-dialog {
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.right .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  -webkit-transform: translate3d(100vw, 0, 0);
          transform: translate3d(100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.auth-modal.left .modal-dialog {
  /* -webkit-transform: translate3d(25%, 0, 0); */
  -webkit-transform: translate3d(-100vw, 0, 0);
          transform: translate3d(-100vw, 0, 0);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.modal-header {
  display: inline !important;
  color: var(--primary-sky);
  font-family: var(--primary-font-family) !important;
  font-size: 12px;
  font-weight: 700;
  border-bottom: none !important;
}
.modal-dialog {
  max-width: 310px !important;
}

.fed-placeholder-modal .modal-content {
  width: 73%;
  margin: auto;
}

.fed-auth-loader {
  width: 100%;
  height: 100%;
  left: -1%;
  bottom: 0%;
}

.fed-type-title {
  margin-bottom: 70px !important;
}

.auth-modal-back {
  cursor: pointer;
  margin-bottom: 0;
  display: flex;
}

.modal-content {
  background: var(--primary-light);
  box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.56);
}

.modal-body {
  text-align: center;
  padding: 50px 98px 56px 98px;
}

.lbl-auth-or {
  z-index: 999999;
  margin: 20px 0px;
  font-family: var(--primary-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-sky);
}

.btn-auth-back {
  display: flex;
}

.bth-auth {
  box-shadow: 0px 0px 6px 2px rgb(169 156 156 / 28%);
}

.btn-signup {
  padding: 9px 18px 9px 18px;
  margin: 6px 0;
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  width: 80%;
  font-family: var(--secondary-font-family);
  text-align: left;
}

.btn-auth-social {
  padding: 9px 18px 9px 18px;
  margin: 6px 0;
  color: white;
  font-weight: 500;
  font-size: 18px;
  border-radius: 4px;
  width: 80%;
  text-align: left;
  font-family: var(--secondary-font-family);
}
.btn-auth-social.btn-dark {
  background-color: #000;
  border-color: #000;
}
.btn-auth-social.btn-primary {
  background-color: #1877f2;
  border-color: #1877f2;
}
.btn-signup.btn-warning {
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}
.btn-signup.btn-warning:hover,
.btn-signup.btn-warning:active,
.btn-signup.btn-warning:focus {
  color: var(--primary-light) !important;
}

.btn-signup.btn-warning:not(:disabled):not(.disabled):active {
  background-color: var(--primary-sky);
  border-color: var(--primary-sky);
}

.btn-pwa-singin {
  background-color: var(--primary-light);
  border: 2px solid var(--primary-sky) !important;
  color: var(--primary-sky);
  font-weight: 500;
}

.btn-signup img {
  width: 22px;
  height: 22px;
  margin: 4px;
  /* background-color: white; */
  border-radius: 2px;
  margin-right: 10px;
}

.btn-auth-social img {
  width: 22px;
  height: 22px;
  margin: 4px;
  margin-right: 10px;
}
.btn-light {
  color: #757575;
}

.label-back {
  font-family: var(--primary-font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-left: 7px;
}

.auth-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1199999;
  left: -1%;
  bottom: 0%;
}

.job-sub-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
  color: var(--primary-dark);
}

.btn-auth-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .modal-body {
    padding: 22px 30px 50px 30px;
  }
  .modal-header {
    padding: 22px 0 0 32px;
  }

  .modal-content {
    background: var(--primary-light) !important;
    box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.9);
  }
}

@media screen and (max-width: 425px) {
  .auth-modal-cancel {
    justify-content: space-between;
  }

  .lbl-auth-or {
    margin: 15px 0px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
}

.homePageLoader {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999;
  bottom: 10%;
  right: 0%;
}

.recaptcha-container {
  display: none;
}

.modal-header {
  display: inline !important;
  color: #3a3a3a;
  font-family: var(--primary-font-family) !important;
  font-size: 12px;
  font-weight: 700;
  padding: 26px 44px;
  border-bottom: none !important;
}
.modal-dialog {
  max-width: 525px !important;
}
.modal-content {
  background: var(--primary-light);
  box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.56);
  border-radius: 0;
  border: none;
}

.btn-pwa-signUp {
  padding: 5px 44px;
  margin: 5px;
  color: white;
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-sky) !important;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  width: 55%;
  border-radius: 4px;
  font-family: var(--secondary-font-family);
}
.btn-signup {
  width: 100%;
}
.btn-auth-social {
  width: 100%;
}
.credentials-detalis {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 25px;
  padding: 10px;
}
.email {
  color: var(--primary-sky);
  padding: 7px;
  font-size: 11px;
}

label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}

.form-floating {
  position: relative;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: #333;
}

label:hover span::before,
label:hover span::before {
  box-shadow: 0 0 2px #ccc;
}

/* label:hover span,
label:hover span {
  color: #000;
} */

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 1px #a8a8a8;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  background: #f6f6f6;
}

input[type="checkbox"]:checked + span::before {
  color: #666;
}

input[type="checkbox"]:disabled + span {
  cursor: default;

  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 18px;
  line-height: 23px;
}

/* input[class="blue"] + span::before {
  border: solid 1px var(--primary-deep-dark);
  background-color: transparent;
  width: 26px;
  height: 26px;
} */

input[class="blue"] + span::before {
  border: solid 2px var(--primary-blue);
  background-color: transparent;
  width: 20px;
  height: 20px;
  margin: 0 9px 0 0;
}

input[class="blue"]:checked + span::before {
  color: var(--primary-deep-dark);
}

.password-change {
  font-size: 14px;
  color: var(--primary-sky);
  font-weight: var(--medium-font-weight);
  cursor: pointer;
}

.sigin-third-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.lbl-keep-login {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-dark);
  font-family: var(--primary-font-family);
}

.login-btn {
  text-align: center;
}
.error-msg p,
.error-msg {
  font-size: 14px;
  text-align: center;
  color: var(--primary-color-danger);
  font-weight: 500;
  font-family: var(--secondary-font-family);
  font-style: normal;
}
.login-modal .modal-dialog {
  max-width: 784px !important;
}
.login-modal .credentials-detalis {
  padding-bottom: 50px;
}
.login-modal .btn-pwa-signUp {
  width: 100%;
  max-width: 214px;
  height: 50px;
}

.keep-login {
  text-align: left;
}
.top-forgot-container {
  display: none;
}
@media (max-width: 767px) {
  .credentials-detalis {
    font-size: 18px;
  }
  .login-modal .credentials-detalis {
    padding-bottom: 39px;
    padding-left: 0;
    padding-right: 0;
  }

  .top-forgot-container {
    display: block;
    text-align: right;
    margin-bottom: 11px;
  }
  .bottom-forgot {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  .signin-modal-body {
    padding: 0px 30px 38px 30px !important;
  }

  .signin-modal-body .credentials-detalis {
    padding-bottom: 29px;
  }

  .auth-floating-input {
    height: 50px;
  }

  .credentials-detalis {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 375px) {
  .password-change {
    margin: 23px 0px;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) {
}

.password-detalis p{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: var(--forms-text-color);
  margin-bottom: 11px;
}

.term-condition {
  font-size: 16px;
  color: var(--primary-blue);
  font-weight: var(--semi-bold-font-weight);
}

.terms-lbl-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.accept-terms-condition {
  font-size: 16px;
  color: #3a3a3a !important;
  font-weight: 400 !important;
  -webkit-user-select: none;
          user-select: none;
  font-family: var(--primary-font-family) !important;
  line-height: 20px;
}

/* .check-box label:hover .term-condition {
  color: var(--primary-sky);
} */
.create-account-modal .modal-dialog {
  max-width: 870px !important;
}

.create-account-modal .modal-body {
  padding: 0px 98px 84px 98px;
}

.create-account-modal .login-btn {
  margin-top: 60px;
}

.create-account-modal .btn-pwa-signUp {
  width: 100%;
  max-width: 208px;
  height: 50px;
}

.term-condition label:hover span,
.term-condition label:hover span {
  color: var(--primary-blue) !important;
}

.txt-accept-terms-col {
  padding-top: 2px;
  text-align: left;
  padding-left: 10px;
}

.create-account-modal .credentials-detalis {
  padding-bottom: 50px;
}

.registration-error-msg p {
 text-align: start !important;
} 

@media (max-width: 767px) {
  .create-account-modal .modal-body {
    padding: 0 30px 50px 30px;
  }

  .create-account-modal .credentials-detalis {
    padding-bottom: 39px;
  }

  .create-account-modal .modal-header {
    padding: 22px 0 12px 32px;
  }

  .txt-accept-terms-col {
    padding-left: 10px;
  }
  .modal-open .modal {
    position: fixed !important;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @supports (-webkit-touch-callout: none) {
    .scroll-modal {
      overflow: hidden !important;
    }
  }
}

@media screen and (max-width: 425px) {
  .signup-back {
    float: left;
  }

  .signup-modal-title {
    padding: 0px 0px 24px 0px !important;
  }

  .signup-btn {
    margin-top: 18px !important;
  }

  .create-account-modal .modal-body {
    padding: 24px 30px 24px 30px;
  }

  .accept-terms {
    margin-left: 1px;
  }

  .check-box {
    margin-top: 14px;
  }

  .password-detalis,
  .password-detalis p {
    font-family: var(--secondary-font-family);
    line-height: 14px;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .accept-terms-condition {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #3a3a3a !important;
    font-family: var(--secondary-font-family) !important;
  }

  .term-condition {
    font-size: 14px;
    color: var(--primary-blue);
    font-weight: var(--semi-bold-font-weight);
    font-family: var(--secondary-font-family);
  }

  .txt-accept-terms-col {
    padding-top: 1px;
    padding-left: 0px;
  }
  .term-chkbox-row,
  .terms-checkbox-col {
    margin: 0 0;
    padding: 0;
  }
}

@media screen and (max-width: 408px) {
  .terms-checkbox-col {
    padding-left: 0px;
  }

  .txt-accept-terms-col {
    padding-left: 0px;
  }

  .term-chkbox-row {
    margin: auto 0;
  }
}

.input-from-container {
  width: 87%;
  margin: 0 auto;
}

.account-term-container {
  width: 82%;
  margin: 0px auto 63px 38px;
  text-align: left;
}

@media (min-width: 426px) and (max-width: 490px) {
  .account-conf-modal-body {
    padding: 22px 10px 50px 10px;
  }
  .txt-accept-terms-col {
    padding-left: 10px;
  }

  .terms-checkbox-col {
    padding-right: 0px;
    margin-right: 0;
  }

  .txt-accept-terms-col {
    padding-left: 12px;
  }
}

@media screen and (max-width: 425px) {
  .input-from-container {
    width: 100%;
    margin: 0 auto;
  }

  .account-term-container {
    width: 100%;
    margin: 0px auto 38px auto;
  }

  .accountconf-title {
    font-weight: 600;
  }
}

.btn-pwa-forgot-container {
  margin-top: 46px;
}

.forgot-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: var(--medium-font-size);
  line-height: 35px;
}

.forgot-back-container {
  text-align: center;
  display: flex;
}

.success-msg,
.success-msg p {
  color: #487b03;
}

.forgot-title {
  padding-bottom: 42px !important;
}

.forgot-input {
  margin-top: 38px;
}

.forgot-pass-body {
  padding: 0px 98px 56px 98px;
}
@media screen and (max-width: 768px) {
  .forgot-input {
    margin-top: 5px;
  }
}
@media screen and (max-width: 425px) {
  .forgot-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: var(--secondary-font-family);
  }

  .forgot-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .success-msg {
    font-family: var(--secondary-font-family);
  }

  .forgot-back-container {
    text-align: center;
    display: flex;
    /* padding: 10px 5px; */
  }
}


.qr-registretion-container {
    background-color: #E0ECFF;
    min-height: calc(100vh - 161px);
}

.qr-registretion-container .logo-banner-image {
    max-height: 340px;
    margin-top: 60px;
}

.register-main-container {
    z-index: 1;
    max-width: 80%;
    margin: auto;
}

.register-heading {
    margin-top: 60px;
    color: #2B2E39;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.register-prompt {
    padding-top: 10px;
    color: #2B2E39;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    text-align: center;
}

.html-qrcode-container {
    padding-top: 36px;
    padding-bottom: 36px;
}

.not-start-camera-stream {
    max-width: 686px;
    width: 100%;
    height: 400px;
    background-color: #000000;
}

.scanner-button {
    max-width: 500px;
    justify-content: space-between;
}

.qrcode-register-button {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.re-scan-button {
    outline: none;
    color: #17a2b8;
    border: 2px solid#3FA4CC;
    background:  #FFF;
}

.continue-after-scan {
    color: #FDFDFD;
    background: #3FA4CC;
}

#html5-qr-code-reader video {
    max-width: 686px;
    width: 100% !important;
    height: 100%;
}

.role-dropdown::after {
    display: none !important;
}

.role-select-heading {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.deftle-modal .location-dropdown {
    width: 100%;
}

.deftle-modal .dropdown-menu {
    min-width: 100%;
}

.role-main-container {
    width: 85%;
}

.add-role-button {
    margin-top: 30px;
}

.camera-permission-title {
    color: #3A3A3A;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.camera-confirmation-body {
    padding: 40px 30px;
}

.camera-permission-text {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.3px;
    margin: 24px 0;
}

.camera-permission-button {
    width: 100%;
    max-width: 148px;
    height: 41px;
    border-radius: 7px;
    border: 1px solid #FFF;
    background: #3FA4CC;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}

.camera-permission-modal .modal-dialog {
    max-width: 384px !important;
    margin: auto;
    height: 100%;
}

.permission-failed-model .modal-dialog {
    max-width: 384px !important;
    margin: auto;
    height: 100%;
}

.camera-permission-modal .modal-dialog .modal-content {
    height: 260px !important;
    box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.90);
}
.permission-failed-model .modal-dialog .modal-content {
    box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.90);
}

.select-role-model-title {
    color: #3A3A3A;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 20px;
}

.loading-model-text {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.3px;
    padding: 0 12px;
}

.loading-confirmation-body {
    display: flex;
    align-items: center;
    padding: 50px 80px 56px 80px;
}

.registration-complete-title {
    color: #3A3A3A;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
}

.registration-confirmation-body {
    padding: 50px 100px;
}

.close-registration-compelete-btn {
    max-width: 236px;
    height: 46px;
    width: 100%;
}

.verify-email-model .modal-content {
    padding: 30px;
}
.verify-email-model .modal-title {
    margin-bottom: 34px;
    color: #3A3A3A;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;  
}

.verify-email-text {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 138.889% */
    letter-spacing: 0.3px;
}

.close-verify-email-button {
    max-width: 236px;
    height: 46px;
    width: 100%;
}
.verify-email-model .modal-content .modal-body {
    padding: 30px 30px 0 30px;
}

.permission-failed-header {
    color: #3A3A3A;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0;
}

.permission-failed-body {
    padding: 40px 35px;
}

.permission-failed-text {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-bottom: 25px;
}

.permission-failed-btn {
    max-width: 148px;
    height: 41px;
    width: 100%;
    background-color: #3FA4CC;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}

@media (max-width: 768px) {
    .register-heading {
        margin: 0!important;
        color: #2B2E39;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .register-prompt {
        font-size: 16px;
    }

    .register-main-container {
        padding-top: 20px;
    }

    .html-qrcode-container {
        padding-top: 10px;
        padding-bottom: 30px;
    }
    .not-start-camera-stream {
        height: 300px;
    }
    #html5-qr-code-reader video {
        height: 300px;
        width: 100%;
    }
}

@media (max-width: 576px) {
    .camera-permission-modal .modal-dialog .modal-content {
        margin: 20px 0;
    }
    .permission-failed-model .modal-dialog .modal-content {
        margin: 20px 0;
    }
    .qrcode-register-button {
        width: 110px;
        height: 38px;
        font-size: 16px;
        font-weight: 500;
        padding: 5px;
    }
    .verify-email-model .modal-title {
        color: #3A3A3A;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
    .verify-email-model .modal-content .modal-body {
        padding: 0;
        padding-bottom: 25px;
    }
    .verify-email-text {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.3px;
    }
    .close-verify-email-button {
        max-width: 148px;
        height: 41px;
        width: 100%;
    }
    .registration-modal .modal-dialog {
        margin: auto;
        max-width: 400px !important;
    }
    .registration-confirmation-body {
        padding: 38px;
    }

    .job-located-title {
        color: #3A3A3A;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .close-job-style {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.3px;
    }
    .job-located-header {
        padding-left: 0 !important;
    }
}
@media (max-width: 425px) {
    .registration-confirmation-body {
        padding: 38px 15px;
    }
    .registration-modal .modal-dialog .modal-content {
        margin: 10px;
    }
    .camera-permission-modal .modal-dialog .modal-content {
        margin: 15px;
    }
    .permission-failed-model .modal-dialog .modal-content {
        margin: 15px;
    }
    .permission-failed-header {
        font-size: 18px;
    }
    .permission-failed-text {
        font-size: 12px;
    }
}
@media (max-width: 368px) {
    .loading-confirmation-body {
        padding: 10px;
        justify-content: center;
    }
}

#html5-qr-code-reader > div {
    display: none !important;
}
body {
  margin: 0;
  background: rgba(255, 255, 255, 1);
  font-family: var(--primary-font-family);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: var(--primary-font-family);
  height: 100%;
  scroll-behavior: smooth;
}

div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e6f3f8;
}

a {
  cursor: pointer;
  text-decoration: none;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

