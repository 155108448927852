.privacy-desc-view-area {
  width: 100%;
  height: 401px;
  min-height: 401px;
  border: 2px solid #3a3a3a;
  margin-bottom: 46px;
  margin-top: 43px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 15px;
  background-color: var(--primary-light);
  text-align: left;
}

@media screen and (max-width: 425px) {
  .privacy-desc-view-area {
    min-height: 269px;
    margin-bottom: 26px;
    margin-top: 26px;
    height: 269px;
  }

  .privacy-desc-view-area::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  .privacy-modal-title {
    font-weight: 600 !important;
    text-align: center;
  }

  /* Handle */
  .privacy-desc-view-area::-webkit-scrollbar-thumb {
    background-color: #3a3a3a;

    border-radius: 4px;
    /* background-color: rgba(0, 0, 0, 0.5); */
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
