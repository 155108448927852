@import url("https://fonts.googleapis.com/css?family=Poppins:500,400");

body {
  margin: 0;
  background: rgba(255, 255, 255, 1);
  font-family: var(--primary-font-family);
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: var(--primary-font-family);
  height: 100%;
  scroll-behavior: smooth;
}

div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e6f3f8;
}

a {
  cursor: pointer;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
