.modal-postjob-data .modal-content {
  padding: 22px 0px 52px;
  background: var(--primary-light) !important;
  border-radius: 0;
  box-shadow: 0px 6px 60px -5px rgba(117, 117, 117, 0.26);
}

.modal-postjob-data .modal-body h5 {
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  padding-bottom: 41px;
}

.modal-postjob-data .modal-body {
  padding: 0px 177px 0px;
  font-size: 18px;
  line-height: 27px;
  text-align: start;
}

.postjob-terms-condition {
  font-size: 14px !important;
  color: #162937 !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  -webkit-user-select: none;
  user-select: none;
}

.modal-postjob-data .modal-dialog {
  max-width: 870px !important;
}

.text-skyblue {
  color: var(--primary-sky);
}

.editor-main-block .tox-statusbar__text-container {
  display: none !important;
}

.editor-container-border .tox-tinymce {
  border: 2px solid var(--primary-blue);
}

.editor-container-error-border .tox-tinymce {
  border: 2px solid var(--primary-color-danger);
}

.post-jobtype-error::before {
  border-color: var(--primary-color-danger) !important;
}

.tox-notifications-container {
  display: none !important;
}

button.close {
  display: inline;
}

.modal-content-data .modal-header {
  padding: 0 50px;
}

.postjob-data {
  width: 0px;
}

label,
input[type="checkbox"] + span,
input[type="checkbox"] + span::before {
  display: inline-block;
  vertical-align: middle;
}

.form-floating {
  position: relative;
}

label *,
label * {
  cursor: pointer;
}

input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] + span {
  /* font: normal 11px/14px Arial, Sans-serif; */
  color: var(--primary-sky);
}

label:hover span::before,
label:hover span::before {
  -moz-box-shadow: 0 0 2px #ccc;
  -webkit-box-shadow: 0 0 2px #ccc;
  box-shadow: 0 0 2px #ccc;
}

input[type="radio"] + span::before,
input[type="checkbox"] + span::before {
  content: "";
  width: 19.5px;
  height: 19.5px;
  margin: 0 4px 0 0;
  border: solid 1px #a8a8a8;
  line-height: 14px;
  text-align: center;
  border-radius: 100%;
  background: #f6f6f6;
}

input[type="checkbox"]:checked + span::before {
  color: var(--primary-sky);
}

input[type="checkbox"]:disabled + span {
  cursor: default;

  -moz-opacity: 0.4;
  -webkit-opacity: 0.4;
  opacity: 0.4;
}

input[type="checkbox"] + span::before {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

input[type="checkbox"]:checked + span::before {
  content: "\2714";
  font-size: 16px;
  line-height: 19px;
}

input[class="skyblue"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 19.5px;
  height: 19.5px;
}

input[class="skyblue-check-box"] + span::before {
  border: solid 1px var(--primary-sky);
  background-color: transparent;
  width: 17.5px;
  height: 17.5px;
}

input[class="skyblue-check-box"]:checked + span::before {
  color: var(--primary-sky);
}

input[class="skyblue"]:checked + span::before {
  color: var(--primary-sky);
}

.postjob-block-inner {
  padding-top: 21px;
  padding-bottom: 12px;
}

/* ------job-details-modal---------- */
.close-style {
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
}

.job-details-modal .modal-dialog {
  /* max-width: 1169px !important; */
  max-width: 1000px !important;
}

.job-details-modal .modal-content {
  padding: 26px 0px 26px;
  background: var(--primary-light) !important;
  border-radius: 0;
  box-shadow: 0px 0px 42px -5px rgba(196, 196, 196, 0.4);
}

.job-details-modal .modal-header {
  /* padding: 26px 115px 26px 98px; */
  padding: 16px 115px 16px 98px;
  border-bottom: none !important;
}

.job-details-modal .modal-body {
  padding: 0px 97px 0px 98px;
}

.job-details-modal .modal-title {
  padding-bottom: 23px;
}

.editor-main-block {
  padding-top: 20px;
}

.editor-main-block .tox-tinymce {
  height: 343px !important;
}

.con-btn {
  width: 30%;
}

.sign-btn {
  width: 24%;
}

@media (max-width: 1200px) {
  /* -----------job-details-modal------------- */
  .job-details-modal.modal.show .modal-dialog {
    margin: 26px;
  }
}

@media (max-width: 768px) {
  .modal-postjob-data .modal-content {
    padding: 0px 0px 48px 0px;
  }

  .modal-postjob-data .modal-body {
    padding: 0px 17px 0px;
  }

  .postjob-block-inner {
    padding-top: 31px;
    padding-bottom: 8px;
  }

  .postjob-style-block {
    display: flex !important;
    flex-wrap: nowrap;
  }

  input[class="skyblue"] + span::before {
    border: solid 1px var(--primary-sky);
    background-color: transparent;
    width: 19.5px;
    height: 19.5px;
  }

  input[class="skyblue-check-box"] + span::before {
    border: solid 1px var(--primary-sky);
    background-color: transparent;
    width: 19.5px;
    height: 19.5px;
  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal-postjob-data .modal-body h5 {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    padding-bottom: 31px;
  }

  .post-none-part {
    display: none;
  }

  /* -----------job-details-modal------------- */
  .job-details-modal .modal-content {
    padding: 23px 0px 25px;
  }

  .job-details-modal .modal-header {
    padding: 0px 20px 0px 20px;
  }

  .job-details-modal .modal-title {
    padding-bottom: 6px;
  }

  .job-details-modal .modal-body {
    padding: 0px 20px 0px 20px;
  }

  .sign-btn {
    width: 75%;
  }

  .editor-main-block .tox-tinymce {
    height: 210px !important;
  }
}

@media screen and (max-width: 425px) and (min-width: 320px) /* -----------job-details-modal------------- */ {
  .job-details-modal .modal-header div {
    padding: 0;
  }

  input[class="skyblue"] + span::before {
    width: 26.5px;
    height: 19.5px;
  }

  .job-details-modal .close-style span {
    display: none;
  }

  .con-btn {
    width: 90%;
  }

  .job-details-modal .form-group {
    margin-bottom: 0;
  }

  .editor-main-block {
    padding-top: 5px;
  }

  input[type="checkbox"]:checked + span::before {
    /* margin-top: 5px; */
  }
}
