.remove-confirmation-body {
  padding: 0px 61px 56px 61px;
}

.remove-doc-modal .modal-dialog {
  max-width: 702px !important;
}

.remove-conf-btn-container {
  display: flex;
  justify-content: space-between;
  margin: auto 51px;
}

.remove-conf-btn-outline {
  border-color: var(--primary-blue);
  box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
  outline: none;
}

.remove-btn-width {
  width: 208px;
}

.remove-conf-title {
  font-family: var(--primary-font-family);
  color: var(--primary-dark);
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
}

.remove-conf-msg {
  font-family: var(--primary-font-family);
  color: var(--primary-color-deepdark);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  margin: 30px auto;
}

@media (max-width: 426px) {
  .remove-confirmation-body {
    padding: 42px 24px 53px 24px;
  }

  .remove-btn-width {
    width: 148px;
    height: 41px;
  }

  .remove-conf-btn-container {
    margin: auto 5px;
  }

  .remove-conf-title {
    font-family: var(--secondary-font-family);
    font-size: 22px;
    line-height: 24px;
  }

  .remove-conf-msg {
    font-family: var(--secondary-font-family);
    font-size: 14px;
    line-height: 25px;
    margin: 24px auto;
    text-align: left;
  }
}
