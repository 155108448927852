.user-setting-table {
  text-align: left;
}

.user-setting-table td {
  padding: 5px;
  border-top: none;
}

.lbl-edit-email {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  padding-left: 6px;
  color: var(--primary-LightCoral);
}

.lbl-setting-subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.conf-pass-title {
  padding-bottom: 28px !important;
}

.conf-subtitle {
  margin-bottom: 28px;
}

.edit-setting {
  cursor: pointer;
}

.lbl-setting-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  color: var(--primary-LightCoral);
}

.change-pass-body {
  padding: 0px 98px 56px 98px;
}

.conf-pass-body {
  padding: 0px 98px 56px 98px;
}

.lbl-setting-desc {
  font-style: normal;
  font-weight: var(--medium-font-weight);
  font-size: 18px;
  line-height: 35px;
  color: var(--primary-dark);
}

.btn-setting-container {
  margin-top: 64px;
}

.setting-inner-body {
  display: none;
}

.btn-chngpass-container {
  margin-top: 60px;
}

.btn-confpass {
  margin-top: 44px;
}

.edit-detail-body {
  padding: 0px 98px 56px 98px;
}

@media screen and (max-width: 425px) {
  .user-setting-table {
    display: none;
  }
  .setting-inner-body {
    display: block;
    padding: 0px 9px;
  }
  .lbl-setting-title {
    text-align: left;
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: var(--primary-LightCoral);
    opacity: 0.54;
    margin-top: 12px;
  }

  .setting-desc-container {
    display: flex;
    justify-content: space-between;
    font-family: var(--secondary-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    color: var(--primary-dark);
  }

  .change-pass-body {
    padding: 0px 30px 50px 30px;
  }

  .btn-chngpass-container {
    margin-top: 27px;
  }

  .edit-detail-body {
    padding: 0px 20px 50px 20px !important;
  }

  .edit-detail-input-container {
    padding: 19px 0 14px 0;
  }

  .change-pass-title {
    padding-bottom: 23px !important;
  }

  .conf-pass-body {
    padding: 0px 30px 38px 30px;
  }

  .btn-confpass {
    margin-top: 23px;
  }
}
