.bg-dark-blue {
  background: #102938;
}

.footer-section li {
  list-style-type: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.footer-section ul {
  padding-inline-start: 32px !important;
}

.text-grey {
  color: var(--primary-light);
  opacity: 0.64;
}

.footer-section {
  padding: 30px 0px 8px 0px;
  font-weight: 400;
  font-family: poppins;
}

.page-wrapper {
  margin-top: 0px;
}

.contact-block {
  width: 100px;
}

.text-cursor {
  cursor: pointer;
}

.text-footer-link a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.txt-bottom-last-right {
  margin-right: 12px;
}

@media screen and (max-width: 425px) {
  .footer-container {
    padding: 0;
  }

  .footer-col {
    padding-left: 0px;
  }
}
.footer-main-container {
  z-index: 1;
  background-color: #0351C7;
}
.footer-title-row {
  padding-top: 60px;
  padding-bottom: 30px;
}
.logo-title {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-left: 10px;
}
.lbl-footer-copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  margin-bottom: 20px;
}
.lbl-footer-content {
  margin-bottom: 10px;
}
.lbl-footer-content a {
  color: #ffffff;
  text-decoration: none;
}
.lbl-footer-description a {
  color: #ffffff;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .footer-title-row {
    max-width: 100%;
    margin: auto;
  }

  .contact-block-container {
    display: none !important;
  }
  .footer-title-row div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-title-row div.footer-logo {
    flex-direction: row !important;
  }
  .footer-left-container {
    margin-bottom: 40px;
  }
}