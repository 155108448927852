.post-job-inner {
  margin-bottom: 12px;
}
.skip-data-style {
  margin: 4px 16px;
}
.skip-label-block {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-family: var(--primary-font-family);
}

.qlf-subtitle {
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 16px;
  text-align: center;
}

.qlf-input-box input {
  border-bottom-color: var(--primary-color-danger) !important;
}

.add-qlf-container {
  display: none;
}

.job-details-modal .modal-dialog {
  top: 0;
}
.review-btn {
  width: 22%;
}

.select-type-dropdown .dropdown-toggle::after {
  display: none;
}

.select-type-dropdown .dropdown-toggle {
  box-shadow: none;
}

.qlftype-lbl-col {
  display: none;
}

.qlf-down-arrow {
  position: absolute;
  top: 23px;
  right: 18px;
}

@media (max-width: 1024px) {
  .review-btn {
    width: 23%;
  }
}
@media (max-width: 768px) {
  .review-btn {
    width: 85%;
  }

  .qlftype-lbl-col {
    display: none;
  }
}

@media (max-width: 425px) {
  .qlf-subtitle {
    text-align: left;
  }

  .qlftype-lbl-col {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
  }

  .add-qlf-container {
    display: block;
    text-align: left;
  }

  .lbl-add-qlf {
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-dark);
  }
}
