.published-modal .modal-dialog {
  max-width: 1400px !important;
}

.job-modal-head-container {
  text-align: right;
  cursor: pointer;
}

.editor-cotainer .tox-statusbar__branding {
  display: none;
}

.edit-job-left-container {
  margin-right: 78px;
}

.input-disable-container .form-floating-input input {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--primary-color-deepdark);
  background-color: var(--primary-light);
}

.pub-qualification {
  margin-top: 42px;
}

.publisedjob-expr-date {
  margin-top: 110px;
}

@media screen and (max-width: 425px) {
  .job-modal-head-container {
    margin-top: 10px;
    margin-right: 10px;
  }
}
