.support-main-container {
  background-image: url("../../Assets/Images/header_large_back.png");
  background-repeat: no-repeat;
  background-size: 100% 110%;
}

.support-description {
  padding-bottom: 44px;
  margin-bottom: -32px;
  background: var(--primary-light);
  border-radius: 14px;
  padding: 72px 65px;
  margin: 60px auto;
  box-shadow: 0px 0px 42px -5px rgba(196, 196, 196, 0.40);
}

.main-content_section {
  background-image: url("../../Assets/Images/footer_background_section.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  padding-bottom: 10px;
}

.support-input-container {
  padding: 0px 32px;
}

.support-title {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 45px;
  color: var(--primary-dark);
}


.support-inner-container {
  max-width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url("../../Assets/Images/footer_background_section.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  margin-bottom: -50px;
}

.support-sub-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  margin-top: 20px;
  margin-bottom: 25px;
}

.secondary-input-container {
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding-top: 0px;
  height: 55px;
  border-bottom-width: 2px;
  background: #f9f9f9;
  border-radius: 4px;
  filter: drop-shadow(4px 0px 4px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
}

.secondary-input-container input,
.secondary-input-container textarea {
  border-bottom: 2px solid #0351C7;
  outline: none;
  font-family: var(--primary-font-family);
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 18px;
  padding: 22px 0 8px 5px;
  margin-top: 6px;
  background-color: var(--light-silver);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #000;
}

.secondary-input-container span {
  padding-left: 16px;
  padding-top: 16px;
  opacity: 0.54;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: var(--primary-dark);
}

.secondary-input-container,
.floating-label input:focus,
.messasge-input textarea:focus {
  color: #000;
  border-color: #0351C7 !important;
}

.messasge-input input,
.messasge-input textarea {
  height: 231px;
}

.message-input-container {
  height: 231px;
}

.btn-support-submit {
  padding: 10px 44px;
  margin-top: 46px;
  color: var(--primary-light);
  background-color: var(--primary-sky);
  border: 1px solid var(--primary-light) !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  width: 100%;
  border-radius: 7px;
}

.support-img-col {
  display: block;
}

.msg-top-space {
  margin-top: 22px;
}

@media screen and (max-width: 991px) {
  .support-inner-container {
    margin-bottom: 0;
  }
  .support-description {
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .support-description {
    padding: 45px 29px;
  }

  .support-img-col {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .support-inner-container {
    padding-top: 60px;
    background-image: unset;
  }
  .btn-support-submit {
    margin-top: 12px;
    padding: 8px 44px;
  }
  .support-title {
    font-weight: bold;
  }
}

@media screen and (max-width: 576px) {
  .support-description {
    max-width: 100%;
  }
}


@media screen and (max-width: 425px) {
  .support-img-col {
    display: none;
  }

  .support-title {
    font-size: 22px;
    line-height: 24px;
  }

  .support-sub-title {
    font-size: 14px;
    line-height: 25px;
  }

  .support-description {
    padding: 45px 13px;
  }
  .secondary-input-container input,
  .secondary-input-container textarea {
    font-family: var(--secondary-font-family);
  }
}

@media screen and (max-width: 375px) {
  .support-description {
    padding: 45px 7px;
  }
}
