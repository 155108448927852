
.qr-registretion-container {
    background-color: #E0ECFF;
    min-height: calc(100vh - 161px);
}

.qr-registretion-container .logo-banner-image {
    max-height: 340px;
    margin-top: 60px;
}

.register-main-container {
    z-index: 1;
    max-width: 80%;
    margin: auto;
}

.register-heading {
    margin-top: 60px;
    color: #2B2E39;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.register-prompt {
    padding-top: 10px;
    color: #2B2E39;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    text-align: center;
}

.html-qrcode-container {
    padding-top: 36px;
    padding-bottom: 36px;
}

.not-start-camera-stream {
    max-width: 686px;
    width: 100%;
    height: 400px;
    background-color: #000000;
}

.scanner-button {
    max-width: 500px;
    justify-content: space-between;
}

.qrcode-register-button {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 0px 6px 2px rgba(169, 156, 156, 0.47);
}

.re-scan-button {
    outline: none;
    color: #17a2b8;
    border: 2px solid#3FA4CC;
    background:  #FFF;
}

.continue-after-scan {
    color: #FDFDFD;
    background: #3FA4CC;
}

#html5-qr-code-reader video {
    max-width: 686px;
    width: 100% !important;
    height: 100%;
}

.role-dropdown::after {
    display: none !important;
}

.role-select-heading {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.deftle-modal .location-dropdown {
    width: 100%;
}

.deftle-modal .dropdown-menu {
    min-width: 100%;
}

.role-main-container {
    width: 85%;
}

.add-role-button {
    margin-top: 30px;
}

.camera-permission-title {
    color: #3A3A3A;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.camera-confirmation-body {
    padding: 40px 30px;
}

.camera-permission-text {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.3px;
    margin: 24px 0;
}

.camera-permission-button {
    width: 100%;
    max-width: 148px;
    height: 41px;
    border-radius: 7px;
    border: 1px solid #FFF;
    background: #3FA4CC;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}

.camera-permission-modal .modal-dialog {
    max-width: 384px !important;
    margin: auto;
    height: 100%;
}

.permission-failed-model .modal-dialog {
    max-width: 384px !important;
    margin: auto;
    height: 100%;
}

.camera-permission-modal .modal-dialog .modal-content {
    height: 260px !important;
    box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.90);
}
.permission-failed-model .modal-dialog .modal-content {
    box-shadow: 0px 6px 60px -5px rgba(196, 196, 196, 0.90);
}

.select-role-model-title {
    color: #3A3A3A;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 20px;
}

.loading-model-text {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.3px;
    padding: 0 12px;
}

.loading-confirmation-body {
    display: flex;
    align-items: center;
    padding: 50px 80px 56px 80px;
}

.registration-complete-title {
    color: #3A3A3A;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
}

.registration-confirmation-body {
    padding: 50px 100px;
}

.close-registration-compelete-btn {
    max-width: 236px;
    height: 46px;
    width: 100%;
}

.verify-email-model .modal-content {
    padding: 30px;
}
.verify-email-model .modal-title {
    margin-bottom: 34px;
    color: #3A3A3A;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;  
}

.verify-email-text {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 138.889% */
    letter-spacing: 0.3px;
}

.close-verify-email-button {
    max-width: 236px;
    height: 46px;
    width: 100%;
}
.verify-email-model .modal-content .modal-body {
    padding: 30px 30px 0 30px;
}

.permission-failed-header {
    color: #3A3A3A;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    margin: 20px 0;
}

.permission-failed-body {
    padding: 40px 35px;
}

.permission-failed-text {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-bottom: 25px;
}

.permission-failed-btn {
    max-width: 148px;
    height: 41px;
    width: 100%;
    background-color: #3FA4CC;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
}

@media (max-width: 768px) {
    .register-heading {
        margin: 0!important;
        color: #2B2E39;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .register-prompt {
        font-size: 16px;
    }

    .register-main-container {
        padding-top: 20px;
    }

    .html-qrcode-container {
        padding-top: 10px;
        padding-bottom: 30px;
    }
    .not-start-camera-stream {
        height: 300px;
    }
    #html5-qr-code-reader video {
        height: 300px;
        width: 100%;
    }
}

@media (max-width: 576px) {
    .camera-permission-modal .modal-dialog .modal-content {
        margin: 20px 0;
    }
    .permission-failed-model .modal-dialog .modal-content {
        margin: 20px 0;
    }
    .qrcode-register-button {
        width: 110px;
        height: 38px;
        font-size: 16px;
        font-weight: 500;
        padding: 5px;
    }
    .verify-email-model .modal-title {
        color: #3A3A3A;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }
    .verify-email-model .modal-content .modal-body {
        padding: 0;
        padding-bottom: 25px;
    }
    .verify-email-text {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.3px;
    }
    .close-verify-email-button {
        max-width: 148px;
        height: 41px;
        width: 100%;
    }
    .registration-modal .modal-dialog {
        margin: auto;
        max-width: 400px !important;
    }
    .registration-confirmation-body {
        padding: 38px;
    }

    .job-located-title {
        color: #3A3A3A;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
    }

    .close-job-style {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0.3px;
    }
    .job-located-header {
        padding-left: 0 !important;
    }
}
@media (max-width: 425px) {
    .registration-confirmation-body {
        padding: 38px 15px;
    }
    .registration-modal .modal-dialog .modal-content {
        margin: 10px;
    }
    .camera-permission-modal .modal-dialog .modal-content {
        margin: 15px;
    }
    .permission-failed-model .modal-dialog .modal-content {
        margin: 15px;
    }
    .permission-failed-header {
        font-size: 18px;
    }
    .permission-failed-text {
        font-size: 12px;
    }
}
@media (max-width: 368px) {
    .loading-confirmation-body {
        padding: 10px;
        justify-content: center;
    }
}