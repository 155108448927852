.stage-job-container {
  min-height: 750px;
}

@media (max-width: 992px) {
  .stage-job-container {
    min-height: 550px;
    margin-top: 74px;
  }
}

@media (max-width: 425px) {
  .stage-job-container {
    margin-top: 74px;
  }
}
