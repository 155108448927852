:root {
  --primary-color: #3ba6fb;
  --primary-light: #ffffff;
  --primary-color-dark-contrast: #60638a;
  --primary-dark: #3a3a3a;
  --primary-blue: #3fa4cc;
  --primary-deep-dark: #2b2e39;
  --primary-Kimberly: #60638a;
  --primary-sky: #3fa4cc;
  --primary-grey: #7e7e7e;
  --primary-light-green: #81b214;
  --primary-color-medium-light: #f1f0fd;
  --primary-color-danger: #c60000;
  --primary-color-deepdark: #000000;
  --secondary-color-light: #ceced9;
  --secondary-color-light-contrast: #6396a6;
  --secondary-color-dark: #17176d;
  --secondary-color-light-tint: #d7d7e6;
  --secondary-color-skin: #c4c4c4;
  --secondary-color-light-gray: #fafafa;
  --secondary-color-dark-gray: #f0f0f0;
  --secondary-color-sky: #66c1e5;
  --dark-grayish: #2b2e39;
  --very-dark-grayish: #282e39;
  --light-silver: #f9f9f9;
  --user-managemnt-margin-top: 120px;

  --forms-text-color: #808080;
  --forms-padding: 30px;
  --forms-button-width: 45%;

  --primary-font-family: "Poppins";
  --secondary-font-family: "Roboto";
  --primary-generic-font-family: "Roboto";
  --large-font-size: 25px;
  --medium-large-font-size: 20px;
  --medium-font-size: 18px;
  --medium-small-font-size: 16px;
  --small-font-size: 14px;
  --extra-small-font-size: 12px;

  --normal-font-weight: 400;
  --medium-font-weight: 500;
  --semi-bold-font-weight: 600;
  --bold-font-weight: 700;
}

.popupmodal {
  width: 100%;
  padding: 20px;
}
