.modal-body .back-join {
  font-weight: 600;
  font-style: normal;
  font-size: 29px;
  line-height: 25px;
  color: #3a3a3a;
}
.modal-body .switch-count {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-sky);
  cursor: pointer;
}
.modal-body .switch-count span {
  font-weight: 700;
}
.modal-open {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.view {
  padding-bottom: 10px;
}
.view-position {
  text-align: initial;
  margin: auto;
  width: 77%;
  padding: 59px 0 42px 0px;
}
.btn-joe-con {
  width: auto;
}
@media screen and (max-width: 425px) and (min-width: 320px) {
  .view-position {
    width: auto;
  }

  .job-modal-body {
    padding: 22px 21px 50px 21px;
    font-family: var(--secondary-font-family);
  }

  .job-modal-body .desc-title {
    font-size: 18px !important;
  }
}
