.doc-view-modal {
  text-align: left !important;
  padding: 20px 98px 60px 98px !important;
}

.doc-view-area {
  width: 100%;
  height: auto;
  min-height: 310px;
  border: 2px solid #3a3a3a;
  margin-bottom: 64px;
  margin-top: 28px;
}

.lbl-doc-viewer {
  margin-top: 6px;
}

.upd-docprev-loader {
  position: absolute;
  width: 0px;
  z-index: 2000000000;
  left: 50%;
  top: 43%;
}

@media screen and (max-width: 425px) {
  .doc-view-area {
    min-height: 270px;
    margin-bottom: 28px;
    margin-top: 18px;
  }

  .doc-view-modal {
    padding: 30px 98px 30px 98px !important;
  }

  .upd-docprev-loader {
    left: 50%;
    top: 46%;
  }
}
