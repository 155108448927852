.deftle-modal.right.fade .modal-dialog,
.deftle-modal.close-right.fade:not(.show) .modal-dialog {
    transform: translate3d(100vw, 0, 0);
    transition: transform 0.5s ease;
}

.deftle-modal.left.fade .modal-dialog,
.deftle-modal.close-left.fade:not(.show) .modal-dialog {
    transform: translate3d(-100vw, 0, 0);
    transition: transform 0.5s ease;
}

.deftle-modal.fade.show .modal-dialog {
    transform: translate3d(0, 0, 0);
}
